import React, { useRef } from 'react';
import {Switch, Route, useRouteMatch, Redirect, useHistory} from 'react-router-dom'
import { useClientConfigProvider } from '../../components/ClientConfigProvider';
import { useSchdeuleProvider } from '../../components/SchdeuleProvider';
import ScheduleSuccessScreen from './schedule-success/ScheduleSuccess';
import ScheduleErrorScreen from './schedule-error/ScheduleError';
import { SelectSlot } from './select-slot/SelectSlot';
import { ScheduleForm } from './schedule-form/ScheduleForm';
import { Reschedule } from './reschedule/Reschedule';
import { SelectHost } from './select-host/SelectHost';
import { ScheduleWelcome } from './schedule-welcome/ScheduleWelcome';
import { SelectEvent } from './select-event/SelectEvent';
import './schedule-screen.scss';
import ScheduleSkeleton from '../../components/skeletons/schedule-skeleton/ScheduleSkeleton';

export const SCHEDULING_PAGES = {
	ROOT: '',
	SCHEDULE_WELCOME: 'welcome-screen',
	SELECT_EVENT: 'select-event',
	SELECT_HOST: 'select_host',
	SELECT_SLOT: 'select-slot',
	SCHEDULE_FORM: 'schedule-form',
	RESCHEDULE: 'reschedule',
	SUCCESS: 'succsess',
	ERROR: 'error',
};

const SchedulingScreen = ({ onClose }) => {
	const history = useHistory();
	const { path } = useRouteMatch();
    const scdeduleContainerRef = useRef(null);
	const { isScheduleLink, hostSelectionConfig } = useClientConfigProvider();
	const { isScheduleItemDataLoading } = useSchdeuleProvider();

	if (isScheduleLink && isScheduleItemDataLoading) {
		return <ScheduleSkeleton />
	}

	const navigateToScheduling = () => {
		history.replace(path);
	}

	const navigateToPreviousScreen = () => {
		history.goBack();
	}

	const navigateToSuccessPage = () => {
		history.push(`${path}/${SCHEDULING_PAGES.SUCCESS}`);
	}

	const navigateToErrorPage = () => {
		history.push(`${path}/${SCHEDULING_PAGES.ERROR}`);
	}

	const navigateToSelectEvent = () => {
		history.push(`${path}/${SCHEDULING_PAGES.SELECT_EVENT}`);
	}

	const navigateToSelectTimeSlot = () => {
		history.push(`${path}/${SCHEDULING_PAGES.SELECT_SLOT}`);
	}

	const navigateToSelectHost = () => {
		history.push(`${path}/${SCHEDULING_PAGES.SELECT_HOST}`);
	}

	const navigateToScheduleForm = () => {
		history.push(`${path}/${SCHEDULING_PAGES.SCHEDULE_FORM}`);
	}

	const navigateToNextScheduleScreen = (activePath, params) => {
		switch (activePath) {
			case `${path}/${SCHEDULING_PAGES.SCHEDULE_WELCOME}`: {
				if (!params?.isRescheduling && params?.isSelectedEventTypesLoaded) {
					if (params?.preselectedEventType) {
						hostSelectionConfig.enabled ? navigateToSelectHost() : navigateToSelectTimeSlot();
					} else {
						navigateToSelectEvent();
					}
				} else {
					hostSelectionConfig.enabled ? navigateToSelectHost() : navigateToSelectTimeSlot();
				}
				break;
			}
			case `${path}/${SCHEDULING_PAGES.SUCCESS}`: {
				return onClose({ resetStack: true });
			}
			case `${path}/${SCHEDULING_PAGES.ERROR}`: {
				return navigateToScheduling();
			}
			case `${path}/${SCHEDULING_PAGES.RESCHEDULE}`:
			case `${path}/${SCHEDULING_PAGES.SELECT_EVENT}`: {
				hostSelectionConfig.enabled ? navigateToSelectHost() : navigateToSelectTimeSlot();
				break;
			}
			case `${path}/${SCHEDULING_PAGES.SELECT_HOST}`: {
				return navigateToSelectTimeSlot();
			}
			case `${path}/${SCHEDULING_PAGES.SELECT_SLOT}`: {
				return navigateToScheduleForm();
			}
			case `${path}/${SCHEDULING_PAGES.SCHEDULE_FORM}`: {
				if (params?.isError) {
					navigateToErrorPage();
				} else if (params?.isSuccess) {
					navigateToSuccessPage();
				} else {
					navigateToSelectHost();
				}
				break;
			}
			default: return;
		}
	}

	return (
		<div ref={scdeduleContainerRef} className='scheduler-container'>
			<Switch>
				<Redirect exact from={path} to={isScheduleLink ? `${path}/${SCHEDULING_PAGES.RESCHEDULE}` : `${path}/${SCHEDULING_PAGES.SCHEDULE_WELCOME}`}>
				</Redirect>
				<Route path={`${path}/${SCHEDULING_PAGES.SCHEDULE_WELCOME}`}>
					<ScheduleWelcome navigateToNextScheduleScreen={navigateToNextScheduleScreen} />
				</Route>
				<Route path={`${path}/${SCHEDULING_PAGES.SUCCESS}`}>
					<ScheduleSuccessScreen navigateToNextScheduleScreen={navigateToNextScheduleScreen} />
				</Route>
				<Route path={`${path}/${SCHEDULING_PAGES.ERROR}`}>
					<ScheduleErrorScreen navigateToNextScheduleScreen={navigateToNextScheduleScreen} />
				</Route>
				<Route path={`${path}/${SCHEDULING_PAGES.SELECT_EVENT}`}>
					<SelectEvent componentRef={scdeduleContainerRef} navigateToNextScheduleScreen={navigateToNextScheduleScreen}/>
				</Route>
				<Route path={`${path}/${SCHEDULING_PAGES.SELECT_HOST}`}>
					<SelectHost navigateToNextScheduleScreen={navigateToNextScheduleScreen}/>
				</Route>
				<Route path={`${path}/${SCHEDULING_PAGES.SELECT_SLOT}`}>
					<SelectSlot navigateToNextScheduleScreen={navigateToNextScheduleScreen} navigateToSelectHost={navigateToPreviousScreen}/>
				</Route>
				<Route path={`${path}/${SCHEDULING_PAGES.SCHEDULE_FORM}`}>
					<ScheduleForm navigateToNextScheduleScreen={navigateToNextScheduleScreen}/>
				</Route>
				<Route path={`${path}/${SCHEDULING_PAGES.RESCHEDULE}`}>
					<Reschedule onClose={onClose} navigateToNextScheduleScreen={navigateToNextScheduleScreen}/>
				</Route>
			</Switch>
		</div>
	)
};

export default SchedulingScreen;
