import { useMemo } from "react";

export const DEFAULT_STORIES_WIDGET_SIZE = 'collapsed';
export const EXPAND_STORIES_WIDGET_SIZE = 'expanded';
export const TOP_LEFT_POSITION = 'top_left';
export const TOP_RIGHT_POSITION = 'top_right';
export const BOTTOM_LEFT_POSITION = 'bottom_left'
export const BOTTOM_RIGHT_POSITION = 'bottom_right'

const useStoriesProductStyle = (size, products, product) => {
    const topLeft = products.filter(({storiesConfig}) => storiesConfig.position === TOP_LEFT_POSITION)
    const topRight = products.filter(({storiesConfig}) => storiesConfig.position === TOP_RIGHT_POSITION)
    const bottomLeft = products.filter(({storiesConfig}) => storiesConfig.position === BOTTOM_LEFT_POSITION)
    const bottomRight = products.filter(({storiesConfig}) => storiesConfig.position === BOTTOM_RIGHT_POSITION)
    const expanded = products.filter(({storiesConfig}) => storiesConfig.size === EXPAND_STORIES_WIDGET_SIZE)

    const {position} = product.storiesConfig;

    const top = useMemo(() => {
        if (size === DEFAULT_STORIES_WIDGET_SIZE) {
            if (position === BOTTOM_RIGHT_POSITION || position === BOTTOM_LEFT_POSITION) {
                return 'auto'
            } else {
                if (position === TOP_LEFT_POSITION) {
                    const index = topLeft.findIndex(({productId}) => product.productId === productId)
                    return 45 + 15 * index + 92 * index
                }
                if (position === TOP_RIGHT_POSITION) {
                    const index = topRight.findIndex(({productId}) => product.productId === productId)
                    return 45 + 15 * index + 92 * index
                }
            }
        } else {
            const index = Math.max(topLeft.length, topRight.length) + expanded.findIndex(({productId}) => product.productId === productId)
            return 45 + 15 * index + 92 * index
        }
    }, [size, position, topLeft.length, topRight.length, expanded.length])

    const bottom = useMemo(() => {
        if (size === DEFAULT_STORIES_WIDGET_SIZE) {
            if (position === BOTTOM_RIGHT_POSITION) {
                const index = bottomRight.findIndex(({productId}) => product.productId === productId)
                return 65 + 15 * index + 92 * index
            }
            if (position === BOTTOM_LEFT_POSITION) {
                const index = bottomLeft.findIndex(({productId}) => product.productId === productId)
                return 65 + 15 * index + 92 * index
            }
        } else {
            return 'auto'
        }
    }, [size, position, bottomLeft.length, bottomRight.length])

    const left = useMemo(() => {
        if (size === DEFAULT_STORIES_WIDGET_SIZE) {
            if (position === TOP_LEFT_POSITION || position === BOTTOM_LEFT_POSITION) {
                return 25
            }
            if (position === TOP_RIGHT_POSITION || position === BOTTOM_RIGHT_POSITION) {
                return 'auto'
            }
        }
        if (size === EXPAND_STORIES_WIDGET_SIZE) {
            return 25
        }
    }, [size, position])

    const right = useMemo(() => {
        if (size === DEFAULT_STORIES_WIDGET_SIZE) {
            if (position === TOP_LEFT_POSITION || position === BOTTOM_LEFT_POSITION) {
                return 'auto'
            }
            if (position === TOP_RIGHT_POSITION || position === BOTTOM_RIGHT_POSITION) {
                return 25
            }
        }
        if (size === EXPAND_STORIES_WIDGET_SIZE) {
            return 25
        }
    }, [size, position])

    return ({
        top,
        bottom,
        left,
        right
    })
}

export default useStoriesProductStyle;
