import React, { useEffect, useState } from 'react';
import { MemoryRouter as Router } from 'react-router-dom';
import { useDeviceSelectors } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import './App.scss';
import { logger } from './logging';

import ClientAuthProvider from './components/ClientAuthProvider';
import ClientConfigProvider from './components/ClientConfigProvider';
import CustomerDetailsProvider from './components/CustomerDetailsProvider';
import SchdeuleProvider from './components/SchdeuleProvider';
import { CURRENT_SESSION } from './constants/sessionActionTypes';

import useDynamicConfig from './hooks/useDynamicConfig';

import ClientApp from './ClientApp';


import LocalizationSettingsProvider from './components/LocalizationSettingsProvider';
import InterWindowMessageProvider from './components/InterWindowMessageProvider';
import { FontsLoader } from './utils/fonts-loader';
import {closeApp} from "./actions/system";
import { setGlobalConfig } from './actions/currentSession';

const App = () => {
  const { globalConfig: globalVariables, isAppDisplayed, appInstanceId } = useSelector(state => state.currentSession);

  const customerShopifyName = globalVariables?.customerName;
  const customerShopifyEmail = globalVariables?.customerEmail;
  const customerShopifyId = globalVariables?.customerId;
  const shopId = globalVariables?.shop;
  const locale = globalVariables?.locale;

  const isShopOpen = globalVariables?.shopOpen;
  const allowOutgoing = globalVariables?.allowOutgoing;
  const disableScheduling = globalVariables?.disableScheduling;
  const accessCode = globalVariables?.accessCode;
  const accessCodeExpiresAt = globalVariables?.accessCodeExpiresAt;
  const showIntro = globalVariables?.showIntro;

  // if opening a callLink:
  const callLinkId = globalVariables?.callLinkId;

  // if opening a scheduleLink:
  const scheduleLinkId = globalVariables?.scheduleLinkId;

  const [deviceSelectors, deviceData] = useDeviceSelectors();


  // the url of the website that opened the client app -(or the iframe's parent window)
  const sourceUrl = globalVariables?.fullUrl;
  // the origin of the website that opened the client app
  const urlParams = new URLSearchParams(window.location.search);
  const sourceUrlOrigin = urlParams.get('shopOrigin');
  const trackingConsent = globalVariables?.trackingConsent;


  const isStandaloneApp = window.parent === window.self;  // will always be false becasue app is opened in iframe

  const { configLoading, configObj, realtimeConfigObj, realtimeConfigLoading } = useDynamicConfig(shopId);
  const customMessageContent = !configLoading && configObj && configObj.customMessageContent;

  const dispatch = useDispatch();

  const onMessageConfigReceived = (payload) => {
    logger.info('App Config has been recevied', payload);
    dispatch(setGlobalConfig(payload));
  }

  const onMessageClientAppClose = () => {
    logger.info('App req close');
    dispatch(closeApp());
  }

  useEffect(() => {
    const { browser, os, device } = deviceData;
    const { isIOS } = deviceSelectors;
    logger.info(`App has started ${process.env.REACT_APP_VERSION}`, {
      shopifyUrlOrigin: sourceUrlOrigin,
      device: {
        browser, os: { ...os, isIOS }, device,
      },
      params: globalVariables,
    });
    dispatch({ type: CURRENT_SESSION.SET_ORIGIN_URL, shopifyUrlOrigin: sourceUrlOrigin });
  }, []);

  useEffect(() => {
    logger.info(`App dispaly set to ${isAppDisplayed}`)
  }, [isAppDisplayed]);

  useEffect(() => {
    if (!configLoading && configObj) {
      const root = document.documentElement;
      if (configObj.app_primary_color)
        root.style.setProperty('--main-color', `${configObj.app_primary_color}`);
      if (configObj.app_corner_style)
        root.classList.add(`${configObj.app_corner_style}`)
      if (configObj.app_button_text_style)
        root.style.setProperty('--btn-text-style', `${configObj.app_button_text_style}`);
      if (configObj.app_filled_button_text_color)
        root.style.setProperty('--filled_btn-text-color', `${configObj.app_filled_button_text_color}`);
      if (configObj.app_outlined_button_text_color)
        root.style.setProperty('--outlined_btn-text-color', `${configObj.app_outlined_button_text_color}`);
      FontsLoader(configObj.fontFamily, root);
      FontsLoader(configObj.fontFamilyButtons, root, '--button-font-family');
    }
  }, [configObj, configLoading])

  return (
    <InterWindowMessageProvider
      configLoading={configLoading}
      targetOriginUrl={sourceUrlOrigin}
      onMessageConfigReceived={onMessageConfigReceived}
      onMessageClientAppClose={onMessageClientAppClose}
    >
      <div className={
        `App App-default
        ${isStandaloneApp ? ' full-screen' : ''}`}>
        <LocalizationSettingsProvider locale={locale} shopId={shopId} customMessages={customMessageContent}>
          <CustomerDetailsProvider
            customerName={customerShopifyName}
            customerEmail={customerShopifyEmail}
            customerId={customerShopifyId}
          >
            <ClientAuthProvider
              shopId={shopId}
              accessCode={accessCode}
              accessCodeExpiresAt={accessCodeExpiresAt}
              callLinkId={callLinkId}
              scheduleLinkId={scheduleLinkId}
              windowUrl={sourceUrl}
              trackingConsent={trackingConsent}
            >
              <ClientConfigProvider
                isShopOpen={isShopOpen}
                allowOutgoingCalls={allowOutgoing}
                disableScheduling={disableScheduling}
                isStandaloneApp={isStandaloneApp}
                dynamicConfig={configObj}
                realtimeConfig={realtimeConfigObj}
                realtimeConfigLoading={realtimeConfigLoading}
                scheduleLinkId={scheduleLinkId}
                showIntro={showIntro}
                sourceUrl={sourceUrl}
              >
                <SchdeuleProvider>
                  <Router>
                    <ClientApp isAppDisplayed={isAppDisplayed} />
                  </Router>
                </SchdeuleProvider>
              </ClientConfigProvider>
            </ClientAuthProvider>
          </CustomerDetailsProvider>
        </LocalizationSettingsProvider>
      </div>
    </InterWindowMessageProvider >
  );
};

export default App;
