import React, { useState, useEffect, useRef, useCallback } from 'react';

import { logger } from '../../logging';

import MicIcon from '../../assets/icons/Mic';
import VideocamIcon from '../../assets/icons/Videocam';
import MicNoneIcon from '../../assets/icons/MicNone';
import VolumeUpOutlinedIcon from '../../assets/icons/VolumeUpOutlined';
import { DeviceSelector } from './deviceSelector';
import { useDevices, useLocalTracks, useAudioLevel, AudioLevelIndicator } from '@caazam/boutiq-video-room';

import { useIntl, FormattedMessage } from 'react-intl';
import { deviceSelectorMessages } from './messages';
import { OutlinedButton } from '../boutiq-buttons/BoutiqButton';
import throttle from 'lodash.throttle';
import './index.scss';

const TABS_LABELS = {
    audio: deviceSelectorMessages.audio,
    video: deviceSelectorMessages.video,
};
const CONTROLS_LABELS = {
    audioinput: deviceSelectorMessages.microphone,
    audiooutput: deviceSelectorMessages.speakers,
    videoinput: deviceSelectorMessages.camera,
};
const AUDIO_ERROR_MESSAGE = 'This browser does not support the audio element.';
const submitSettingButton = {
    fontSize: 16,
    width: '100%',
    height: 44,
};

export default function AudioVideoSettingsDialog({ onClose }) {
    const [isVideoInputEnabled, setVideoInputEnabled] = useState(true);

    const videoElementRef = useRef(null);
    const audioElementRef = useRef(null);
    const AVSettingsRef= useRef();

    const [selectedTab, setSelectedTab] = useState(0);

    const {
        currentMic, hasMicError, micState, setMicrophone, microphones,
        currentCam, hasCamError, camState, setCamera, cameras,
        currentSpeaker, speakers, setSpeaker,
        refreshDevices
    } = useDevices();

    const { localAudio, isAudioMuted, localVideo, isVideoMuted } = useLocalTracks();
    const [volume, setVolume] = useState(null);

    const updateVolume = useCallback(throttle((volume) => {
        // this volume number will be between 0 and 1
        // give it a minimum scale of 0.15 to not completely disappear 👻
        setVolume(volume);
    }, 100), []);

    useAudioLevel(localAudio, updateVolume);

    const intl = useIntl();

    useEffect(() => {
        AVSettingsRef.current.setAttribute('tabindex', '-1');
        AVSettingsRef.current.focus();
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                event.stopPropagation();
                event.preventDefault();
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    useEffect(() => {
        if (!localVideo) return;
        videoElementRef.current.srcObject = new MediaStream([localVideo])
        return () => {
            if (videoElementRef.current) {
                videoElementRef.current.srcObject = null;
            }
        };
    }, [localVideo]);

    const onVideoInputChange = (device) => {
        setCamera(device.deviceId);
    }

    const onAudioInputChange = (device) => {
        setMicrophone(device.deviceId);
    }

    const onAudioOutputChange = (device) => {
        setSpeaker(device.deviceId);
        attachSinkId(
            audioElementRef.current,
            device.deviceId
        );
    }

    const attachSinkId = (audioElem, sinkId) => {
        if (typeof audioElem.sinkId !== 'undefined') {
            audioElem
                .setSinkId(sinkId)
                .catch((error) => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    logger.error('attachSinkId', error);
                });
        } else {
            logger.warn('Browser does not support output device selection.');
        }
    };

    const play = () => {
        audioElementRef.current.pause();
        audioElementRef.current.currentTime = 0;
        audioElementRef.current.play();
    };

    const tabs = [
        {

            label: <FormattedMessage {...TABS_LABELS.audio} />,
            icon: <MicIcon color='current' size={24} />,
            component: {
                content: (
                    <div className='container no-outline' ref={AVSettingsRef}>
                        <div className='section'>
                            <h5>{micState !== 'granted'
                                ? <FormattedMessage {...deviceSelectorMessages.enable_audio} />
                                : !isAudioMuted
                                    ? <FormattedMessage {...CONTROLS_LABELS.audioinput} />
                                    : <FormattedMessage {...deviceSelectorMessages.microphone_is_muted} />}
                            </h5>
                            {!isAudioMuted && micState === 'granted' &&
                                <DeviceSelector
                                    value={currentMic?.device}
                                    onChange={onAudioInputChange}
                                    listitems={microphones.map(m => m.device)}
                                />
                            }
                        </div>
                        <div className='test-controls button'>
                            {!isAudioMuted && micState === 'granted' && <button>
                                <MicNoneIcon color='black64' size={24} />
                                <AudioLevelIndicator volume={volume} />
                            </button>}
                        </div>
                        {!isAudioMuted && micState === 'granted' &&
                            <>
                                <div className='section'>
                                    <h5><FormattedMessage {...CONTROLS_LABELS.audiooutput} /></h5>
                                    <DeviceSelector
                                        value={currentSpeaker?.device}
                                        onChange={onAudioOutputChange}
                                        listitems={speakers.map(s => s.device)}
                                    />
                                </div>
                                <div className='test-controls button'>
                                    <button onClick={play}>
                                        <VolumeUpOutlinedIcon color='black64' size={24}/>
                                        <span>
                                            <FormattedMessage {...deviceSelectorMessages.cta_test} />
                                        </span>
                                    </button>
                                    <audio ref={audioElementRef} title='local audio file'>
                                        <source src='sounds/doorbell.mp3' type='audio/mp3' />
                                        {AUDIO_ERROR_MESSAGE}
                                    </audio>
                                </div>
                            </>
                         }
                    </div>
                ),
            },
        },
        {
            label: <FormattedMessage {...TABS_LABELS.video} />,
            icon: <VideocamIcon color='current' size={24} />,
            component: {
                content: (
                    <div className='container'>
                        <div className='section'>
                            <h5>{camState !== 'granted' ?
                                <FormattedMessage {...deviceSelectorMessages.enable_video} /> :
                                isVideoInputEnabled ?
                                    <FormattedMessage {...CONTROLS_LABELS.videoinput} /> :
                                    <FormattedMessage {...deviceSelectorMessages.camera_is_muted} />}
                            </h5>
                            {!isVideoMuted && camState === 'granted' &&
                                <DeviceSelector
                                    value={currentCam?.device}
                                    onChange={onVideoInputChange}
                                    listitems={cameras.map(c => c.device)}
                                />}
                        </div>
                        <div className='test-controls'>
                            <video ref={videoElementRef} muted playsInline autoPlay></video>
                        </div>
                    </div>
                ),
            },
        },
    ];

    return (
        <div className={`settings-dialog-container`}>
            <div className='tabs-container'>
                {tabs.map((item, index) => (
                    <button
                        key={index}
                        onClick={() => setSelectedTab(index)}
                        className={`tab${index === selectedTab ? ' selected' : ''}`}
                    >
                        <div className='icon'>{item.icon}</div>
                        {item.label}
                    </button>
                ))}
            </div>
            <div className='tab-content'>
                {tabs.map((item, index) => (
                    <div
                        key={index}
                        className={`content${index === selectedTab ? ' active' : ''}`}
                    >
                        {item.component.label}
                        {item.component.content}
                    </div>
                ))}
            </div>
            <div className='submit-button-container'>
                <OutlinedButton style={submitSettingButton} onClick={onClose}>
                    <FormattedMessage {...deviceSelectorMessages.cta_done} />
                </OutlinedButton>
            </div>
        </div>

    );
};
