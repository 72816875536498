import React, { createContext, useContext, useState, useCallback } from 'react';
import { AppFooter } from './appFooter/AppFooter';
import { AppHeader } from './appHeader/AppHeader';

export const AppHeaderContext = createContext(null);

export default function AppHeaderProvider({ onClose, children }) {
    
    const [backFunc, setBackFunc] = useState(null);
    const [AVSettingVisible, setAVSettingVisible] = useState(false);
    const [AVSettingEnabled, setAVSettingEnabled] = useState(false);

    const backFuncRef = useCallback(ref => {
            setBackFunc(() => ref);
    }, []);

    return (
        <AppHeaderContext.Provider value={{
            onClose,
            backFunc,
            backFuncRef,
            AVSettingVisible, setAVSettingVisible,
            AVSettingEnabled, setAVSettingEnabled
        }}>
            <AppHeader />
            {children}
            <AppFooter />            
        </AppHeaderContext.Provider>
    );
}

export function useAppHeaderProvider() {
    const context = useContext(AppHeaderContext);
    if (!context) {
        throw new Error('useAppHeaderProvider must be used within the AppHeaderProvider');
    }
    return context;
}
