import { APP_DIAGNOSTICS_ACTION_TYPES } from "../constants/appDiagnostics";

const initPreflight = {
  report: null,
  error: null,
  stopped: false,
  signalingGatewayReachable: false,
  turnServersReachable: false,
};

const initialState = {
  contextId: null,
  duration: null,
  preflightTest: Object.assign({}, initPreflight),
  preflightTestInProgress: false,
  preflightTestFinished: false,
  reportSent: false,
};

const appDiagnostics = (state = initialState, action) => {
  switch (action.type) {
    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STARTED:
      return {
        ...state,
        contextId: action.contextId,
        duration: action.duration,
        preflightTestInProgress: true,
        preflightTestFinished: false,
        preflightTest: {
          ...Object.assign({}, initPreflight),
        }
      }

    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_COMPLETED:
      if (!state.preflightTestInProgress) return {...state};
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          report: action.res,
          signalingGatewayReachable: action.res?.websocketConnectivity?.result !== 'failed',
          turnServersReachable: action.res?.networkConnectivity?.result !== 'failed',
        },
        preflightTestFinished: true,
        preflightTestInProgress: false,
      }

      case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STOPPED:
      if (!state.preflightTestInProgress) return {...state};
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          stopped: true,
          report: action.res,
        },
        preflightTestInProgress: false,
        preflightTestFinished: false,
      }
    case APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_FAILED:
      if (!state.preflightTestInProgress) return {...state};
      return {
        ...state,
        preflightTest: {
          ...state.preflightTest,
          error: action.error,
        },
        preflightTestFinished: false,
        preflightTestInProgress: false,
      }
    case APP_DIAGNOSTICS_ACTION_TYPES.REPORT_SENT:
      return {
        ...state,
        reportSent: true,
      }
    default:
      return state;
  }
};

export default appDiagnostics;
