import React, { useMemo } from 'react';
import { CloseBtn } from './components/close-btn/CloseBtn';
import { LocaleSettings } from './components/locale-settings/LocaleSettings';
import { AVSettings } from './components/av-settings/AVSettings';
import { BackBtn } from './components/back-btn/BackBtn';
import { useLocation } from 'react-router-dom';
import { VIDEO_CALL_PAGES } from '../VideoCall';
import { useAppHeaderProvider } from '../AppHeaderProvider';
import './AppHeader.scss';
import {PAGES} from "../../constants/routes";

export const AppHeader = ({}) => {
    const location = useLocation();
    const { onClose, AVSettingEnabled } = useAppHeaderProvider();
    const isHeaderAvailable = useMemo(() => location.pathname !== `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.CALL}`, [location]);
    const isTopBarAvailable = useMemo(() => location.pathname !== PAGES.INTRO, [location]);
    const isAVSettingsAvailable = useMemo(() => {
        return AVSettingEnabled && [
            `${PAGES.VIDEO_CALL}`,
            `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.VIDEO_LOBBY}`,
            `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.WAITING_ROOM}`
        ].includes(location.pathname)

    }, [location, AVSettingEnabled]);


    const isShowCloseButton = () => {
        return  location.pathname !== `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.WAITING_ROOM}`;
    }

    return (
        <div className={`app-header visible
            ${isHeaderAvailable ? '' : ' disabled'}
            ${isTopBarAvailable ? '' : ' no-bar'}`}>
            <div className='header-buttons-container'>
                <BackBtn />
                <div className='additional-app-headet-buttons'>
                    <AVSettings disabled={!isAVSettingsAvailable}/>
                    <LocaleSettings />
                    {
                        isShowCloseButton() &&
                            <CloseBtn location={location} onClose={onClose} />
                    }
                </div>
            </div>
        </div>
    )
}
