import React from 'react';
import './StoriesProduct.scss';
import CollapseProductPreview from './components/CollapseProductPreview';
import ExpandProductPreview from './components/ExpandProductPreview';
import { PRODUCT_PREVIEW_SIZE } from '../../constants/story';
import { useIntroPage } from '../../pages/intro/IntroPageProvider';

const StoriesProduct = ({product, currentTime}) => {
  const {showFrom, size} = product.storiesConfig;
  const {
    playList,
    playingVideoIndex,
  } = useIntroPage();

  if (showFrom > currentTime) {
    return null
  }

  return (
    <div className={'product-preview'}>
      {size === PRODUCT_PREVIEW_SIZE.collapsed
        ? <CollapseProductPreview product={product} products={playList[playingVideoIndex].products} />
        : <ExpandProductPreview product={product} products={playList[playingVideoIndex].products} />
      }
    </div>
  )
}

export default StoriesProduct;
