import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";

import usePreflightTest from "../hooks/appStateDiagnostic/usePreflightTest";
import { useCallContextAPI } from "../hooks";
import { getQualityScore, getPreflightStopped, getPreflightFailed } from "../utils/diagnostics";
import { APP_DIAGNOSTICS_ACTION_TYPES } from "../constants/appDiagnostics";
import { logger } from '../logging';


export default function useAppDiagnostics(contextId, canRunTest) {

  const dispatch = useDispatch();

  const isTestRunning = useRef(false);
  const { sendDiagnosticReport } = useCallContextAPI(contextId);
  const { startPreflightTest, stopPreflightTest } = usePreflightTest(contextId);

  const {
    contetxId: lastRunContextId,
    preflightTest, 
    preflightTestFinished,
    preflightTestInProgress,
    reportSent,
    duration,
  } = useSelector((state) => state.appDiagnostics);

  useEffect(()=>{
    isTestRunning.current = preflightTestInProgress;
  },[preflightTestInProgress])

  useEffect(()=>{
    return () => {
      if (isTestRunning.current) {
        stopPreflightTest();
      }
    }

  }, []);

  useEffect(() => {
    if (contextId && contextId !== lastRunContextId && !preflightTestFinished && canRunTest) {
      startPreflightTest();
    }
  }, [contextId, lastRunContextId, preflightTestFinished, canRunTest]);
  
  useEffect(() => {
    if (preflightTestFinished && !reportSent) {

      const signalingGateway = preflightTest.signalingGatewayReachable;
      const turnServer = preflightTest.turnServersReachable;

      const finalTestResults = {
        version: '1', // 1 == daily, none = twilio
        browserInformation: {
          ua: navigator.userAgent
        },
        connectivityResults: {
          signalingGateway,
          turnServer,
        },
        preflightTestReport: {
          report: preflightTest.report,
          error: preflightTest.error,
          failed: getPreflightFailed(preflightTest.report) ?? !!preflightTest.error,
          stopped: getPreflightStopped(preflightTest.report),
          result: getQualityScore(preflightTest.report, duration),
        },
        appVersion: process.env.REACT_APP_VERSION,
      };

      logger.info('Sending diag report', finalTestResults);
      sendDiagnosticReport(finalTestResults)
        .then(() => {
          dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.REPORT_SENT });
        })
        .catch(error => {
          logger.error('failed to save diagnostics report', error);
        });
    }
  }, [preflightTestFinished, reportSent, sendDiagnosticReport])
}