import React, {useState} from 'react';
import {useIntl, FormattedMessage} from 'react-intl';

import ChatHeader from '../../../../components/chatHeader';
import {FilledButton, StyledInput} from '../../../../components';
import {chatScreenMessages} from '../../messages';
import {errorPageMessages} from '../../../error-page/messages';
import {isEmail} from '../../../../utils';
import {useContact} from '../../../../hooks/useShop';
import CaazamError from '../../../../utils/errors';
import { useCustomerDetails } from '../../../../components/CustomerDetailsProvider';
import './styles.scss';
import { useGA4Analytics } from '../../../../hooks/useGA4Analytics';

const ChatErrorScreen = ({close}) => {
  const {
    name,
    email,
    setCustomerName,
    setCustomerEmail,
  } = useCustomerDetails();
  const [message, setMessage] = useState('');
  const [formError, setFormError] = useState(null);
  const intl = useIntl();
  const {contactRequest} = useContact();
  const {sendAnalyticsCallEvent} = useGA4Analytics()

  const onCustomerName = (e) => setCustomerName(e.target.value);

  const onCustomerEmail = (e) => setCustomerEmail(e.target.value);

  const updateMessage = (e) => setMessage(e.target.value);

  const sendMessage = async () => {
    if (!email) {
      setFormError(errorPageMessages.email_error);
      return;
    } else if (!isEmail(email)) {
      setFormError(errorPageMessages.email_invalid);
      return;
    }

    try {
      const res = await contactRequest(
        name,
        email,
        message,
        null,
      );
      if (res.ok) {
        sendAnalyticsCallEvent('support_message_posted');
        close({resetStack: true});
        return;
      } else {
        const errorJson = await res.json();
        throw new CaazamError(
          res.status,
          errorJson.error.reason || 'Something went wrong'
        );
      }
    } catch (err) {
      console.error(err);
      close({resetStack: true});
    }
  };

  return (
    <div className='error-chat'>
      <ChatHeader error={true} />
      <div className='error-chat_content'>
        <div className='error-chat_messageitem'>
          <FormattedMessage {...chatScreenMessages.unavailableTitle} />
        </div>
        <StyledInput
          placeholder={intl.formatMessage(chatScreenMessages.name)}
          onChange={onCustomerName}
          name='name'
          value={name}
          className='error-chat_input'
        />
        <span className='error-chat_divider'/>
        <StyledInput
          placeholder={intl.formatMessage(chatScreenMessages.email) + '*'}
          value={email}
          onChange={onCustomerEmail}
          name='email'
          type='email'
          className='error-chat_input'
        />
        <div className='error-chat_textinput'>
          <textarea
            placeholder={intl.formatMessage(chatScreenMessages.errorMessage)}
            rows='5'
            className='caazam-input'
            onChange={updateMessage}
          />
          {formError
            ? <p className='error-message'>
                <FormattedMessage {...formError} />
              </p>
            : <div className='error-message-placeholder' />
          }
        </div>
        <FilledButton
          onClick={sendMessage}
          disabled={!name || !email}
          className='error-chat_send'
          type='submit'
        >
          <FormattedMessage {...chatScreenMessages.send} />
        </FilledButton>
      </div>
    </div>
  );
};

export default ChatErrorScreen;
