import React, { useState, useEffect, useCallback, useRef } from 'react';
import ArrowBackIcon from '../../../../../../assets/icons/ArrowBack';
import Select from 'react-select';
import ChatIcon from '../../../../../../assets/icons/Chat';
import { FilledButton } from '../../../../../../components';
import './product-sidebar.scss';
import {
  useSessionCart,
  useSessionCartActions,
  useSessionShowroom,
  useSessionShowroomActions,
  useSessionCartItem,
  useSessionDataClientView,
} from '../../../../../../hooks';
import { useCallContextProvider } from '../../../../../../components/CallContextProvider';
import { parseIdString } from '../../../../../../utils';
import { generatVariantTitle, getImagePerSelectedVariant } from '../../../../../../utils/shopify';
import { Loader } from '../../../../../../components';
import { useProductViewProvider } from '../../../../../../components/ProductViewProvider'
import { useIntl, FormattedMessage } from 'react-intl';
import FullScreenProductView from "../product-sidebar-components/FullScreenProductView/fullSizedImageView";
import { restoreVideoViews } from "../../../../../../utils/ui";
import { ProductDetails } from '../../../../../../components/productDetails';
import { ProductDetailsSubmitActions } from '../components/product-details-submit-actions/ProductDetailsSubmitActions';
import { sidebarMessages } from '../../../../intl/sidebar-messages/messages';
import { useGA4Analytics } from '../../../../../../hooks/useGA4Analytics';

const ProductSidebar = ({
  isFullScreenEnabled,
  setVideoChatOpen,
  isSearchDisabled
}) => {
  const intl = useIntl();
  const productDetailsRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [availableOptions, setAvailableOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [emptyOptions, setEmptyOptions] = useState([]);

  const [cleanProductId, setCleanProductId] = useState(null);
  const [variantNotFound, setVariantNotFound] = useState(false);
  const [variantNotInIventory, setVariantNotInInventory] = useState(null);
  const { callId, activeCartId } = useCallContextProvider();
  const { cartSnapshots, cartSnapshotsLength, cartSnapshotLoading } = useSessionCart(callId, activeCartId);
  const { addToCart, updateCartProduct, removeCartProduct } = useSessionCartActions(callId, activeCartId);
  const { sessionSnapshots, sessionSnapshotsLength, sessionSnapshotLoading } = useSessionShowroom(callId);
  const { addToSession } = useSessionShowroomActions(callId);

  const [currentImages, setCurrentImages] = useState([]);
  const [selectedProductVariant, setSelectedProductVariant] = useState(null);
  const [isShowFullDetails, showFullDetails] = useState(false);
  const [isShowMobileDescription, showMobileDescription] = useState(false);

  const { productViewData: { product, cartKey: viewedCartKey, cartData: viewedCartData, showroomKey: viewedShowroomKey, presentedImage, presentedProductId,
    presentedVariantId, presentedVariantOptions }, isProductLoading, clearSelectedProduct, isPresentationEnabled } = useProductViewProvider();
  const [cartItemKey, setCartItemKey] = useState(viewedCartKey);
  const [showroomItemKey, setShowroomProductKey] = useState(viewedShowroomKey);
  const cartItem = useSessionCartItem(callId, activeCartId, cartItemKey);
  const { updateClientProductView } = useSessionDataClientView();
  const imagesRefs = useRef([]);
  const {sendAnalyticsEcommEvent} = useGA4Analytics()

  useEffect(() => {
    if (isFullScreenEnabled) {
      hideFullDetails();
    }
  }, [isFullScreenEnabled])

  const returnToSearch = () => {
    clearSelectedProduct();
  }

  const readOnlyInput = useCallback((node) => {
    if (node) {
      node.select.inputRef.readOnly = true;
    }
  }, []);

  const updateSelectedOptions = (optionName, optionValue) => {
    setSelectedOptions((existingOptions) => {
      const existingOptionsCopy = { ...existingOptions };
      existingOptionsCopy[optionName] = optionValue;
      return existingOptionsCopy;
    });
  };

  const hasOnlyDefaultVariant = product && product.hasOnlyDefaultVariant;
  const hasSingleVariant = product && product.variants.length === 1;

  const buildProductVariantOptions = (productForOptions) => {

    if (!productForOptions.options) {
      return null;
    }

    let dropDownOptions = productForOptions.options.map(option => {
      const availableValues = option.values.map(value => ({
        label: value,
        value,
        category: option.name,
      }));
      return {
        name: option.name,
        placeholder: intl.formatMessage(sidebarMessages.product_variant_select, { product_option: option.name.toLowerCase() }),
        selectValues: availableValues,
      }
    });
    return dropDownOptions;
  }

  const findVariantsForSelecetedOptions = () => {
    return product.variants.find(variant =>
      variant.selectedOptions.every(productOption =>
        selectedOptions[productOption.name] && selectedOptions[productOption.name].value === productOption.value
      )
    )
  }

  // when product/cartdata changes, set the aviable options and their default values
  useEffect(() => {
    if (product) {
      setCleanProductId(parseIdString(product.productId));
      if (product.images && product.images.length > 0) {
        setCurrentImages(product.images);
        setSelectedImage(product.images[0]);
      }

      const productOptions = buildProductVariantOptions(product);
      setAvailableOptions(productOptions);
      if (!productOptions) return;

      // if specific option has only 1 value then set it as the default selected value,
      for (const option of productOptions) {
        if (option.selectValues.length === 1) {
          updateSelectedOptions(option.name, option.selectValues[0]);
        }
      }

      // if product is in cart configure the selected options to default to cart variants values
      if (viewedCartKey) {
        var variantSelectedOptions = {};
        const cartVariant = product.variants.find(variant => parseIdString(variant.id) === viewedCartData.variantId);

        for (const option of productOptions) {
          const variantSelectedOption = cartVariant.selectedOptions.find(selectedOption => selectedOption.name === option.name);
          variantSelectedOptions[variantSelectedOption.name] = {
            label: variantSelectedOption.value,
            value: variantSelectedOption.value,
          }
        }
        setCartItemKey(viewedCartKey);
        setSelectedOptions(variantSelectedOptions);
      } else if (presentedVariantId && presentedVariantOptions && Object.keys(presentedVariantOptions).length > 0) {
        let presentedVariantSelectedOptions = {};
        presentedVariantOptions.forEach(v => {
          presentedVariantSelectedOptions[v.category] = {
            label: v.value,
            value: v.value,
          }
        })
        setSelectedOptions(presentedVariantSelectedOptions);
      } else {
        setCartItemKey(null);
      }
    }
    return () => {
      setSelectedOptions({});
      setEmptyOptions([]);
      setAvailableOptions(null);
      setCleanProductId(null);
      setVariantNotFound(null);
      setVariantNotInInventory(null);
      setSelectedProductVariant(null);
      setSelectedImage(null);
      setCurrentImages([]);
    };
  }, [product, viewedCartKey, presentedVariantId]);


  // check if product is in showroom (might have been added by remote participant)
  useEffect(() => {
    if (!sessionSnapshotLoading) {
      const showroomItem = sessionSnapshots.find((snapshot) => snapshot.val().productId === cleanProductId);
      setShowroomProductKey(showroomItem ? showroomItem.key : null);
    }
  }, [cleanProductId, sessionSnapshotsLength, sessionSnapshotLoading]);

  // check if item was in cart but removed
  useEffect(() => {

    if (cartItemKey && !cartSnapshotLoading) {
      const existsInCart = cartSnapshots.find(snap => snap.key === cartItemKey)
      if (!existsInCart)
        setCartItemKey(null);
    }

  }, [cartItemKey, cartSnapshotsLength, cartSnapshotLoading]);

  // when option vlaues are set by user, find the relevant variant and show its specific image
  useEffect(() => {

    if (availableOptions && Object.keys(selectedOptions).length === availableOptions.length) {
      setVariantNotInInventory(null);
      const selectedVariant = findVariantsForSelecetedOptions();
      setSelectedProductVariant(selectedVariant || null);
      if (selectedVariant && selectedVariant.image) {
        const index = product.images.findIndex(item => item.id === selectedVariant.image.id);
        let item;
        let arrayImages = [...product.images];
        if (index !== -1) {
          item = arrayImages.splice(index, 1);
        } else {
          item = [selectedVariant.image];
        }
        setCurrentImages([...item, ...arrayImages])
        setSelectedImage(selectedVariant.image)
      } else {
        setSelectedImage(product.images[0])
        setCurrentImages([...product.images])
      }
    }
  }, [selectedOptions, availableOptions])

  // send analytics event when product is viewed
  useEffect(() => {
    if (product) {
      sendAnalyticsEcommEvent('view_item', {
        items: [
          {
            item_id: parseIdString(product.productId),
            item_name: product.title,
          },
        ],
      });
    }
  }, [product]);

  /* START - report which product client is currently viewing */

  useEffect(() => {
    return () => updateClientProductView({});
  }, [])

  useEffect(() => {
    if (isPresentationEnabled && presentedProductId) {
      if (currentImages.length > 0) {
        let image = selectedImage;
        let presentedImageIndex = -1;
        if (presentedImage !== null) {
          presentedImageIndex = currentImages.findIndex(v => parseIdString(v.id) === presentedImage);
          if (presentedImageIndex !== -1) {
            image = currentImages[presentedImageIndex];
          }
          setSelectedImage({ ...image, index: presentedImageIndex });
        } else {
          setSelectedImage(null);
        }
      }
      showFullDetails(true);
    }
  }, [isPresentationEnabled, presentedProductId, presentedImage, currentImages]);

  useEffect(() => {
    updateClientProductView({
      productId: cleanProductId,
      showroomKey: showroomItemKey,
      cartKey: viewedCartKey,
      imageId: selectedImage ? selectedImage.id : null,
    })
  }, [cleanProductId, showroomItemKey, viewedCartKey, selectedImage])

  useEffect(() => {
    if (selectedImage) {
      if (isPresentationEnabled) {
        setTimeout(() => scrollToView(), 500);
      } else {
        scrollToView();
      }
    }
  }, [selectedImage])

  const scrollToView = () => {
    const activeImageId = imagesRefs.current.findIndex(i => i.id === selectedImage.id);
    if (activeImageId !== -1 && window.innerWidth >= 1025) {
      imagesRefs.current[activeImageId].ref.scrollIntoView({
        behavior: 'smooth',
        block: "end",
        inline: "nearest"
      });
    }
  }

  const validateVariantForCart = () => {

    const notSelectedOptions = [];

    if (availableOptions) {
      for (const option of availableOptions) {
        if (!selectedOptions[option.name]) {
          notSelectedOptions.push(option);
        }
      }
    }

    if (notSelectedOptions.length) {
      const emptyOptions = notSelectedOptions.reduce(
        (accumulatorArr, option) => {
          return [...accumulatorArr, option.name];
        },
        []
      );
      setEmptyOptions(emptyOptions);
      return false;
    }
    setEmptyOptions([]);

    if (!selectedProductVariant) {
      setVariantNotFound(true);
      return false;
    }

    setVariantNotFound(false);
    return true;
  };

  const validateInventory = (quantity) => {

    if (quantity > selectedProductVariant.inventoryQuantity && selectedProductVariant.inventoryPolicy !== 'CONTINUE') {
      setVariantNotInInventory(true);
      return false;
    }

    setVariantNotInInventory(false);
    return true;
  };

  const addFullProductToCart = async (e) => {
    e.preventDefault();

    if (!validateVariantForCart())
      return;

    // if exact same variant is already in cart then its an "add quantity action"
    const existingCartItem = cartSnapshots.find((snapshot) => snapshot.val().variantId === parseIdString(selectedProductVariant.id))
    if (existingCartItem) {
      if (!validateInventory(existingCartItem.val().quantity + 1))
        return;

      updateCartProduct(existingCartItem.key, { quantity: existingCartItem.val().quantity + 1 });
      setCartItemKey(existingCartItem.key);

    } else {
      // else its as add to cart for first time action
      if (!validateInventory(1))
        return;

      const normalizedProduct = {
        productId: cleanProductId,
        image: getImagePerSelectedVariant(product, selectedProductVariant),
        currencyCode: selectedProductVariant.currencyCode,
        currentPrice: selectedProductVariant.price,
        productTitle: product.title,
        productDescription: product.descriptionHtml,
        variantId: parseIdString(selectedProductVariant.id),
        variantTitle: generatVariantTitle(selectedProductVariant),
        inventoryPolicy: selectedProductVariant.inventoryPolicy,
        inventoryQuantity: selectedProductVariant.inventoryQuantity,
        quantity: 1,
      };
      const newCartKey = await addToCart(normalizedProduct);
      setCartItemKey(newCartKey);
    }
  };

  const updateVariant = (e) => {
    e.preventDefault();

    if (!validateVariantForCart())
      return;

    if (!cartItem)
      return;

    // if exact same variant is already in cart then its an "add quantity action"
    const existingCartItem = cartSnapshots.find((snapshot) => snapshot.val().variantId === parseIdString(selectedProductVariant.id))
    if (existingCartItem && existingCartItem.key !== cartItemKey) {
      if (!validateInventory(existingCartItem.val().quantity + cartItem.quantity))
        return;

      updateCartProduct(existingCartItem.key, { quantity: existingCartItem.val().quantity + cartItem.quantity });
      removeCartProduct(cartItemKey, cartItem);
      setCartItemKey(existingCartItem.key);
    } else {
      // else update the exsiting cart item
      if (!validateInventory(cartItem.quantity))
        return;

      updateCartProduct(cartItemKey, {
        variantId: parseIdString(selectedProductVariant.id),
        variantTitle: generatVariantTitle(selectedProductVariant),
        currentPrice: selectedProductVariant.price,
        image: getImagePerSelectedVariant(product, selectedProductVariant),
        inventoryPolicy: selectedProductVariant.inventoryPolicy,
        inventoryQuantity: selectedProductVariant.inventoryQuantity,

      })
    }
  };

  const handleAddToShowroom = () => {
    addToSession(product);
  };

  const showFullSizedImageView = () => {
    if (!selectedImage) {
      return;
    }
    showFullDetails(true);
  };

  const showFullSizedMobileDescription = () => {
    showMobileDescription(true);
    showFullDetails(true);
  };

  const openVideoChat = () => {
    setVideoChatOpen(true);
  };

  const hideFullDetails = () => {
    if (isShowMobileDescription) {
      showMobileDescription(false);
    }
    if (isShowFullDetails) {
      restoreVideoViews();
      showFullDetails(false);
    }
  };

  const updateBtnDisableStatus = () => {
    return selectedProductVariant && cartItem && parseIdString(selectedProductVariant.id) === cartItem.variantId;
  }
  const soldOutBtnVisibilityStatus = () => {
    return selectedProductVariant && selectedProductVariant.inventoryQuantity <= 0 && selectedProductVariant.inventoryPolicy !== 'CONTINUE';
  }

  const renderErrorMessage = () => {
    return (
      <p className='error-text'>
        {emptyOptions.length
          ? <FormattedMessage {...sidebarMessages.product_empty_options} values={{ product_options: emptyOptions.join(', ') }} />
          : variantNotFound
            ? <FormattedMessage {...sidebarMessages.cart_add_no_variant} />
            : variantNotInIventory
              ? <FormattedMessage {...sidebarMessages.cart_add_no_inventory} />
              : ''}
      </p>
    );
  };

  const preventKeyboardOpen = (e) => e.preventDefault();

  const renderActions = () => {

    // don;t redner actions until we've loaded showroom and cart
    if (cartSnapshotLoading || sessionSnapshotLoading) return null;

    // if source of view was cart
    if (viewedCartKey || showroomItemKey) {
      return (
        <form>
          {availableOptions && !hasOnlyDefaultVariant
            ? availableOptions.map((option, optionIndex) => {
              return (
                <Select
                  ref={readOnlyInput}
                  key={`${product.productId}${optionIndex}`}
                  onChange={(newSelection) =>
                    updateSelectedOptions(option.name, newSelection)
                  }
                  placeholder={option.placeholder}
                  menuPlacement='auto'
                  value={selectedOptions[option.name] || null}
                  className='product-sidebar-select'
                  classNamePrefix='product-sidebar-select'
                  styles={{
                    option: (styles, { isFocused, isSelected }) => {
                      return {
                        ...styles,
                        backgroundColor: isSelected
                          ? '#DCDCDC'
                          : isFocused
                            ? 'rgba(249,139,0,0.04)'
                            : null,
                      };
                    },
                  }}
                  options={option.selectValues}
                  blurInputOnSelect={true}
                  onFocus={preventKeyboardOpen}
                />
              )
            })
            : null}
          <ProductDetailsSubmitActions
            isUpdateBtnVisible={viewedCartKey && cartItemKey && !hasSingleVariant}
            isUpdateBtnDisabled={updateBtnDisableStatus()}
            isSoldOutBtnVisible={soldOutBtnVisibilityStatus()}
            isAddToCartBtnVisible={!viewedCartKey || !cartItemKey}
            updateVariant={updateVariant}
            addFullProductToCart={addFullProductToCart} />
          {renderErrorMessage()}
        </form>)
    } else {
      return (
        <FilledButton
          style={{
            zIndex: 999,
            height: '6%',
            minHeight: '40px',
            width: '100%',
            backgroundColor: 'black',
          }}
          onClick={handleAddToShowroom}>
          <FormattedMessage {...sidebarMessages.cta_add_to_showroom} />
        </FilledButton>)
    }
  }

  return (
    <>
      {
        isShowFullDetails && product && !isFullScreenEnabled &&
        <FullScreenProductView
          product={product}
          variant={selectedProductVariant}
          currentImages={currentImages}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          sessionSnapshots={sessionSnapshots}
          addToSession={addToSession}
          hideFullDetailsView={hideFullDetails}
          isProductViewVisible={isShowFullDetails}
          isShowMobileDescription={isShowMobileDescription}
          isPresentationEnabled={isPresentationEnabled}
          presentedProductId={presentedProductId}
        />
      }
      {isProductLoading ? (
        <Loader name={null} hasText={false} />
      ) : product ? (
        <div
          ref={productDetailsRef}
          className={`product-details-container ${showroomItemKey ? 'in-showroom' : ''}} ${isShowFullDetails ? 'disabled-scroll' : ''}`}>
          <div
            className={`back-button`}>
            <div className='back-chat'>
              {!isSearchDisabled && <button id='return-to-search-arrow' onClick={returnToSearch}>
                <ArrowBackIcon color='gray' size={30} />
              </button>}
            </div>
          </div>

          <ProductDetails
            showFullSizedMobileDescription={showFullSizedMobileDescription}
            showFullSizedImageView={showFullSizedImageView}
            setSelectedImage={setSelectedImage}
            selectedProductVariant={selectedProductVariant}
            selectedImage={selectedImage}
            currentImages={currentImages}
            product={product}
            renderActions={renderActions}
            imagesRefs={imagesRefs}
          />
        </div>
      ) : (
        <p>Uh oh... no product here</p>
      )}
    </>
  );
};

export default ProductSidebar;
