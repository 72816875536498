import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useClientAuthProvider } from '../components/ClientAuthProvider'
import { useInterWindowMessageProvider, REQ_HEADER_TYPES } from './InterWindowMessageProvider';
import { createAuthenticatedGetRequest, handleResponse } from '../utils/http';

import { osVersion, isIOS, isMobile, isMobileOnly, browserName } from 'react-device-detect';
import { logger } from '../logging';
import { CART_DISABLE_REASON } from '../constants/features';
import { CURRENT_SESSION  } from '../constants/sessionActionTypes';

export const ClientConfigContext = createContext(null);

const TEN_MINUTES = 10 * 60;
const SUPPORTED_IOS_BROWSERS = ['chrome','safari', 'mobile safari'];

export default function ClientConfigProvider({
    isShopOpen,
    allowOutgoingCalls,
    disableScheduling,
    isStandaloneApp,
    sendParentMessage,
    dynamicConfig,
    realtimeConfig,
    realtimeConfigLoading,
    scheduleLinkId,
    showIntro,
    sourceUrl,
    children
}) {
    const dispatch = useDispatch();
    const { postMessage } = useInterWindowMessageProvider();
    const { contextId, user, sessionData, authLoading } = useClientAuthProvider();
    const [hostAvatars, setHostAvatars] = useState([]);
    const [preselectedEventType, setPreselectedEventType] = useState(null);
    const [hostSelectionConfig, hostSelectionConfigSet] = useState({
        loaded: false,
        enabled: false,
        anyAvailableEnabled: true,
    });
    const { shopifyContext, androidTrustedWebActivity } = useSelector(state => state.currentSession);

    const [timezone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

    const scheduleEarlyThreshold = useMemo(() => {
        return dynamicConfig?.scheduleEarlyThreshold ?? TEN_MINUTES;
    }, [dynamicConfig]);

    const scheduleLateThreshold = useMemo(() => {
        return dynamicConfig?.scheduleLateThreshold ?? TEN_MINUTES;
    }, [dynamicConfig])


    const featureSupport = useMemo(() => {
        //this check will not work to detect iPads which identify with OS X version number.
        const isIOS15Regression = isIOS && osVersion.startsWith('15.1');
        let obj = {
            //this check will work to detect iPads
            //screenSharingEnabled: !isMobile,

            //https://bugs.webkit.org/show_bug.cgi?id=230902#c18
            // MediaStreamTack audio volume too low on iOS 15.1
            gainCorrection: isIOS15Regression ? 10 : 0,
        };
        if (androidTrustedWebActivity) {
            obj.checkoutDisabled = { reason: CART_DISABLE_REASON.NO_TABS }
        }
        // on iOS 15.1 openeing a new tab will kill the call session
        if (isIOS15Regression) {
            obj.checkoutDisabled = { reason: CART_DISABLE_REASON.IOS_15_1 }
        }

        // on iOS, WebKit in-app broswers (Facebook, Instagram, etc) don't support mutliple tabs resuling in checkout killing the call session
        // SFSafariViewControllers (which identify as plain vanilla Safari) do allow tabs (but in a single stacked view).
        if (isIOS && !SUPPORTED_IOS_BROWSERS.includes(browserName.toLowerCase())) {            
            obj.videoCallsDisabled = true;
        }

        return obj;
    }, [androidTrustedWebActivity, shopifyContext, realtimeConfig])

    useEffect(() => {
        if (!realtimeConfigLoading && realtimeConfig && sessionData) {
            // allow host selection only if the session/context doesn't have a host and config is enabled
            hostSelectionConfigSet({
                loaded: true,
                enabled: !sessionData.host && !!realtimeConfig.hostSelectionEnabled,
                anyAvailableEnabled: !!realtimeConfig.hostAnyAvailableEnabled,
            });
            if (sessionData.eventType?.eventTypeId !== 'default') {
                setPreselectedEventType(sessionData.eventType)
            }
        }
    }, [realtimeConfigLoading, realtimeConfig, sessionData]);

    useEffect(() => {
        if (contextId) {
            if (dynamicConfig.disableCartSync) {
                logger.warn('cart sync is disabled in configuration');
                dispatch({ type: CURRENT_SESSION.SET_SHOPIFY_CART, shopifyCartData: null, disableCartSync: true });
            } else {
                const timestamp = Date.now();
                const syncInitTimer = setTimeout(() => {
                    logger.warn('Failed to init cart sync - request to fetch initial cart timeout');
                    dispatch({ type: CURRENT_SESSION.SET_SHOPIFY_CART, shopifyCartData: null, disableCartSync: true });
                }, 3000);
                logger.info(`pre-call cart sync requested`);
                postMessage(REQ_HEADER_TYPES.GET_CART_PRODUCTS, null, (data) => {
                    logger.info(`pre-call cart sync response received in ${Date.now() - timestamp}ms`, { cartId: data?.cartId });
                    clearTimeout(syncInitTimer);
                    dispatch({ type: CURRENT_SESSION.SET_SHOPIFY_CART, shopifyCartData: data, disableCartSync: false });
                });
            }
        }
    }, [dynamicConfig?.disableCartSync, contextId]);


    useEffect(() => {
        if (!authLoading && dynamicConfig.hostAvatarsDisplayed) {
            getHostsAvatars();
        }
    }, [authLoading, dynamicConfig.hostAvatarsDisplayed]);

    useEffect(() => {
        if (!realtimeConfigLoading && !!shopifyContext)
            logger.info('feature support changed', featureSupport);
    }, [featureSupport, shopifyContext, realtimeConfigLoading]);

    const getHostsAvatars = () => {
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/hosts/avatars`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { hosts } = data;
                setHostAvatars(hosts.map(h=> h.avatar));
            }).catch(error => {
                logger.error('getHostsAvatars', error);
                setHostAvatars([]);
            })
    }

    return <ClientConfigContext.Provider value={{
        isShopOpen,
        allowOutgoingCalls,
        disableScheduling,
        isStandaloneApp,
        isMobile: isMobileOnly,
        sendParentMessage,
        dynamicConfig,
        realtimeConfig,
        realtimeConfigLoading,
        isChatEnabled: realtimeConfig ? realtimeConfig.isChatEnabled : dynamicConfig.isChatEnabled,
        hostSelectionConfig,
        preselectedEventType,
        isScheduleLink: !!scheduleLinkId,
        showIntro,
        hostAvatars,
        timezone,
        sourceUrl,
        featureSupport,
        scheduleEarlyThreshold,
        scheduleLateThreshold,

    }}>
        {children}
    </ClientConfigContext.Provider>
}

export function useClientConfigProvider() {
    const context = useContext(ClientConfigContext);
    if (!context) {
        throw new Error('useClientConfigProvider must be used within the scope of ClientConfigContext');
    }
    return context;
}
