import React from 'react';
import './styles.scss';
import ProductPreviewImage from './ProductPreviewImage';
import StoryPreviewProductDescription from './StoryPreviewProductDetails';
import useStoriesProductStyle from '../../../hooks/useStoriesProductStyle';

const ExpandProductPreview = ({product, products}) => {
  const {color, size} = product.storiesConfig;
  const {top, left, right} = useStoriesProductStyle(size, products, product);

  return (
    <div
      className={'expanded-product-preview'}
      style={{
        top: Number.isInteger(top) ? `${top}px` : 'auto',
        bottom: 'auto',
        left,
        right,
        backgroundColor: `${color}99`, //60% bg opacity
      }}
    >
      <ProductPreviewImage image={product.image} size={size} />
      <StoryPreviewProductDescription product={product} />
    </div>
  )
}

export default ExpandProductPreview
