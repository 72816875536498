import React, { useEffect, useRef, useState } from 'react';
import { useProductViewProvider } from '../../../../../../../components/ProductViewProvider';
import { useCallScreenProvider } from '../../../../../../../components/CallScreenProvider';
import { ShowroomTab } from './components/showroom-tab/ShowroomTab';
import { useModalViewProvider } from '../../../../../../../components/mobile-providers/ModalViewProvider';
import { FullScreenProductView } from '../../../modals/full-screen-product-view/FullScreenProductView';
import usePrevious from '../../../../../../../hooks/usePreviousState';
import { FormattedMessage } from 'react-intl';
import { callScreenMessages } from '../../../../../intl/call-screen/messages';
import { mobileTabs as tabs, state, tabLabels, tabKey } from '../../../../../../../constants/menuDrawer';
import { CartTab } from './components/cart-tab/CartTab';
import { ChatTab } from './components/chat-tab/ChatTab';
import { ProductDetailsView } from '../../../modals/product-details-view/ProductDetailsView';
import './TabsContent.scss';

const TabsComponents = {
	[tabKey.SHOWROOM]: <ShowroomTab />,
	[tabKey.CART]: <CartTab />,
	[tabKey.CHAT]: <ChatTab />
}

export const TabsContent = () => {
	const { isPresentationEnabled, isPresentationModeReady, clearSelectedProduct } = useProductViewProvider();
	const isPMvisibleRef = useRef(false);
	const {
		activeTab,
		openDrawer,
		hostName,
		drawerSize,
		isChatEnabled,
		draftOrder,
		onFocusTab
	} = useCallScreenProvider();

	const { showView, dismissView, destroyModalViews, isModalViewAnimationDone } = useModalViewProvider();
	const [activeTabPosition, setActiveTabPosition] = useState(0);
	const prevActiveTab = usePrevious(activeTab);
	const prevDrawerSize = usePrevious(drawerSize);
	const [cartHidden, setCartHidden] = useState(false);
	const [preventAnimation, setPreventAnimation] = useState(false);
	const [isPMFlowAvailable, setPMFlowAvailable] = useState(false);

	useEffect(() => {
		if (drawerSize !== prevDrawerSize && activeTab !== prevActiveTab) {
			destroyModalViews();
		}
		const isPreventAnimation = drawerSize !== prevDrawerSize
		setPreventAnimation(isPreventAnimation);
		if (isPreventAnimation) {
			onFocusTab(activeTab)
		}

		if (prevActiveTab === tabLabels.SHOWROOM && activeTab === tabLabels.CHAT) {
			setCartHidden(true);
		} else if (prevActiveTab === tabLabels.CHAT && activeTab === tabLabels.SHOWROOM) {
			setCartHidden(true);
		} else {
			setCartHidden(false);
		}

		switch (activeTab) {
			case tabLabels.SHOWROOM:
				setActiveTabPosition('0')
				return
			case tabLabels.CART:
				setActiveTabPosition('-100%')
				return
			case tabLabels.CHAT:
				setActiveTabPosition('-200%')
				return;
			default:
				return;
		}

	}, [activeTab])

	useEffect(() => {
		if (isModalViewAnimationDone) {
			if (isPresentationEnabled && !isPMvisibleRef.current) {
				isPMvisibleRef.current = true;
				const modal = {
					headerLabel: <div className='mobile-presentation-mode-view-header'><FormattedMessage {...callScreenMessages.host_is_presenting} values={{ name: hostName }} /></div>,
					viewPort: true,
					type: 'presentation-mode',
					component: <FullScreenProductView isPresentationMode={true} />
				}
				showView(modal)
			} else if (!isPresentationEnabled && isPMvisibleRef.current && !isPresentationModeReady) {
				openDrawer(tabLabels.SHOWROOM, state.FULL_EXPANDED);
				isPMvisibleRef.current = false;
				dismissView().then(() => {
					setPMFlowAvailable(true);
				});
			}
		}
	}, [isPresentationEnabled, isPresentationModeReady, isModalViewAnimationDone]);

	useEffect(() => {
		if (isPMFlowAvailable && isModalViewAnimationDone && !isPresentationEnabled) {
			setPMFlowAvailable(false);
				setTimeout(() => {
					showView({
						dismissAlloved: true,
						type: 'product-details',
						onDismiss: clearSelectedProduct,
						component: <ProductDetailsView />
					});
				}, 0)
		}
	}, [isPMFlowAvailable, isModalViewAnimationDone, isPresentationEnabled])

	return (
		<div className='mobile-tabs-wrapper'>
			<div
				className='mobile-tabs-container'
				style={{
					transform: `translate3d(${activeTabPosition}, 0,0)`,
					transitionDuration: preventAnimation ? '0s' : '.4s'
				}}
				onTransitionEnd={() => onFocusTab(activeTab)}
			>
				{tabs(isChatEnabled, draftOrder).map((tab) => {
					if (tab.label === tabLabels.CART) {
						return (
							<div key={`${tab.key}-${tab.label}`} className='mobile-tab-wrapper' style={{ visibility: cartHidden ? 'hidden' : 'visible' }}>
								{cartHidden}
								{TabsComponents[tab.key]}
							</div>
						)
					} else {
						return (
							<div key={`${tab.key}-${tab.label}`} className='mobile-tab-wrapper'>
								{TabsComponents[tab.key]}
							</div>
						)
					}
				})}
			</div>
		</div>
	);
}
