import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { boutiqFormMessages } from '../../messages';
import { ErrorContainer } from '../error-container/ErrorContainer';
import './BoutiqTextField.scss';

export const BoutiqTextField = ({
    label = '',
    fieldId = '',
    focus = false,
    placeholder = '',
    value = '',
    type = 'text',
    validationType = 'text',
    fieldName = '',
    required = false,
    disabled = false,
    rows = 1,
    error = null,
    errorHandler = true,
    handleChange = () => {},
    onSubmit = () => {},
    refProvider = () => {}
}) => {
    const inputRef = useRef(null);
    const intl = useIntl();

    const handleKeyUp = (e) => {
        if (e.keyCode === 13) {
            if (inputRef.current) {
                inputRef.current.blur();
                onSubmit(fieldName, e.target.value, validationType, required);
            }
        }
    };

    useEffect(() => {
      if (focus) {
        if (inputRef.current) {
            inputRef.current.focus();
        } 
      }
    }, [focus])

    return (
        <div ref={refProvider(fieldId)}  className={`text-field-container${disabled ? ' disabled' : ''}`} >
           {label && <span className='text-field-placeholder'>
                {`${label}${required ? ` ${intl.formatMessage(boutiqFormMessages.required)}` : ''}`}
            </span>}
            <div className='text-field-wrapper'>
                {rows === 1 ?
                    <input
                        ref={inputRef}
                        onKeyUp={handleKeyUp}
                        onChange={(e) => handleChange(e, fieldName, e.target.value)}
                        onBlur={(e) => onSubmit(fieldName, e.target.value, validationType, required)}
                        className='text-field'
                        name={fieldName}
                        value={value || ''}
                        type={type === 'phone' ? 'tel' : type}
                        required={required}
                        placeholder={placeholder} 
                        aria-label={fieldName} 
                        aria-invalid={error ? 'true' : 'false'}
                        aria-describedby={`${fieldName}-error`}
                        /> :

                    <textarea
                        ref={inputRef}
                        onKeyUp={handleKeyUp}
                        onChange={(e) => handleChange(e, fieldName, e.target.value)}
                        onBlur={(e) => onSubmit(fieldName, e.target.value, validationType, required)}
                        className='text-field area'
                        name={fieldName}
                        value={value || ''}
                        type={type}
                        required={required}
                        rows={rows}
                        placeholder={placeholder}
                        aria-label={fieldName}
                        aria-invalid={error ? 'true' : 'false'}
                        aria-describedby={`${fieldName}-error`}
                        >
                    </textarea>
                }
                <div className='text-field-border'></div>
            </div>
            <ErrorContainer required={errorHandler} error={error} fieldName={`${fieldName}-error`}/>
        </div >
    )
}