
import React, { createContext, useContext, useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useClientAuthProvider } from './ClientAuthProvider';
import { useClientConfigProvider } from './ClientConfigProvider';
import { logger } from '../logging';
import { createAuthenticatedGetRequest, createAuthenticatedPostRequest, createAuthenticatedDeleteRequest, handleResponse } from '../utils/http';
import getDefaultFormSchema from './boutiq-form/consts/DefaultSchema';

export const SchdeulerContext = createContext(null);

export default function SchdeuleProvider({ children }) {
    const { user, shopId, contextId, scheduleId, authLoading } = useClientAuthProvider();
    const { dynamicConfig, hostSelectionConfig } = useClientConfigProvider();
    const [globalScheduleEventTypes, globalScheduleEventTypesSet] = useState([]);
    const [sessionHosts, setSessionHosts] = useState([]);
    const [sessionHostsLoading, setSessionHostsLoading] = useState(true);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [instanceCounter, setInstanceCounter] = useState(0);
    const [hostId, hostIdSet] = useState(null);
    const [eventType, eventTypeSet] = useState(null);
    const [scheduleItemData, setScheduleItemData] = useState(null);
    const [isScheduleItemDataLoading, setScheduleItemDataIsLoading] = useState(true);

    const [schedulingFormSchema, setSchedulingFormSchema] = useState(getDefaultFormSchema(dynamicConfig?.appForms));
    const [schedulingFormSchemaLoading, setSchedulingFormSchemaLoading] = useState(true);

    const schedulingCache = useRef({});
    const schedulingKey = useMemo(()=> `${eventType ?? 'default_event'}_${hostId ?? 'default_host'}`,[hostId, eventType]);

    const getSlotDay = (dateObject) =>
        new Date(dateObject.getFullYear(),dateObject.getMonth(), dateObject.getDate());
        
    const buildSlots = (slots) => {
        if (!slots || slots.length === 0) {
            return [];
        }
        let slotsByDate = [];
        let currentDate = null;
        let currentDateSlots = [];
        slots.forEach((slot) => {
            let slotDate = getSlotDay(new Date(slot.start));            
            if (slotDate.getTime() !== currentDate?.getTime()) {
                if (!!currentDate) {
                    slotsByDate.push({ date: new Date(currentDate), slots: [...currentDateSlots] })
                }
                currentDateSlots = [];
                currentDate = new Date(slotDate);
            }

            currentDateSlots.push({
                isAvailable: slot.isAvailable,
                timeSlot: new Date(slot.start),
                timeSlotEnd: new Date(slot.end),
            });
        });
        // for last date (we've left the loop already)
        slotsByDate.push({ date: new Date(currentDate), slots: [...currentDateSlots] });
        return slotsByDate;
    }

    const scheduleAPIQuery = (timestamp) => {
        let urlParams = new URLSearchParams();
        if (timestamp) {
            urlParams.append('timestamp', timestamp);
        }
        if (scheduleId) {
            urlParams.append('scheduleId', scheduleId);
        }
        if (hostId) {
            urlParams.append('hostId', hostId);
        }
        if (eventType) {
            urlParams.append('eventTypeId', eventType);
        }
        return urlParams;
    }

    const getConfig = (timestamp = null) => {
        let urlParams = scheduleAPIQuery(timestamp);
        logger.info('Requesting scheduling config', { urlParams: urlParams.toString()});
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule?${urlParams.toString()}`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { slots, status, eventTypes } = data;
                const slotsByDate = buildSlots(slots);
                logger.info('Scheduling config received', { status, eventTypes, slots: slotsByDate?.length });
                return { slotsByDate, status, eventTypes };
            }).catch(error => {
                logger.error('SchdeuleProvider getConfig', error);
                return { slotsByDate: [], status: false, eventTypes: [] };
            });
    }

    const getFormSchema = () => {
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/clientFormSchema`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { schema } = data;     
                logger.info('Scheduling form schema received', { schemaVersion: schema?.version });
                return schema;
            }).catch(error => {
                logger.error('SchdeuleProvider getFormSchema', error);
                return null;
            });
    }

    const getSlots = (timestamp = null) => {
        let urlParams = scheduleAPIQuery(timestamp);
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/slots?${urlParams.toString()}`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { slots } = data;
                const slotsByDate = buildSlots(slots);                
                return slotsByDate;
            }).catch(error => {
                logger.error('SchdeuleProvider getSlots', error);
                return [];
            });
    }

    const getSchdeuleStatus = (timestamp = null) => {
        if (schedulingCache.current[schedulingKey]?.status) return schedulingCache.current[schedulingKey]?.status;
        let urlParams = scheduleAPIQuery(timestamp);
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/status?${urlParams.toString()}`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { status } = data;
                schedulingCache.current[schedulingKey] = {
                    ...schedulingCache.current[schedulingKey],
                    status,
                }
                return status;
            }).catch(error => {
                logger.error('SchdeuleProvider getSchdeuleStatus', error);
                return false;
            });
    }

    const getSchdeuleItem = (scheduleId) => {
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/${scheduleId}`)
            .then((res) => handleResponse(res))
            .then(data => {
                setScheduleItemData(data.scheduleData);
                eventTypeSet(data.scheduleData?.eventType?.eventTypeId);
            })
            .catch(error => {
                logger.error(`SchdeuleProvider getSchdeuleItem ${scheduleId}`, error);
                setScheduleItemData(null);
            });
    }

    const scheduleCall = useCallback((scheduleData) => {
        return createAuthenticatedPostRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule`, scheduleData)
            .then(res => handleResponse(res));
    }, [user, shopId, contextId])

    const rescheduleCall = useCallback((scheduleData, scheduleId) => {
        if (!scheduleId) throw new Error('cannot reschedule call w/o valid scheduleId');
        return createAuthenticatedPostRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/${scheduleId}`, scheduleData)
            .then(res => handleResponse(res));
    }, [user, shopId, contextId]);

    const deleteScheduledCall = useCallback((scheduleId) => {
        if (!scheduleId) throw new Error('cannot reschedule call w/o valid scheduleId');
        return createAuthenticatedDeleteRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/schedule/${scheduleId}`)
            .then(res => handleResponse(res))
            .catch(error => console.error('deleteScheduledCall', error));
    }, [user, shopId, contextId])

    const getHosts = () => {
        return createAuthenticatedGetRequest(user, `${process.env.REACT_APP_CAAZAM_REST_EP}/vs/context/${contextId}/hosts`)
            .then((res) => handleResponse(res))
            .then(data => {
                const { hosts } = data;
                setSessionHosts(hosts);
            }).catch(error => {
                logger.error('getHosts', error);
                setSessionHosts([]);
            }).finally(() => setSessionHostsLoading(false));
    }

    // get scheduling data if a rescehduling session
    useEffect(() => {
        if (!authLoading && user && contextId) {
            if (!scheduleId) {
                setScheduleItemDataIsLoading(false);
            } else {
                setScheduleItemDataIsLoading(true);
                getSchdeuleItem(scheduleId)
                    .finally(() => setScheduleItemDataIsLoading(false));
            }
            return () => setScheduleItemData(null);
        }
    }, [authLoading, user, contextId, scheduleId]);

    useEffect(() => {
        if (hostSelectionConfig?.loaded && hostSelectionConfig?.enabled) {
            setSessionHostsLoading(true);
            getHosts();
        }
    },[hostSelectionConfig]);

    // get slots and scehduling status for session 
    useEffect(() => {
        if (!authLoading && user && contextId && !isScheduleItemDataLoading) {
            if (schedulingCache.current[schedulingKey]) return;
            setIsLoading(true);
            getConfig()
                .then(({ slotsByDate: slots, status, eventTypes }) => {
                    schedulingCache.current[schedulingKey] = {
                        status,
                        slots,
                    }
                    eventTypes && globalScheduleEventTypesSet(eventTypes);
                }).finally(() => {
                    setIsLoading(false);
                    setInitialLoadComplete(true);
                });
        }
    }, [authLoading, user, contextId, scheduleId, hostId, instanceCounter, isScheduleItemDataLoading]);

    useEffect(() => {
        if (!authLoading && user && contextId && eventType && initialLoadComplete) {
            if (schedulingCache.current[schedulingKey]?.slots) return;
            setIsLoading(true);
            getSlots()
                .then(slots => {
                    schedulingCache.current[schedulingKey] = {
                        ...schedulingCache.current[schedulingKey],
                        slots,
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [authLoading, user, contextId, scheduleId, hostId, eventType, instanceCounter, initialLoadComplete]);

    useEffect(() => {
        if (!authLoading && user && contextId) {
            setSchedulingFormSchemaLoading(true);
            getFormSchema()
                .then(schema => {
                    if (schema) {
                        setSchedulingFormSchema(schema);
                    }
                }).finally(() => {
                    setSchedulingFormSchemaLoading(false);
                });
        }
    }, [authLoading, user, contextId])

    const refresh = useCallback(() => {
            logger.info('reseting scheduling config state');
            schedulingCache.current = {};        
            setInitialLoadComplete(false);    
            setInstanceCounter(curr => curr + 1);
    }, [instanceCounter]);

    const setHostForScheduling = (newHostId) => {
        hostIdSet(newHostId);
    }

    const setEventTypeForScheduling = (eventTypeId) => {
        eventTypeSet(eventTypeId);
    }

    return <SchdeulerContext.Provider value={{
        availableSlots: schedulingCache.current[schedulingKey]?.slots,
        scheduleStatus: schedulingCache.current[schedulingKey]?.status,
        scheduleEventTypes: globalScheduleEventTypes,
        isScheduleLoading: isLoading,
        scheduleCall,
        getSchdeuleStatus,
        scheduleId,
        scheduleItemData,
        isScheduleItemDataLoading,
        rescheduleCall,
        deleteScheduledCall,
        setHostForScheduling,
        hostIdForScheduling: hostId,
        eventType, 
        setEventTypeForScheduling,
        schedulingFormSchema,
        schedulingFormSchemaLoading,
        sessionHosts,
        sessionHostsLoading,
        refresh,
    }}>
        {children}
    </SchdeulerContext.Provider>
}

export function useSchdeuleProvider() {
    const context = useContext(SchdeulerContext);
    if (!context) {
        throw new Error('useSchdeuleProvider must be used within the scope of SchdeulerContext');
    }
    return context;
}
