import { useCallback } from 'react';
import firebase from '../Firebase'
import {getDatabase, ref, push, set, child, remove, update} from 'firebase/database';
import { useList } from 'react-firebase-hooks/database';
import { parseIdString } from '../utils'
import { logger } from '../logging'
import { useGA4Analytics } from './useGA4Analytics';

const db = getDatabase(firebase);

export const useSessionShowroom = (callId) => {
  const productsRef = callId ? ref(db,`session/${callId}/products`) : null;

  const [
    sessionSnapshots,
    sessionSnapshotLoading,
    sessionSnapshotError,
  ] = useList(productsRef);

  return {
    sessionSnapshots,
    sessionSnapshotsLength: sessionSnapshots ? sessionSnapshots.length : 0,
    sessionSnapshotLoading,
    sessionSnapshotError,
  };
};

export function useSessionShowroomActions(callId) {
  const productsRef = ref(db,`session/${callId}/products`);
  const {sendAnalyticsEcommEvent} = useGA4Analytics()

  /**
 * Product data added to showroom must conform to an object.
 * Product object and product search result obbject retruned by REST API conform to the required
 * interafce.
 * All other callers of this function should adapt;
 * @param {string}  product.productId           // can be clean ('123') or full identifier ('gid://shopify/...')
 * @param {string}  product.title
 * @param {string}  [product.description]       // either description or descriptionHtml
 * @param {string}  [product.descriptionHtml]   // either description or descriptionHtml
 * @param {string}  [image]                     // image url string
 * @param {string}  [images[].url]              //array of images
 * @param {number}  maxVariantPrice
 * @param {number}  minVariantPrice
 * @param {number}  price
 * @param {string}  currencyCode
 * @param {number}  [compareAtPrice]
 *  */

  const addToSession = useCallback(async (product) => {
    try {
      const newRef = push(productsRef);
      const productVal = productDataToShowroom(product);
      await set(newRef, productVal);
      logger.info('addToShowroom', { key: newRef.key, productId: product.productId });

      sendAnalyticsEcommEvent('select_content', {
        content_type: 'showroom_product',
        content_id: productVal.productId,
      })
    } catch (error) {
      logger.error(`addToShowroom failed ${product.productId}`, error);
    }
  }, [productsRef]);

  const removeSessionProduct = (productKey) => {
    return remove(child(productsRef, productKey))
      .then(() => logger.info('removeSessionProduct', { key: productKey }))
      .catch(error => logger.error(`removeSessionProduct error ${productKey}`, error));
  }

  const clearSession = () => {
    return remove(productsRef)
      .then(() => logger.info('clearShowroom'))
      .catch(error=> logger.error('clearShowroom', error));
  }

  const loadToSession = useCallback(async (products) => {
    if (!products || !products.length) return;

    try {
      let updates = {};
      for(const product of products) {
        const newRefId = push(productsRef).key;
        const productVal = productDataToShowroom(product);
        updates[newRefId] = productVal;
      }
      await update(productsRef, updates);

    } catch (error) {
      console.error(`Failed to load product ${products.length} to session`, error);
    }
  }, [productsRef]);

  return {
    addToSession,
    removeSessionProduct,
    clearSession,
    loadToSession,
  };

}

function productDataToShowroom(productData) {

  let image = null;
  if (typeof productData.image === 'string') {
    image = productData.image;
  } else if (productData.images && productData.images.length > 0) {
    image = productData.images[0].url;
  }

  return {
    productId: parseIdString(productData.productId),
    productTitle: productData.title,
    productDescription: productData.descriptionHtml || productData.descriptionHtml || '',
    image,
    maxVariantPrice: productData.maxVariantPrice,
    minVariantPrice: productData.minVariantPrice,
    price: productData.price,
    compareAtPrice: productData.compareAtPrice || null,
    currencyCode: productData.currencyCode,
  };
}
