import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useCustomerDetails } from '../../components/CustomerDetailsProvider';
import { useClientConfigProvider } from '../../components/ClientConfigProvider';
import storageFactory from '../../utils/storageFactory';
import { getRandom } from '../../utils';

const AVATARSQUANTITY = 5;

export const IntroPageContext = createContext(null);

export default function IntroPageProvider({ shopId, introVideosConfig, introVideosConfigLoading, children }) {
  const localStore = storageFactory(() => localStorage);
  const [shopVideosStates, setShopVideosStates] = useState(localStore.getItem(`caazam:shopVideosStates:${shopId}`) ? JSON.parse(localStore.getItem(`caazam:shopVideosStates:${shopId}`)) : []);
  const [isShopVideoStatesRefreshed, setIsShopVideoStatesRefreshed] = useState(false)
  const [isMuted, setIsMuted] = useState(false);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(0);
  const { id } = useCustomerDetails();
  const { dynamicConfig, isChatEnabled, hostAvatars } = useClientConfigProvider();
  const [videoElement, setVideoElement] = useState(null);
  const [isUserInteractWithVideoPlayer, setIsUserInteractWithVideoPlayer] = useState(false);
  const [stateChecked, setStateChecked] = useState(false);
  const playingVideoRef = useRef(null);
  const setPlayingVideoRef = (ref) => {
    playingVideoRef.current = ref;
  }
  const setListedVideos = (videos) => {
    localStore.setItem(`caazam:shopVideosStates:${shopId}`, JSON.stringify(videos));
    setShopVideosStates(videos);
  }
  /** Video buffering */
  const [isVideoBuffering, setIsVideoBuffering] = useState(true);
  /** Autoplay Permissions */
  const [autoplayPermissionsDenied, setAutoplayPermissionsDenied] = useState(false);
  /** Set playlist start */
  const [playList, setPlayList] = useState([]);
  /** Intro welcome styles */
  const [introWelcomeStyles, setIntroWelcomeStyles] = useState(null);

  useEffect(() => {
    if (!introVideosConfigLoading) {
      if (introVideosConfig) {

        setPlayList(introVideosConfig);

        if (shopVideosStates.length === 0) {
          const videoList = introVideosConfig.map(video => ({
            id: video.id,
            isPlayed: false,
          }));
          setListedVideos(videoList);
        } else {
           const newVideoArray = introVideosConfig.map((video, index) => {
            if (index < shopVideosStates.length) {
              if (shopVideosStates[index].id === video.id) {
                return ({ id: video.id, isPlayed: shopVideosStates[index].isPlayed })
              } else {
                return ({ id: video.id, isPlayed: false })
              }
            } else {
              return ({ id: video.id, isPlayed: false })
            }
           });
            setListedVideos(newVideoArray);
          }
          setIsShopVideoStatesRefreshed(true);
      }
    }
  }, [introVideosConfig, introVideosConfigLoading])

  useEffect(() => {
    if (dynamicConfig) {
        const stylesConfig = {
          app_custom_background_color: dynamicConfig.app_custom_background_color ? dynamicConfig.app_custom_background_color : null,
          app_custom_background_opacity: dynamicConfig.app_custom_background_opacity ? dynamicConfig.app_custom_background_opacity : null,
          app_custom_background_url: dynamicConfig.app_custom_background_url ? dynamicConfig.app_custom_background_url : null,
        }
        setIntroWelcomeStyles(stylesConfig);
    }
  }, [dynamicConfig])

  /** store video states */
  const updateVideoPlayingState = (videoIndex) => {
    if (shopVideosStates.length > 0 && playList.length > 0) {
      const storedVideoStates = shopVideosStates;
      const index = storedVideoStates.findIndex(item => item.id === playList[videoIndex].id);
      if (index > -1) {
        storedVideoStates[index].isPlayed = true;
        setListedVideos(storedVideoStates);
      }
    }
  }

  const setActualVideoIndex = (value = 0) => {
    if (value < 0) {
      if (playingVideoIndex > 0) {
        setPlayingVideoIndex(0)
      }
    } else {
      setPlayingVideoIndex(value)
    }
  }

  /** play video after user interaction */
  const playIntroVideo = useCallback(() => {
    setIsUserInteractWithVideoPlayer(true);
    const playPromise = videoElement.current.play();
    if (playPromise !== undefined) {
      playPromise.then(_ => { setAutoplayPermissionsDenied(false); })
    }
  }, [setIsUserInteractWithVideoPlayer, videoElement])

  return <IntroPageContext.Provider value={{
    id,
    shopVideosStates,
    setListedVideos,
    isMuted,
    setIsMuted,
    config: dynamicConfig,
    isChatEnabled,
    playingVideoIndex,
    setPlayingVideoIndex: setActualVideoIndex,
    setPlayingVideoRef,
    introVideosConfigLoading,
    setVideoElement,
    playIntroVideo,
    isUserInteractWithVideoPlayer,
    setIsUserInteractWithVideoPlayer,
    setAutoplayPermissionsDenied,
    playList,
    autoplayPermissionsDenied,
    isVideoBuffering,
    setIsVideoBuffering,
    introWelcomeStyles,
    stateChecked,
    setStateChecked,
    updateVideoPlayingState,
    isShopVideoStatesRefreshed,
    avatars: getRandom(hostAvatars, AVATARSQUANTITY) || []
  }}>
    {children}
  </IntroPageContext.Provider>
}

export function useIntroPage() {
  const context = useContext(IntroPageContext);
  if (!context) {
    throw new Error('useIntroPage must be used within the IntroPageContext Provider');
  }
  return context;
}
