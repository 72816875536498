import React from 'react'
import IntroWelcomeMessage from '../introWelcomeMessage/introWelcomeMessage'
import ArrowBackIosOutlinedIcon from '../../../assets/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '../../../assets/icons/ArrowForwardIosOutlined';
import { useIntroPage, IntroPageContext } from '../IntroPageProvider';
import {useHistory, useRouteMatch} from 'react-router-dom';
import UserPlayInteraction from '../userPlayInteraction/userPlayInteraction';
import VideoLoader from '../loader/video-loader';
import './index.scss';
import {PAGES} from "../../../constants/routes";
import {INTRO_PAGES} from "../intro-page";

export default function IntroContent({ }) {
    const {
        playingVideoIndex,
        setPlayingVideoIndex,
        playList,
        isVideoBuffering,
        autoplayPermissionsDenied,
        setAutoplayPermissionsDenied,
        setIsUserInteractWithVideoPlayer,
        stateChecked,
        updateVideoPlayingState,
        avatars,
        config
    } = useIntroPage();
    const customLogo = config && config.app_custom_logo;
    const history = useHistory();
    const { path } = useRouteMatch();

    const prev = () => {
        setIsUserInteractWithVideoPlayer(true);
        setAutoplayPermissionsDenied(false);
        if (playingVideoIndex > 0) {
            setPlayingVideoIndex(playingVideoIndex - 1);
        }
    }

    const next = () => {
        setIsUserInteractWithVideoPlayer(true);
        setAutoplayPermissionsDenied(false);
        updateVideoPlayingState(playingVideoIndex);
        if (playingVideoIndex !== playList.length - 1) {
            setPlayingVideoIndex(playingVideoIndex + 1);
        } else {
            history.push(`${path}/${INTRO_PAGES.INTRO_WELCOME}`);
        }
    }

    return (
        <div className='intro-content-wrapper'>
            {history.location.pathname === PAGES.INTRO &&
                <div className='video-controls-container' style={{marginTop: avatars.length > 0 && customLogo ? '-60px' : 0}}>
                    {playList.length > 0 && stateChecked &&
                        <>
                            <div className='prev-control-container'>
                                <button disabled={playingVideoIndex === 0} className='prev-story' onClick={prev} aria-label='previous video'>
                                    <ArrowBackIosOutlinedIcon />
                                </button>
                            </div>
                            <div className='next-control-container'>
                                <button className='next-story' onClick={next} aria-label='next video'>
                                    <ArrowForwardIosOutlinedIcon style={{width: '20px', height: '20px'}} />
                                </button>
                            </div>
                        </>}
                    <div className='state-observers'>
                        {autoplayPermissionsDenied &&
                            <IntroPageContext.Consumer>
                                {({ playIntroVideo }) => (
                                    <UserPlayInteraction onUserInteraction={playIntroVideo} />
                                )}
                            </IntroPageContext.Consumer>}
                        {(isVideoBuffering && playList.length > 0 && !autoplayPermissionsDenied) &&
                            <div className='video-loader-container'>
                                <VideoLoader />
                            </div>}
                    </div>
                </div>}
            {history.location.pathname === `${PAGES.INTRO}/${INTRO_PAGES.INTRO_WELCOME}` &&
                <div className='welcome-container'>
                    <IntroWelcomeMessage />
                </div>}
        </div>
    )
}
