import {QUALITY_LABEL, QUALITY_SCORE} from "../constants/appDiagnostics";

export const formatNumber = (val = null) => {
  if (val === null) {
    return 0;
  }
  return parseFloat(val.toFixed(3));
};

const getSingleQualityScore =(stat, goodThreshold, suboptimalThreshold, poorThreshold, descending = false) => {
  if (typeof stat === 'undefined') {
    // We ignore values that are missing
    return QUALITY_SCORE.EXCELLENT;
  }

  if (descending) {
    if (stat > goodThreshold) return QUALITY_SCORE.EXCELLENT;
    if (stat > suboptimalThreshold) return QUALITY_SCORE.GOOD;
    if (stat > poorThreshold) return QUALITY_SCORE.SUBOPTIMAL;
    return QUALITY_SCORE.POOR;
  }

  if (stat >= poorThreshold) return QUALITY_SCORE.POOR;
  if (stat >= suboptimalThreshold) return QUALITY_SCORE.SUBOPTIMAL;
  if (stat >= goodThreshold) return QUALITY_SCORE.GOOD;
  return QUALITY_SCORE.EXCELLENT;
}

const getLatency = (preflightTestReport) => {
  if (preflightTestReport?.quality?.data?.avgRoundTripTime >= 0) {
    return {
      average: formatNumber(preflightTestReport.quality.data.avgRoundTripTime * 1000),
      max: formatNumber(preflightTestReport.quality?.data?.avgRoundTripTime * 1000),
      qualityScore: getSingleQualityScore(preflightTestReport.quality.data.avgRoundTripTime * 1000, 150, 300, 600),
    }
  }
  return {average: 0, max: 0, qualityScore: 0};
}

const getPacketLoss = (preflightTestReport) => {
  if (preflightTestReport?.quality?.data?.avgRecvPacketLoss >= 0) {
    return {
      average: formatNumber(preflightTestReport.quality.data.avgRecvPacketLoss),
      max: formatNumber(preflightTestReport.quality.data.avgRecvPacketLoss), // no max provided
      qualityScore: getSingleQualityScore(preflightTestReport.quality.data.avgRecvPacketLoss, 0.01, 0.03, 0.08),
    }
  }
  return {average: 0, max: 0, qualityScore: 0};
}

const getBitsPerSecond = (preflightTestReport, duration) => {
  if (preflightTestReport?.quality?.data?.avgRecvBitsPerSecond >= 0) {
    return {
      average: formatNumber(preflightTestReport.quality.data.avgRecvBitsPerSecond),
      max: formatNumber(preflightTestReport.quality.data.avgRecvBitsPerSecond), // no max provided
      qualityScore: getSingleQualityScore(preflightTestReport.quality.data.avgRecvBitsPerSecond, 1100000, 700000, 0, true),
      duration,
    }
  }
  return {average: 0, max: 0, qualityScore: 0};
}

export function getQualityScore(preflightTestReport, duration) {
  const latency = getLatency(preflightTestReport);
  const packetLoss = getPacketLoss(preflightTestReport);
  const bitrate = getBitsPerSecond(preflightTestReport, duration);
  const totalQualityScore = Math.min(latency.qualityScore, bitrate.qualityScore, packetLoss.qualityScore);
  const totalQualityLabel = QUALITY_LABEL[totalQualityScore];
  return {
    latency,
    packetLoss,
    bitrate,
    totalQualityScore,
    totalQualityLabel,
  }
};

export function getPreflightStopped(preflightTestReport, duration) {
  if (preflightTestReport?.networkConnectivity?.result === 'aborted')
    return true;
  else if (preflightTestReport?.websocketConnectivity?.result === 'aborted')
    return true;
  else if (preflightTestReport?.quality?.result === 'aborted' || preflightTestReport?.quality?.secondsElapsed < duration)
    return true;
  else
  return false;
}

export function getPreflightFailed(preflightTestReport) {
  if (preflightTestReport?.networkConnectivity?.result === 'failed')
    return preflightTestReport?.networkConnectivity?.result;
  else if (preflightTestReport?.websocketConnectivity?.result === 'failed')
    return preflightTestReport?.websocketConnectivity?.result; 
  else if (preflightTestReport?.quality?.result === 'failed')
    return preflightTestReport?.quality?.result ; 
  else return null;
}

/* 
{
  "networkConnectivity": {
      "result": "passed"
  },
  "websocketConnectivity": {
      "abortedRegions": [],
      "failedRegions": [],
      "passedRegions": [
          "eu-central-1",
          "eu-west-2",
          "us-east-1",
          "ap-south-1",
          "af-south-1",
          "us-west-2",
          "ap-southeast-1",
          "sa-east-1",
          "ap-northeast-2",
          "ap-southeast-2"
      ],
      "result": "passed"
  },
  "quality": {
      "result": "good",
      "data": {
          "maxRoundTripTime": 0.137,
          "avgRoundTripTime": 0.13133333333333333,
          "avgRecvPacketLoss": 0.06965546070733997,
          "avgAvailableOutgoingBitrate": 3418285,
          "avgSendBitsPerSecond": 1742881,
          "avgRecvBitsPerSecond": 1784243
      },
      "secondsElapsed": 10
  }
}

 */
