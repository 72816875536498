import React, { createContext, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { VIDEO_CALL_PAGES } from '../VideoCall';
import { VideoScreenPreview } from './video-screen-preview/video-screen-preview';

export const VideoScreenPreviewContext = createContext(null);

export default function VideoScreenPreviewProvider({ localTracksError, isAcquiring, setButtonDisabled, isBoutiqVideoSupported, children }) {
  const history = useHistory();
  const { path } = useRouteMatch();

  const isVideoScreenAvailable = history.location.pathname === `${path}/${VIDEO_CALL_PAGES.WAITING_ROOM}` ||
  history.location.pathname === `${path}/${VIDEO_CALL_PAGES.VIDEO_LOBBY}` || 
  history.location.pathname === `${path}`
  const isWaitingRoom = history.location.pathname === `${path}/${VIDEO_CALL_PAGES.WAITING_ROOM}`
  return <VideoScreenPreviewContext.Provider value={{
  }}>
    {isVideoScreenAvailable && <VideoScreenPreview
      smallPreview={isWaitingRoom}
      trackError={localTracksError}
      isAcquiring={isAcquiring}
      setButtonDisabled={setButtonDisabled}
      isBoutiqVideoSupported={isBoutiqVideoSupported}
    />}
    {children}
  </VideoScreenPreviewContext.Provider>
}

export function useVideoScreenPreviewProvider() {
  const context = useContext(VideoScreenPreviewContext);
  if (!context) {
    throw new Error('useVideoScreenPreviewProvider must be used within the VideoScreenPreviewProvider');
  }
  return context;
}
