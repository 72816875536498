import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useBoutiqVideoRoomContext, useLocalTracks, browserSupport, } from '@caazam/boutiq-video-room';
import { logger } from '../logging';
import CallContextProvider from '../components/CallContextProvider';
import { useClientAuthProvider } from '../components/ClientAuthProvider';
import { useClientConfigProvider } from '../components/ClientConfigProvider';
import VideoScreenPreviewProvider from '../components/video-screen-preview/VideoScreenPreviewProvider';
import VideoLobby from '../pages/video-lobby/video-lobby';
import CallScreen from '../pages/video-screen/call-screen';
import ThankYou from '../pages/thank-you-page/thank-you-page';
import { isMobile } from 'react-device-detect';
import { WaitingRoom } from '../pages/waiting-room/waiting-room';
import useAppDiagnostics from "../hooks/useAppDiagnostics";
import { BrowserCompabilityModal } from './video-screen-preview/video-screen-preview/components/video-screen-preview-loader/browser-compability-modal/BrowserCompabilityModal';
import { AppHeaderContext } from './AppHeaderProvider';
import AudioVideoSettingsDialog from './AudioVideoSettingsDialog';
import './video-call.scss';
import CaazamError from '../utils/errors';
import { useCustomerDetails } from './CustomerDetailsProvider';

export const VIDEO_CALL_PAGES = {
    ROOT: '',
    VIDEO_LOBBY: 'video-lobby',
    WAITING_ROOM: 'waiting-room',
    CALL: 'call',
    THANK_YOU: 'thank-you',
}

function VideoCall({ onError, onClose, onCallRequested, onCallConnect, onCallDisconnect }) {
    const { path } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const [callConnectedTimestamp, setCallConnectedTimestamp] = useState(null);
    const { shopId, contextId } = useClientAuthProvider();
    const { name, initials } = useCustomerDetails();

    const { isReady, join, leave, onVideoEvent, offVideoEvent } = useBoutiqVideoRoomContext();

    const {
        localVideo,
        localAudio,
        startCamera,
        stopCamera,
    } = useLocalTracks();

    const { dynamicConfig, featureSupport } = useClientConfigProvider();
    const unloadEvent = isMobile ? 'pagehide' : 'unload';
    const MIN_CALL_FOR_FEEDBACK = dynamicConfig.minCallDurationForFeedback || 2 * 60 * 1000
    const showFeedback = callConnectedTimestamp ? Date.now() - callConnectedTimestamp > MIN_CALL_FOR_FEEDBACK : false;
    const [localTracksError, setLocalTracksError] = useState(null);
    const isBoutiqVideoSupported = browserSupport.supported && !featureSupport.videoCallsDisabled;
    const [isAcquiring, setIsAcquiring] = useState(true);
    const { AVSettingVisible, setAVSettingVisible, setAVSettingEnabled } = useContext(AppHeaderContext);
    useAppDiagnostics(contextId, !!localVideo && !isAcquiring); // should be called and cleaned up after starting/stoppong local tarcks becase of an issue with Daily aborting

    const stopAllLocalTracks = () => {
        logger.info('stopping local tracks');
        stopCamera();
    }

    const handleLocalTracksResult = (trackRes) => {
        setIsAcquiring(false);
        if (trackRes.error) {
            // if we got a partial error from startCamera.
            // if there's an error for both auduio and video, there'll be no local tracks and the call can't start
            logger.warn('Got local track error', trackRes);
            setLocalTracksError(trackRes);
        } else {
            logger.info('local tracks result', trackRes);
            setLocalTracksError(null)
        }
        setAVSettingEnabled(true);
    }

    const onVideoCallRequested = () => {
        logger.info('onVideoCallRequested');
        onCallRequested && onCallRequested();
        history.push(`${path}/${VIDEO_CALL_PAGES.WAITING_ROOM}`);
    }

    const onVideoCallDisconnect = useCallback(({ error }) => {
        logger.info('onVideoCallDisconnect', { error: error?.message ?? null });
        //window.removeEventListener(unloadEvent, leave);
        stopAllLocalTracks();
        history.push(`${path}/${VIDEO_CALL_PAGES.THANK_YOU}`);
        onCallDisconnect?.();
    }, [leave, onCallDisconnect]);

    const onVideoCallLeave = () => {
        logger.info('onVideoCallLeave');
        onCallDisconnect && onCallDisconnect();
        onError && onError(new CaazamError(499, 'client left waiting room'));
    }

    const onVideoCallConnect = useCallback((roomUrl, callToken) => {
        logger.info('onVideoCallConnect', { localAudio, localVideo });
        //this WebRTC/Daily will connect after our sig state machine is ready (connected)
        join(roomUrl,
            callToken,
            name,
            {
                initials,
            }
        )
            .then(() => {
                logger.info('CONNECTED');
                //window.addEventListener(unloadEvent, leave);
                onCallConnect && onCallConnect();
                setCallConnectedTimestamp(Date.now());
                history.push(`${path}/${VIDEO_CALL_PAGES.CALL}`)
            })
            .catch(connectError => {
                logger.error('CONNECT ERROR', connectError);
                if (connectError?.action === 'error') {
                    onError && onError(new Error(connectError.errorMsg));
                } else {
                    onError && onError(connectError);
                }

            });
    }, [join, leave]);

    const onVideoCallRejoin = () => {
        logger.info('onVideoCallRejoin');
        setIsAcquiring(true);
        if (isBoutiqVideoSupported) {
            startCamera()
                .then(handleLocalTracksResult)
                .catch(error => setLocalTracksError(error))
                .finally(() => history.push(`${path}`));// TODO  should this be replace instead?
        }
    }

    useEffect(() => {
        const errorHandler = ({ error }) => logger.error('BoutiqVideoProvider error event', error);
        const controlEventHandler = (data) => logger.info(`user video contorl event: ${data?.action}`, data);
        onVideoEvent('error', errorHandler);
        onVideoEvent('controlEvent', controlEventHandler);
        return () => {
            offVideoEvent('error', errorHandler);
            offVideoEvent('controlEvent', controlEventHandler);
        }
    }, []);

    useEffect(() => {
        onVideoEvent('disconnected', onVideoCallDisconnect);
        return () => offVideoEvent('disconnected', onVideoCallDisconnect);
    }, [onVideoCallDisconnect]);

    useEffect(() => {
        if (isBoutiqVideoSupported && isReady) {
            logger.info('getting local tracks');
            startCamera()
                .then(handleLocalTracksResult)
                .catch(error => setLocalTracksError(error));

            return () => {
                stopAllLocalTracks();
            }
        }
    }, [isReady]);

    useEffect(() => {
        const audio = localAudio
            ? {
                kind: localAudio.kind,
                id: localAudio.id,
                label: localAudio.label,
                settings: localAudio.getSettings(),
            }
            : null;

        const video = localVideo
            ? {
                kind: localVideo.kind,
                id: localVideo.id,
                label: localVideo.label,
                settings: localVideo.getSettings(),
            }
            : null;


        logger.info('change in local tracks', {
            audio,
            video,
        });
    }, [localAudio, localVideo]);

    useEffect(() => {
        if (localTracksError) {
            //logger.info('this is localTracksError', localTracksError);
            logger.error('FAILED getting local tracks', localTracksError?.error ? new Error(localTracksError.error.msg) : localTracksError);
        }
    }, [localTracksError]);

    useEffect(() => {
        if (location.pathname === `${path}/${VIDEO_CALL_PAGES.THANK_YOU}`) {
            stopAllLocalTracks();
        }
    }, [location]);

    const [isJoinButtonDisabled, setJoinButtonDisabled] = useState(true);

    return (
        <>
            <CallContextProvider shopId={shopId} contextId={contextId} onRequest={onVideoCallRequested} onConnect={onVideoCallConnect} onError={onError}>
                <VideoScreenPreviewProvider
                    setButtonDisabled={setJoinButtonDisabled}
                    localTracksError={localTracksError}
                    isAcquiring={isAcquiring}
                    isBoutiqVideoSupported={isBoutiqVideoSupported}
                >
                    <div className={`video-call-container-wrapper ${location?.pathname === path && ' video-call-container-wrapper_form-position'}`} style={{ width: '100%' }}>
                        {!isBoutiqVideoSupported && isMobile && <BrowserCompabilityModal />}
                        <Switch>
                            <Route exact path={path}>
                                <VideoLobby
                                    isButtonDisabled={isJoinButtonDisabled}
                                />
                            </Route>
                            <Route path={`${path}/${VIDEO_CALL_PAGES.WAITING_ROOM}`}>
                                <WaitingRoom
                                    onLeave={onVideoCallLeave}
                                />
                            </Route>
                            <Route path={`${path}/${VIDEO_CALL_PAGES.CALL}`}>
                                <CallScreen />
                            </Route>
                            <Route path={`${path}/${VIDEO_CALL_PAGES.THANK_YOU}`}>
                                <ThankYou
                                    close={onClose}
                                    onRejoin={onVideoCallRejoin}
                                    showFeedback={showFeedback}
                                />
                            </Route>
                        </Switch>
                    </div>
                </VideoScreenPreviewProvider>
            </CallContextProvider>
            {AVSettingVisible &&
                <div className='av-settings-dialog-container'>
                    <AudioVideoSettingsDialog
                        onClose={() => setAVSettingVisible(!AVSettingVisible)} />
                </div>
            }
        </>
    )
}

export default function VideoCallRoot({
    isAppDisplayed,
    onCallError,
    onClose,
    onVideoRequested,
    onVideoConnect,
    onVideoDisconnect,
    saveToCart,
}) {

    return isAppDisplayed
        ?
        <VideoCall
            onError={onCallError}
            onClose={onClose}
            onCallRequested={onVideoRequested}
            onCallConnect={onVideoConnect}
            onCallDisconnect={onVideoDisconnect}
            saveToCart={saveToCart}
        />
        : null;
}