import React, { useCallback } from 'react';
import { logger } from '../../logging';
import { useNetworkTests } from '@caazam/boutiq-video-room';
import { useSelector, useDispatch } from "react-redux";
import { APP_DIAGNOSTICS_ACTION_TYPES } from "../../constants/appDiagnostics";
import { getPreflightStopped } from '../../utils/diagnostics';

export default function usePreflightTest(contextId) {
  const { runTests, abortTests } = useNetworkTests();
  const dispatch = useDispatch();
  const {
    preflightTestInProgress
  } = useSelector((state) => state.appDiagnostics);


  const startPreflightTest = useCallback(async () => {
    // Don't start a new preflight test if one is already running
    if (preflightTestInProgress) {
      logger.warn('startPreflightTest already in progress');
      return;
    }

    const duration = 15;    
    dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STARTED, contextId, duration });
    logger.info('starting preflight tests', { duration });
    runTests(duration)
      .then(res => {
        if (getPreflightStopped(res, duration)) {
          logger.info('preflight aborted', res);
          dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STOPPED, res });
        } else {
          logger.info('preflight completed', res);
          dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_COMPLETED, res });
        }

      })
      .catch(error => {
        logger.error('preflight error', error);
        let errorMessage = typeof error === 'string' ? error : error.message;
        dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_FAILED, error: errorMessage });
      })
  }, [runTests, preflightTestInProgress]);

  const stopPreflightTest = () => {
    logger.info('preflight stopped');
    abortTests();
    dispatch({ type: APP_DIAGNOSTICS_ACTION_TYPES.PREFLIGHT_STOPPED }); // need to send force send this becasue sometimes daily doesn't abort immediatley
  }

  return {
    startPreflightTest,
    stopPreflightTest,
  };
}
