import React, { useState } from 'react';
import './styles.scss';
import ProductPreviewImage from './ProductPreviewImage';
import StoryPreviewProductDescription from './StoryPreviewProductDetails';
import ProductCurrentPrice from '../../../pages/video-screen/shared/ProductCurrentPrice/productCurrentPrice';
import useStoriesProductStyle from '../../../hooks/useStoriesProductStyle';

const CollapseProductPreview = ({product, products}) => {
  const {color, size} = product.storiesConfig;
  const {top, bottom, left, right} = useStoriesProductStyle(size, products, product);
  const [isHover, setHover] = useState(false)

  const onHover = () => {
    setHover(true)
  }

  const onHoverOut = () => {
    setHover(false)
  }

  return (
    <div
      className={'collapsed-product-preview'}
      style={{
        top: Number.isInteger(top) ? `${top}px` : 'auto',
        bottom: Number.isInteger(bottom) ? `${bottom}px` : 'auto',
        left,
        right,
        width: `calc(100% - ${left}px - ${right}px)`,
        backgroundColor: `${color}99` //60% bg opacity
      }}
      onMouseOver={onHover}
      onMouseLeave={onHoverOut}
    >
      <ProductPreviewImage image={product.image} size={size} />
      {isHover ? (
        <StoryPreviewProductDescription product={product} />
      ) : (
        <div className={'product-preview-price-container'}>
          <ProductCurrentPrice
            product={product}
            isLongPrice={(product.compareAtPrice && product.compareAtPrice !== product.price) || product.maxVariantPrice !== product.minVariantPrice}
          />
        </div>
      )}
    </div>
  )
}

export default CollapseProductPreview
