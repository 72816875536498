import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { scheduleScreenMessages } from '../messages';
import { useSchdeuleProvider } from '../../../components/SchdeuleProvider';
import { AppHeaderContext } from '../../../components/AppHeaderProvider';
import { SmallCalendar } from '../../../assets/icons/Small_calendar';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { ScheduleClientMessageForm } from '../components/forms/schedule-client-message-form/ScheduleClientMessageForm';
import { SelectedHostInfoRow } from '../components/selected-host-info-row/SelectedHostInfoRow';
import { useClientConfigProvider } from '../../../components/ClientConfigProvider';
import { LeaveMessageEvent } from '../components/leave-message-event/LeaveMessageEvent';
import { formatDateString, formatTimezone } from '../../../utils/formatDateString';
import { useSchdeuleConfigProvider } from '../../../components/ScheduleConfigProvider';
import { useLocalizationSettingsProvider } from '../../../components/LocalizationSettingsProvider';
import { useClientAuthProvider } from '../../../components/ClientAuthProvider';
import { REQ_HEADER_TYPES, useInterWindowMessageProvider } from '../../../components/InterWindowMessageProvider';
import { useForm } from '../../../components/boutiq-form/hooks/useForm';
import { PageFooter } from '../../../components/page-footer/PageFooter';
import { PageHeader } from '../../../components/page-header/PageHeader';
import { PageScrollContainer } from '../../../components/page-scroll-container/PageScrollContainer';
import { EventTypeDurationRow } from '../components/eventTypeDurationRow/EventTypeDurationRow';
import './ScheduleForm.scss';
import {PAGES} from "../../../constants/routes";
import { useGA4Analytics } from '../../../hooks/useGA4Analytics';

export const ScheduleForm = ({navigateToNextScheduleScreen}) => {
    const intl = useIntl();
    const history = useHistory();
    const { path } = useRouteMatch();
    const { postMessage } = useInterWindowMessageProvider();
    const { customerLocale } = useLocalizationSettingsProvider();
    const { hostSelectionConfig, isScheduleLink, timezone } = useClientConfigProvider();
    const [isScheduling, setIsScheduling] = useState(null);
    const [isPrimaryButtonHidden, setIsPrimaryButtonHidden] = useState(true);
    const [scrollTarget, setScrollTarget] = useState(null);
    const {sendAnalyticsCallEvent} = useGA4Analytics()

    const {
        rescheduleCall,
        scheduleCall,
        scheduleId,
        scheduleItemData,
        schedulingFormSchema,
        schedulingFormSchemaLoading,
    } = useSchdeuleProvider();

    const scheduleCallRequest = () => {
        setIsScheduling(true);
        setScheduleCallRequested(true);
    }

    const {
        name,
        email,
        hostIdForScheduling,
        selectedSlot,
        setScheduleData,
        selectedEventType,
        clientFormData,
        setClientFormData,
        setSchedulingError,
    } = useSchdeuleConfigProvider()

    const { contextId } = useClientAuthProvider();
    const [scheduleCallRequested, setScheduleCallRequested] = useState(false);

    const onSubmitMessageForm = () => {
        if (!selectedSlot) return;
        scheduleCallRequest()
    }

    const showErrorFieldCallback = (errorFieldRef) => {
        setScrollTarget(errorFieldRef.offsetTop - 20);
    }

    const {
        handleChange,
        validate,
        values,
        errors,
        omitError,
        handleSubmit,
        refProvider,
    } = useForm(
        onSubmitMessageForm,
        showErrorFieldCallback,
        true,
        clientFormData,
        schedulingFormSchema,
        scheduleItemData?.clientForm,
    );

    useEffect(() => {
        if (isScheduleLink) {
            setIsPrimaryButtonHidden(false)
        }
    }, [isScheduleLink])

    // if scheduling is requested, don't send to server unless context (and auth) state are stable
    useEffect(() => {
        if (scheduleCallRequested && !!contextId) {
            scheduleCallAction();
        }
    }, [scheduleCallRequested, contextId])

    const valueMapper = (schemaItem, id, value) => {
        let label = '';
        if (schemaItem.label) {
            label = schemaItem.label;
        } else if (schemaItem.multiLanguageLabel) {
            label = intl.formatMessage(schemaItem.multiLanguageLabel)
        }
        if (typeof value === 'string' || typeof value === 'boolean') {
            return { id, label, value }
        } else if (typeof value === 'object' &&
            !Array.isArray(value)) {
            return { id, label, value: value.value ? value.value : value.selected }
        } else {
            const checkedValues = value.filter(val => val.selected).map(selectedItem => {
                return selectedItem.value;
            })
            return { id, label, value: checkedValues }
        }
    }
    const formValuesConverter = (formValues) => {
        const filteredFormValues = Object.keys(formValues)
            .filter((k) => formValues[k])
            .reduce((a, k) => ({ ...a, [k]: formValues[k] }), {});

        if (!schedulingFormSchema) {
            return null;
        }

        return {
            schemaVersion: schedulingFormSchema.version,
            data: schedulingFormSchema.fields.reduce((acc, field) => {
                let valueObj;
                if (filteredFormValues[field.id]) {
                    valueObj = valueMapper(field, field.id, filteredFormValues[field.id])
                } else if (field.type !== 'title') {
                    let label = '';
                    if (field.label) {
                        label = field.label;
                    } else if (field.multiLanguageLabel) {
                        label = intl.formatMessage(field.multiLanguageLabel)
                    }
                    valueObj = {id: field.id, label, value: null }
                }
                if (valueObj) {
                    return [...acc, valueObj]
                }
                return acc;
            }, []),
        }

    }

    const scheduleCallAction = useCallback(() => {
        if (!selectedSlot) return
        let scheduleData = {
            slot: {
                start: selectedSlot.timeSlot,
                end: selectedSlot.timeSlotEnd,
            },
            customerName: name,
            customerEmail: email,
            clientForm: formValuesConverter(values),
            customerTimezone: timezone || null,
            hostId: hostIdForScheduling,
            locale: customerLocale,
        }
        const schedule = isScheduleLink ? rescheduleCall : scheduleCall;

        if (selectedEventType) {
            scheduleData.eventTypeId = selectedEventType;
        }
        setSchedulingError(null);
        schedule(scheduleData, scheduleId)
            .then((data) => {

                setScheduleData(data);
                sendAnalyticsCallEvent('call_scheduled');
                postMessage(REQ_HEADER_TYPES.SCHEDULING_SUCCESS);
                setIsScheduling(false);
                navigateToNextScheduleScreen(path, {isSuccess: true});
            })
            .catch(scheduleError => {
                console.error(scheduleError);
                setIsScheduling(false);
                setScheduleCallRequested(false);
                setSchedulingError(scheduleError);
                navigateToNextScheduleScreen(path, {isError: true});
            });
    }, [scheduleCall, name, email, values, customerLocale, selectedSlot, timezone])

    const { backFuncRef } = useContext(AppHeaderContext);

    //NEED TO THINK HOW TO UPDATE STATE
    useEffect(() => {
        setClientFormData(values);
    }, [values])

    useEffect(() => {
        backFuncRef(() => {
            history.goBack()
        })
    }, [])

    return (
        <>
            <PageHeader
                title={
                    <div className='schedule-message-title-container'>
                        <p className='event-message'>
                            <FormattedMessage
                                {...scheduleScreenMessages.schedule_message_header}
                                values={{ service_name: intl.formatMessage({ ...scheduleScreenMessages.service_noun }) }} />
                        </p>
                        <div className='schedule-message-main-title-container'>
                            <SmallCalendar />
                            <p className='scheduled-date-time-label'>
                                {formatDateString(
                                    intl.formatMessage(scheduleScreenMessages.nouns_today),
                                    intl.formatMessage(scheduleScreenMessages.nouns_tomorrow),
                                    selectedSlot.timeSlot)}
                                <span className='scheduled-date-timezone'>
                                    {formatTimezone(timezone)}
                                </span>
                            </p>
                        </div>
                        <div className='schedule-addition-data-rows'>
                            <EventTypeDurationRow />
                            {hostSelectionConfig.enabled && <SelectedHostInfoRow isChangeAvailable={false}/>}
                        </div>
                    </div>}
            />
            {/* // TODO: Maxim need to improve loading state of form. */}
            {!schedulingFormSchemaLoading && schedulingFormSchema &&
                <PageScrollContainer resetTarget={() => setScrollTarget(null)} scrollTargetElement={scrollTarget} children={
                    <ScheduleClientMessageForm
                        refProvider={refProvider}
                        schema={schedulingFormSchema}
                        handleChange={handleChange}
                        validateOnBlur={validate}
                        values={values}
                        errors={errors}
                        omitError={omitError}
                        isPrimaryButtonHidden={isPrimaryButtonHidden}
                        setIsPrimaryButtonHidden={setIsPrimaryButtonHidden} />
                }>
                </PageScrollContainer>}
            <PageFooter
                onSubmitPrimary={(e) => handleSubmit(e, schedulingFormSchema)}
                isLoading={isScheduling}
                isHidden={isPrimaryButtonHidden || schedulingFormSchemaLoading}
                primaryButtonLabel={<FormattedMessage {...scheduleScreenMessages.cta_confirm_schedule} />}
                secondaryButtonLabel={<LeaveMessageEvent />}
                onSubmitSecondary={() => history.push({
                    pathname: PAGES.CONTACT,
                    isBackAllowed: true,
                    preventSchedule: true
                })}
            />
        </>
    )
}
