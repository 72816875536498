import React from 'react';
import LoadingCard from '../loading-card/LoadingCard';
import { useClientConfigProvider } from '../../ClientConfigProvider';

const ScheduleSkeleton = () => {
  const { dynamicConfig } = useClientConfigProvider();
  const customLogo = dynamicConfig && dynamicConfig.app_custom_logo;

  return (
    <>
      {customLogo && (
        <LoadingCard
          height={50}
          width={65}
          borderRadius={4}
          marginBottom={40}
          marginTop={80}
        />
      )}
      <LoadingCard
        height={20}
        width={'80%'}
        borderRadius={4}
        marginTop={customLogo ? 10 : 80}
        marginBottom={4}
      />
      <LoadingCard
        height={20}
        width={'80%'}
        borderRadius={4}
      />
      <LoadingCard
        height={154}
        width={130}
        borderRadius={4}
        marginTop={30}
      />
      <LoadingCard
        height={50}
        width={50}
        borderRadius={50}
        marginTop={-35}
        marginRight={-115}
      />
      <LoadingCard
        height={20}
        width={'80%'}
        borderRadius={4}
        marginBottom={4}
      />
      <LoadingCard
        height={20}
        width={'80%'}
        borderRadius={4}
        marginBottom={customLogo ? 20 : 100}
      />
      <div style={{display: 'flex', flexFlow: 'row', width: '100%'}}>
        <LoadingCard
          height={50}
          width={'100%'}
          borderRadius={4}
          marginLeft={'10%'}
        />
        <LoadingCard
          height={50}
          width={'100%'}
          borderRadius={4}
          marginRight={'10%'}
        />
      </div>
      <LoadingCard
        height={20}
        width={'30%'}
        borderRadius={4}
        marginTop={4}
      />
    </>
  )
}

export default ScheduleSkeleton;
