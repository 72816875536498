import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowLeft } from '../../../../assets/icons/ArrowLeft';
import { PAGES } from '../../../../constants/routes';
import { SCHEDULING_PAGES } from '../../../../pages/scheduling/schedule-screen';
import { useAppHeaderProvider } from '../../../AppHeaderProvider';
import { VIDEO_CALL_PAGES } from '../../../VideoCall';
import './BackBtn.scss';

export const BackBtn = () => {
    const history = useHistory();
    const { backFuncRef, backFunc } = useAppHeaderProvider();
    const [isBackButtonAvailable, setIsBackButtonAvailable] = useState(true);
    const [contrast, setContrast] = useState(false);

    const goBack = () => {
        if (backFunc) {
            backFunc();
            backFuncRef(null);
        } else {
            history.goBack();
        }
    }

    useEffect(() => {
        setContrast(ContrastBtnRoutes(history.location.pathname));

        switch (history.location.pathname) {
            case `${PAGES.INTRO}`:
                setIsBackButtonAvailable(false);
                break;
            case `${PAGES.INTRO}/intro-welcome`:
                setIsBackButtonAvailable(!!backFunc);
                break;
            case `${PAGES.VIDEO_CALL}`:
                setIsBackButtonAvailable(!!backFunc);
                break;
            case `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.THANK_YOU}`:
                setIsBackButtonAvailable(false);
                break;
            case `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.WAITING_ROOM}`:
                setIsBackButtonAvailable(false);
                break;
            case `${PAGES.SCHEDULING}/${SCHEDULING_PAGES.SUCCESS}`:
                setIsBackButtonAvailable(false);
                break;
            case `${PAGES.SCHEDULING}/${SCHEDULING_PAGES.RESCHEDULE}`:
                setIsBackButtonAvailable(false);
                break;
            case `${PAGES.SCHEDULING}/${SCHEDULING_PAGES.ERROR}`:
                setIsBackButtonAvailable(false);
                break;
            case `${PAGES.ERROR}`:
                setIsBackButtonAvailable(!!backFunc);
                break;
            case `${PAGES.CONTACT}`:
                setIsBackButtonAvailable(!!backFunc);
                break;
            default:
                setIsBackButtonAvailable(true);
                break;
        }
     
    }, [history.location.pathname, backFunc])

    const ContrastBtnRoutes = (currentLocation) => {
        return [
            `${PAGES.VIDEO_CALL}`,
            `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.VIDEO_LOBBY}`,
            `${PAGES.VIDEO_CALL}/${VIDEO_CALL_PAGES.WAITING_ROOM}`
        ].includes(currentLocation);
    }

    return (
        <button
            onClick={() => goBack()}
            disabled={!isBackButtonAvailable}
            className={`boutiq-back-button${contrast ? ' circle' : ''}`}
            aria-label='back'
        >
            <ArrowLeft />
        </button>
    )
}
