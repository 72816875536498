import React, { createContext, useContext, useCallback, useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import {
    useSessionCart,
    useSessionCartActions,
    useSessionShowroom,
    useSessionShowroomActions,
    useSessionCartItem,
    useSessionDataClientView,
} from '../hooks';
import { sidebarMessages } from '../pages/video-screen/intl/sidebar-messages/messages';
import { parseIdString } from '../utils';
import { generatVariantTitle, getImagePerSelectedVariant } from '../utils/shopify';
import { restoreVideoViews } from '../utils/ui';
import { useCallContextProvider } from './CallContextProvider';
import { useProductViewProvider } from './ProductViewProvider';
import { useGA4Analytics } from '../hooks/useGA4Analytics';

export const ProductDetailsContext = createContext(null);

export default function ProductDetailsProvider({
    closeSidebar = () => {},
    isScrollEnabled,
    enableScroll = () => {},
    isFullScreenEnabled,
    isMobile = true,
    setVideoChatOpen = () => {},
    children }) {

    const intl = useIntl();
    const productDetailsRef = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openModalView, setOpenModalView] = useState(false);
    const [availableOptions, setAvailableOptions] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [emptyOptions, setEmptyOptions] = useState([]);

    const [cleanProductId, setCleanProductId] = useState(null);
    const [variantNotFound, setVariantNotFound] = useState(false);
    const [variantNotInIventory, setVariantNotInInventory] = useState(null);
    const { callId, activeCartId } = useCallContextProvider();
    const { cartSnapshots, cartSnapshotsLength, cartSnapshotLoading } = useSessionCart(callId, activeCartId);
    const { addToCart, updateCartProduct, removeCartProduct } = useSessionCartActions(callId, activeCartId);
    const { sessionSnapshots, sessionSnapshotsLength, sessionSnapshotLoading } = useSessionShowroom(callId);
    const { addToSession } = useSessionShowroomActions(callId); // ??????

    const [currentImages, setCurrentImages] = useState([]);
    const [selectedProductVariant, setSelectedProductVariant] = useState(null);
    const [isShowFullDetails, showFullDetails] = useState(false);
    const [isShowMobileDescription, showMobileDescription] = useState(false);

    const { productViewData: { product, cartKey: viewedCartKey, cartData: viewedCartData, showroomKey: viewedShowroomKey, presentedImage, presentedProductId,
        presentedVariantId, presentedVariantOptions }, isProductLoading, clearSelectedProduct, isPresentationEnabled } = useProductViewProvider();
    const [cartItemKey, setCartItemKey] = useState(viewedCartKey);
    const [showroomItemKey, setShowroomProductKey] = useState(viewedShowroomKey);
    const cartItem = useSessionCartItem(callId, activeCartId, cartItemKey);
    const { updateClientProductView } = useSessionDataClientView();
    const imagesRefs = useRef([]);
    const {sendAnalyticsEcommEvent} = useGA4Analytics()

    useEffect(() => {
        if (isFullScreenEnabled) {
            hideFullDetails();
        }
    }, [isFullScreenEnabled])

    const returnToSearch = () => {
        clearSelectedProduct();
    }

    const updateSelectedOptions = (optionName, optionValue) => {
        setSelectedOptions((existingOptions) => {
            const existingOptionsCopy = { ...existingOptions };
            existingOptionsCopy[optionName] = optionValue;
            return existingOptionsCopy;
        });
    };

    const buildProductVariantOptions = (productForOptions) => {

        if (!productForOptions.options) {
            return null;
        }

        let dropDownOptions = productForOptions.options.map(option => {
            const availableValues = option.values.map(value => ({
                label: value,
                value,
                category: option.name,
            }));
            return {
                name: option.name,
                placeholder: intl.formatMessage(sidebarMessages.product_variant_select, { product_option: option.name.toLowerCase() }),
                selectValues: availableValues,
            }
        });
        return dropDownOptions;
    }

    const findVariantsForSelecetedOptions = () => {
        return product.variants.find(variant =>
            variant.selectedOptions.every(productOption =>
                selectedOptions[productOption.name] && selectedOptions[productOption.name].value === productOption.value
            )
        )
    }

    // when product/cartdata changes, set the aviable options and their default values
    useEffect(() => {
        if (product) {
            setCleanProductId(parseIdString(product.productId));
            if (product.images && product.images.length > 0) {
                setCurrentImages(product.images);
                setSelectedImage(product.images[0]);
            }

            const productOptions = buildProductVariantOptions(product);
            setAvailableOptions(productOptions);
            if (!productOptions) return;

            // if specific option has only 1 value then set it as the default selected value,
            for (const option of productOptions) {
                if (option.selectValues.length === 1) {
                    updateSelectedOptions(option.name, option.selectValues[0]);
                }
            }

            // if product is in cart configure the selected options to default to cart variants values
            if (viewedCartKey) {
                var variantSelectedOptions = {};
                const cartVariant = product.variants.find(variant => parseIdString(variant.id) === viewedCartData.variantId);

                for (const option of productOptions) {
                    const variantSelectedOption = cartVariant.selectedOptions.find(selectedOption => selectedOption.name === option.name);
                    variantSelectedOptions[variantSelectedOption.name] = {
                        label: variantSelectedOption.value,
                        value: variantSelectedOption.value,
                    }
                }
                setCartItemKey(viewedCartKey);
                setSelectedOptions(variantSelectedOptions);
            } else if (presentedVariantId && presentedVariantOptions && Object.keys(presentedVariantOptions).length > 0) {
                let presentedVariantSelectedOptions = {};
                presentedVariantOptions.forEach(v => {
                    presentedVariantSelectedOptions[v.category] = {
                        label: v.value,
                        value: v.value,
                    }
                })
                setSelectedOptions(presentedVariantSelectedOptions);
            } else {
                setCartItemKey(null);
            }
        }
        return () => {
            setSelectedOptions({});
            setEmptyOptions([]);
            setAvailableOptions(null);
            setCleanProductId(null);
            setVariantNotFound(null);
            setVariantNotInInventory(null);
            setSelectedProductVariant(null);
            setSelectedImage(null);
            setCurrentImages([]);
        };
    }, [product, viewedCartKey, presentedVariantId]);


    // check if product is in showroom (might have been added by remote participant)
    useEffect(() => {
        if (!sessionSnapshotLoading) {
            const showroomItem = sessionSnapshots.find((snapshot) => snapshot.val().productId === cleanProductId);
            setShowroomProductKey(showroomItem ? showroomItem.key : null);
        }
    }, [cleanProductId, sessionSnapshotsLength, sessionSnapshotLoading]);

    // check if item was in cart but removed
    useEffect(() => {

        if (cartItemKey && !cartSnapshotLoading) {
            const existsInCart = cartSnapshots.find(snap => snap.key === cartItemKey)
            if (!existsInCart)
                setCartItemKey(null);
        }

    }, [cartItemKey, cartSnapshotsLength, cartSnapshotLoading]);

    // when option vlaues are set by user, find the relevant variant and show its specific image
    useEffect(() => {

        if (availableOptions && Object.keys(selectedOptions).length === availableOptions.length) {
            setVariantNotInInventory(null);
            const selectedVariant = findVariantsForSelecetedOptions();
            setSelectedProductVariant(selectedVariant || null);
            if (selectedVariant && selectedVariant.image) {
                const index = product.images.findIndex(item => item.id === selectedVariant.image.id);
                let item;
                let arrayImages = [...product.images];
                if (index !== -1) {
                    item = arrayImages.splice(index, 1);
                } else {
                    item = [selectedVariant.image];
                }
                setCurrentImages([...item, ...arrayImages])
                setSelectedImage(selectedVariant.image)
            } else {
                setSelectedImage(product.images[0])
                setCurrentImages([...product.images])
            }
        }
    }, [selectedOptions, availableOptions])

    // send analytics event when product is viewed
    useEffect(() => {
        if (product) {
            sendAnalyticsEcommEvent('view_item', {
                items: [
                    {
                        item_id: parseIdString(product.productId),
                        item_name: product.title,
                    },
                ],
            });
        }
    }, [product]);

    /* START - report which product client is currently viewing */

    useEffect(() => {
        return () => updateClientProductView({});
    }, [])

    useEffect(() => {
        if (isPresentationEnabled && presentedProductId) {
            if (currentImages.length > 0) {
                let image = selectedImage;
                let presentedImageIndex = -1;
                if (presentedImage !== null) {
                    presentedImageIndex = currentImages.findIndex(v => parseIdString(v.id) === presentedImage);
                    if (presentedImageIndex !== -1) {
                        image = currentImages[presentedImageIndex];
                    }
                    setSelectedImage({ ...image, index: presentedImageIndex });
                } else {
                    setSelectedImage(null);
                }
            }
            if (isMobile && !isScrollEnabled) {
                enableScroll(true);
            }
            showFullDetails(true);
        } else if (isMobile && isScrollEnabled && !isShowFullDetails) {
            enableScroll(false);
        }
    }, [isPresentationEnabled, presentedProductId, presentedImage, currentImages]);

    useEffect(() => {
        updateClientProductView({
            productId: cleanProductId,
            showroomKey: showroomItemKey,
            cartKey: viewedCartKey,
            imageId: selectedImage ? selectedImage.id : null,
        })
    }, [cleanProductId, showroomItemKey, viewedCartKey, selectedImage])

    useEffect(() => {
        if (selectedImage) {
            if (isPresentationEnabled) {
                setTimeout(() => scrollToView(), 500);
            } else {
                scrollToView();
            }
        }
    }, [selectedImage])

    const scrollToView = () => {
        const activeImageId = imagesRefs.current.findIndex(i => i.id === selectedImage.id);
        if (activeImageId !== -1 && window.innerWidth >= 1025) {
            imagesRefs.current[activeImageId].ref.scrollIntoView({
                behavior: 'smooth',
                block: "end",
                inline: "nearest"
            });
        }
    }

    /*  END */
    const hideModalView = () => {
        setOpenModalView(false);
    };

    const showModalView = () => {
        setOpenModalView(true);
    };

    const validateVariantForCart = () => {

        const notSelectedOptions = [];

        if (availableOptions) {
            for (const option of availableOptions) {
                if (!selectedOptions[option.name]) {
                    notSelectedOptions.push(option);
                }
            }
        }

        if (notSelectedOptions.length) {
            const emptyOptions = notSelectedOptions.reduce(
                (accumulatorArr, option) => {
                    return [...accumulatorArr, option.name];
                },
                []
            );
            setEmptyOptions(emptyOptions);
            if (isMobile && emptyOptions.length > 0) {
                showModalView();
            }
            return false;
        }
        setEmptyOptions([]);

        if (!selectedProductVariant) {
            setVariantNotFound(true);
            if (isMobile) {
                showModalView();
            }
            return false;
        }

        setVariantNotFound(false);
        return true;
    };

    const validateInventory = (quantity) => {

        if (quantity > selectedProductVariant.inventoryQuantity && selectedProductVariant.inventoryPolicy !== 'CONTINUE') {
            setVariantNotInInventory(true);
            if (isMobile) {
                showModalView();
            }
            return false;
        }

        setVariantNotInInventory(false);
        return true;
    };

    const addFullProductToCart = async (e) => {
        e.preventDefault();

        if (!validateVariantForCart())
            return false;

        // if exact same variant is already in cart then its an "add quantity action"
        const existingCartItem = cartSnapshots.find((snapshot) => snapshot.val().variantId === parseIdString(selectedProductVariant.id))
        if (existingCartItem) {
            if (!validateInventory(existingCartItem.val().quantity + 1))
                return false;

            updateCartProduct(existingCartItem.key, { quantity: existingCartItem.val().quantity + 1 });
            setCartItemKey(existingCartItem.key);

        } else {
            // else its as add to cart for first time action
            if (!validateInventory(1))
                return false;

            const normalizedProduct = {
                productId: cleanProductId,
                image: getImagePerSelectedVariant(product, selectedProductVariant),
                currencyCode: selectedProductVariant.currencyCode,
                currentPrice: selectedProductVariant.price,
                productTitle: product.title,
                productDescription: product.descriptionHtml,
                variantId: parseIdString(selectedProductVariant.id),
                variantTitle: generatVariantTitle(selectedProductVariant),
                inventoryPolicy: selectedProductVariant.inventoryPolicy,
                inventoryQuantity: selectedProductVariant.inventoryQuantity,
                quantity: 1,
            };
            const newCartKey = await addToCart(normalizedProduct);
            setCartItemKey(newCartKey);
        }

        return true;
    };

    const updateVariant = (e) => {
        e.preventDefault();

        if (!validateVariantForCart())
            return false;

        if (!cartItem)
            return false;

        // if exact same variant is already in cart then its an "add quantity action"
        const existingCartItem = cartSnapshots.find((snapshot) => snapshot.val().variantId === parseIdString(selectedProductVariant.id))
        if (existingCartItem && existingCartItem.key !== cartItemKey) {
            if (!validateInventory(existingCartItem.val().quantity + cartItem.quantity))
                return false;

            updateCartProduct(existingCartItem.key, { quantity: existingCartItem.val().quantity + cartItem.quantity });
            removeCartProduct(cartItemKey, cartItem);
            setCartItemKey(existingCartItem.key);
        } else {
            // else update the exsiting cart item
            if (!validateInventory(cartItem.quantity))
                return false;

            updateCartProduct(cartItemKey, {
                variantId: parseIdString(selectedProductVariant.id),
                variantTitle: generatVariantTitle(selectedProductVariant),
                currentPrice: selectedProductVariant.price,
                image: getImagePerSelectedVariant(product, selectedProductVariant),
                inventoryPolicy: selectedProductVariant.inventoryPolicy,
                inventoryQuantity: selectedProductVariant.inventoryQuantity,

            })
        }
        return true;
    };

    const handleAddToShowroom = () => { // FOR DESKTOP
        addToSession(product);
    };

    const showFullSizedImageView = () => {
        if (!selectedImage) {
            return;
        }
        if (isMobile) {
            enableScroll(true);
        }
        showFullDetails(true);
    };

    const showFullSizedMobileDescription = () => {
        showMobileDescription(true);
        showFullDetails(true);
    };

    const openVideoChat = () => {
        setVideoChatOpen(true);
    };

    const hideFullDetails = () => {
        if (isShowMobileDescription) {
            showMobileDescription(false);
        }
        if (isShowFullDetails) {
            restoreVideoViews();
            if (isMobile) {
                enableScroll(false);
            }
            showFullDetails(false);
        }
    };

    return <ProductDetailsContext.Provider value={{
        selectedProductVariant,
        selectedImage,
        setSelectedImage,
        currentImages,
        hideFullDetails,
        showFullSizedImageView,
        isShowFullDetails,
        availableOptions,
        addFullProductToCart,
        handleAddToShowroom,  // FOR DESKTOP
        updateVariant,
        cartItem,
        showroomItemKey,
        selectedOptions,
        updateSelectedOptions,
        cartItemKey,
        hideModalView,
        openModalView,
        emptyOptions,
        variantNotFound,
        variantNotInIventory,
    }}>
        {children}
    </ProductDetailsContext.Provider>
}

export function useProductDetailsProvider() {
    const context = useContext(ProductDetailsContext);
    if (!context) {
        throw new Error('useProductDetailsProvider must be used within the scope of ProductDetailsContext');
    }
    return context;
}
