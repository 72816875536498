import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useCustomerDetails } from '../../components/CustomerDetailsProvider';
import WelcomeForm from './components/welcome-form';
import { useClientAuthProvider } from '../../components/ClientAuthProvider';
import { useCallContextProvider } from '../../components/CallContextProvider';
import { useClientConfigProvider } from '../../components/ClientConfigProvider';
import { useLocalizationSettingsProvider } from '../../components/LocalizationSettingsProvider';
import { AppHeaderContext } from '../../components/AppHeaderProvider';
import { isHistoryRoot } from '../../utils/history';
import './video-lobby.scss';
import {PAGES} from "../../constants/routes";

const VideoLobby = ({
  isButtonDisabled,
}) => {
  const [isLoading, toggleLoading] = useState(false);
  const [videoCallRequested, setVideoCallRequested] = useState(false);
  const { authLoading, scheduleId } = useClientAuthProvider();
  const { requestVideoCall } = useCallContextProvider();
  const {
    name,
    email,
    recordingConsent,
    setCustomerName,
    setCustomerEmail,
  } = useCustomerDetails();
  const { disableScheduling } = useClientConfigProvider();
  const { customerLocale } = useLocalizationSettingsProvider();
  const history = useHistory();
  const { backFuncRef } = useContext(AppHeaderContext);
  const startVideoCall = useCallback(async () => {
    requestVideoCall(name, email, customerLocale, recordingConsent);
  }, [name, email, customerLocale, requestVideoCall, recordingConsent]);
  // if call is requested, don't send to server unless context (and auth) state are stable
  useEffect(() => {
    if (videoCallRequested && !authLoading) {
      startVideoCall();
    }
  }, [videoCallRequested, authLoading])

  useEffect(() => {
    if (!isHistoryRoot(history)) {
        backFuncRef(() => history.goBack());
      }
  }, [])

  const startVideoCallRequested = (formData) => {
    toggleLoading(true);
    setVideoCallRequested(true);
  }

  const startScheduling = (formData) => {
    history.push({pathname: PAGES.SCHEDULING, preventForm: true});
  };

  return (
    <div className='wtr-container'>
      <WelcomeForm
        isLoading={isLoading}
        onStartCall={startVideoCallRequested}
        onStartSchedule={!authLoading && !scheduleId && !disableScheduling && startScheduling}
        isButtonDisabled={isButtonDisabled}
      />
    </div>
  );
};

export default VideoLobby;
