import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  name: {
    id: 'nouns_name'
  },
  name_error: {
    id: 'messages_name_error'
  },
  email: {
    id: 'nouns.email'
  },
  email_error: {
    id: 'messages.email_error'
  },
  email_invalid: {
    id: 'messages.email_invalid'
  },
  terms_error: {
    id: 'messages_terms_error'
  },
  optional: {
    id: 'adverb_optional'
  },
  required: {
    id: 'adverb_required'
  },
  powered_by_caazam: {
    id: 'messages.powered_by_caazam',
  },
  consent_recording: {
    id: 'messages_rec_consent'
  },
  consent_error: {
    id: 'messages_rec_consent_error'
  }
});
