import React from "react"

export const CalendarCheckmark = ({color}) =>
<svg width="46px" height="46px" viewBox="0 0 46 46" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <g id="client-flow---Desktop" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="date-confirmed-(scheduled-call)" transform="translate(-779.000000, -472.000000)" stroke={color} strokeWidth="3">
            <g id="Group-5-Copy-2" transform="translate(480.000000, 160.000000)">
                <g id="Big-calendar" transform="translate(161.000000, 182.000000)">
                    <g id="Group-4" transform="translate(138.000000, 130.000000)">
                        <circle id="Oval" fill="#FFFFFF" cx="23" cy="23" r="21.5"></circle>
                        <polyline id="Path-2" strokeLinecap="round" strokeLinejoin="round" points="12 23.6373053 20.2527012 31 35 17"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
