import React, { useRef, useEffect } from "react";
import './ErrorContainer.scss';

export const ErrorContainer = ({ required, error, fieldName }) => {
    const errorContainer = useRef();

    useEffect(()=>{
        if (required && error) {
            errorContainer.current.setAttribute('tabindex', '-1');
            errorContainer.current.focus();
        }

    },[required,error])
    
    return (
        required ? <div className='text-field-error-container' id={fieldName} aria-live="assertive" ref={errorContainer}>
            <p className={`text-field-error-message${error ? ' active' : ''}`}>
                {error}
            </p>
        </div> : null
    )
}
