import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import EventEmitter from 'events';

import CallSignaling, { CallStatus } from './CallSignaling';
import { useClientAuthProvider } from './ClientAuthProvider';
import { useClientConfigProvider  } from './ClientConfigProvider';

import {
  useCallContextAPI,
} from '../hooks';

import { appLogger, logger } from '../logging';
import { setPreferredHost } from "../actions/currentSession";
import { useSessionCartManager} from "../hooks/useSessionCartManager";

export const CallContext = createContext(null);

export default function CallContextProvider({ shopId, contextId, onRequest, onRinging, onConnect, onError, children }) {

  const dispatch = useDispatch();
  const { user } = useClientAuthProvider();
  const { featureSupport } = useClientConfigProvider();

  const [callId, setCallId] = useState(null);
  const [callData, setCallData] = useState(null);
  const callEvents = useRef(new EventEmitter());
  const { contextCallRequest, contextCallRequestHost, contextCallConnect, contextCancel } = useCallContextAPI(contextId);

  const { activeCartId, preferredHost, disableCartSync, shopifyLocaleRoot } = useSelector(state => state.currentSession);
  useSessionCartManager(shopId, contextId, callId, callData, callEvents.current );
  const [sig, setSig] = useState(null);

  useEffect(() => {
    return () => {
      setCallId(null);
      appLogger.setCallContext(null);
      setCallData(null);
    }
  }, [contextId]);

  useEffect(() => {
    if (sig) {
      sig.on('call_error', (error) => {
        sig.disconnect();
        onError && onError(error);
      });

      sig.on('call_status', data => {
        setCallData(data);
        switch (data.status) {
          case CallStatus.setup:
            contextCallRequestHost(sig.callId, { preferredHost, waitForConnection: false })
              .catch(error => {
                onError && onError(error);
              });
            break;
          case CallStatus.ringing:
            onRinging && onRinging();
            break;
          case CallStatus.connecting:
            break;
          case CallStatus.connected:
            dispatch(setPreferredHost(data.host.id));
            contextCallConnect(sig.callId)
              .then(({ url, token }) => {
                callEvents.current.emit('connected', sig.callId);
                onConnect && onConnect(url, token);
              })
              .catch(error => {
                onError && onError(error);
              });
            break;
        }
      });

      sig.connect();
      return () => {
        sig.removeAllListeners();
        sig.disconnect();
      }
    }
  }, [sig]);

  const requestVideoCall = useCallback(async (name, email, customerLocale, recordingConsent) => {
    onRequest && onRequest();
    logger.info('requesting new call', { customerLocale, featureSupport });
    try {
      const { callId: newCallId, callData } = await contextCallRequest(name, email, customerLocale, recordingConsent);
      setCallId(newCallId);
      appLogger.setCallContext(newCallId);
      setCallData(callData);
      setSig(new CallSignaling(newCallId, user));
    } catch (requestError) {
      onError && onError(requestError);
    }
  }, [contextCallRequest, onRequest]);

  
  return <CallContext.Provider value={{
    shopId,
    contextId,
    callId,
    callData,
    requestVideoCall,
    contextCancel,
    disableCartSync,
    activeCartId,
    shopifyLocaleRoot,
    callEvents: callEvents.current,
  }}>
    {children}
  </CallContext.Provider>
}

export function useCallContextProvider() {
  const context = useContext(CallContext);
  if (!context) {
    throw new Error('useCallContextProvider must be used within the CallContextProvider');
  }
  return context;
}
