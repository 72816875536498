import React from 'react';
import './styles.scss';
import ProductCurrentPrice from '../../../pages/video-screen/shared/ProductCurrentPrice/productCurrentPrice';

const StoryPreviewProductDescription = ({product}) => {
  return (
    <div className={`product-preview-description-container`}>
      <div className={`product-preview-description-wrapper`}>
        <p>{product.title}</p>
        <ProductCurrentPrice product={product} />
      </div>
    </div>
  )
}

export default StoryPreviewProductDescription;
