import React, { Fragment, useState } from 'react';
import { useIntl } from 'react-intl';
import { boutiqFormMessages } from '../../../messages';
import { BoutiqTextField } from '../../boutiq-text-field/BoutiqTextField';
import { ErrorContainer } from '../../error-container/ErrorContainer';
import '../styles/BoutiqChoice.scss';

export const BoutiqRadio = ({ field, refProvider, handleChange, values, errors, omitError, validateOnBlur }) => {
    const intl = useIntl();

    const [inputValue, setInputValue] = useState('');
    const handleChangeInput = (e, id, fieldId, val) => {
        e.persist();
        setInputValue(val);
        handleChange(e, field.id, { ...values, value: { optionValue: id, value: val } });
        omitError(fieldId);
    }

    const handleChangeRadio = (e, id, value) => {
        e.persist();
        const targetOption = field.options.find(item => item.value === value.selected);
        if (targetOption.innerComponents) {
            value = { ...value, value: { value: inputValue, optionValue: id } }
            handleChange(e, id, value);
        }
        else {
            handleChange(e, id, value)
            const nonUsedFields = field.options.filter(item => item.innerComponent);
            nonUsedFields.map(item => omitError(item.innerComponent.id));
        }
        omitError(field.id);
    }

    return (
        <div ref={refProvider(field.id)} className='boutiq-radio-button-group'>
            {field.label && <div className='boutiq-radio-button-group-label'>{`${field.label}${field.required ? `${intl.formatMessage(boutiqFormMessages.required)}` : ''}`}</div>}
            {field.options.map((option, index) =>
                <Fragment key={option.value}>
                    <label className={`radio-option-label${option.innerComponent ? ' group' : ''}`}>
                        <input
                            type="radio"
                            onBlur={(e) => { validateOnBlur(e) }}
                            name={field.id}
                            value={option.value}
                            checked={values && values.selected === option.value}
                            onChange={(e) => handleChangeRadio(e, field.id, { selected: option.value })}
                            aria-label={field.label}
                        />
                        {option.innerComponent ?
                            <BoutiqTextField
                                focus={values && values.selected === option.value}
                                key={`${option.value}-${index}`}
                                type={option.innerComponent.type}
                                validationType={option.innerComponent.type}
                                disabled={values && values.selected !== option.value}
                                fieldName={option.innerComponent.id}
                                value={values && values.value && values.value.value || inputValue}
                                placeholder={option.innerComponent.placeholder}
                                required={true}
                                handleChange={(e, _, val) => { handleChangeInput(e, option.value, option.innerComponent.id, val) }}
                                errorHandler={true}
                                onSubmit={validateOnBlur}
                                error={errors && errors[option.innerComponent.id]} />
                            :
                            <span className='option-value'>{option.label}</span>}
                    </label>
                </Fragment>
            )}
            <div className='field-group-error-message'>
                <ErrorContainer required={field.required} error={errors[field.id]} />
            </div>
        </div>
    )
}
