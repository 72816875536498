import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AVsettings } from '../../../../assets/icons/AVsettings';
import { useAppHeaderProvider } from '../../../AppHeaderProvider';
import './AVSettings.scss';

export const AVSettings = ({disabled}) => {
    const { AVSettingVisible, setAVSettingVisible } = useAppHeaderProvider();
    const history = useHistory();

    return (
        <div className={`boutiq-audio-video-settings${!disabled ? '' : ' disabled'}`}>
            <button onClick={() => setAVSettingVisible(!AVSettingVisible)} className='boutiq-audio-video-button' aria-label='audio video settings'>
                <AVsettings />
            </button>
        </div>)
}
