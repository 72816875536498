import { configureStore} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import rootReducer from '../reducers';

const middleware = process.env.NODE_ENV !== 'production' ?
  [logger] : [];

const store = configureStore({
  reducer: rootReducer,
  middleware,
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('../reducers', () => {
    const newRootReducer = require('../reducers').default;
    store.replaceReducer(newRootReducer);
  });
}

export default store;
