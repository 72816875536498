import React, { useState, useCallback, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { isEmail, isEmptyString } from '../../utils';
import { useCustomerDetails } from '../CustomerDetailsProvider';
import { StyledInput } from '../inputs/inputs';
import { formMessages } from './messages';
import { FilledButton } from '../boutiq-buttons/BoutiqButton';
import './index.scss';
import { useClientAuthProvider } from '../ClientAuthProvider';
import ClientNameFormSkeleton from '../skeletons/client-name-form-skeleton/ClientNameFormSkeleton';

//TODO
const ClientNameForm = ({
    title,
    subTitle,
    primaryButton,
    onClickPrimaryButton,
    onClickSecondaryButton,
    secondaryButton,
    isSecondaryButtonValidation,
    isButtonDisabled,
    isPrimaryButtonValidation = true,
    tertiaryButton = null,
    onClickTertiaryButton = null,
    emailFormConfig = { hidden: false, required: false },
    termsConfig,
    recordingConsentRequired,
    forceRecConsent,
    defaultRecConsentOff
}) => {
    const intl = useIntl();
    const { sessionData } = useClientAuthProvider();
    const {
        name,
        email,
        recordingConsent,
        toggleRecordingConsent,
        setCustomerName,
        setCustomerEmail,
    } = useCustomerDetails();
    const [formData, setFormData] = useState({
        name: name || '',
        email: email || '',
    });
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [termsError, setTermsError] = useState(null);
    const [recordingError, setRecordingError] = useState(null);
    const [isTermsSubmitted, setIsTermsSubmitted] = useState(false);


    useEffect(()=>{
        // always make sure the checkbox is set to checked when entering this screen - unless default is "off"
        if (!recordingConsent && recordingConsentRequired && !defaultRecConsentOff) {
            toggleRecordingConsent();
        }

    },[recordingConsentRequired])

    const setNameValue = (e) => {
        const newFormData = { ...formData, name: e.target.value };
        setFormData(newFormData);
        setNameError(null);
    };

    const setEmailValue = (e) => {
        const newFormData = { ...formData, email: e.target.value };
        setFormData(newFormData);
        setEmailError(null);
    };

    const validateForm = (withEmail = false, validateTerms = false, validateRecording = false) => {
        if (isEmptyString(formData.name)) {
            setNameError(formMessages.name_error);
            return false;
        } else if (withEmail & !formData.email) {
            setEmailError(formMessages.email_error);
            return false;
        } else if (formData.email && !isEmail(formData.email)) {
            setEmailError(formMessages.email_invalid);
            return false;
        } else if (validateTerms || validateRecording) {
            let isValid = true
            if (validateTerms && termsConfig && !isTermsSubmitted) {
                setTermsError(formMessages.terms_error);
                isValid = false;
            } else if (validateRecording && recordingConsentRequired && !recordingConsent) {
                setTermsError(formMessages.consent_error);
                isValid = false;
            }
            return isValid
        }

        setNameError(null);
        setEmailError(null);
        setTermsError(null);
        setRecordingError(null)
        return true;
    };

    const setCustomerData = (data) => {
        setCustomerName(data.name);
        setCustomerEmail(data.email);
    }

    const onSubmit = () => {
        const isFormValid = validateForm(isPrimaryButtonValidation, true, forceRecConsent);
        if (isFormValid) {
            setCustomerData(formData);
            onClickPrimaryButton(formData);
        }
    };

    const onSecondarySubmit = () => {
        if (isSecondaryButtonValidation) {
            const isFormValid = validateForm(true);
            if (isFormValid) {
                setCustomerData(formData);
                onClickSecondaryButton(formData)
            }
        } else {
            onClickSecondaryButton(formData)
        }
    };

    const handleChange = (e, openLink) => {
        if (openLink) {
            e.stopPropagation();
            return;
        }
        setIsTermsSubmitted(prev => !prev)
    }

    const handleRecordingsChange = (e, openLink) => {
        if (openLink) {
            e.stopPropagation();
            return;
        }
        toggleRecordingConsent()
    }

    const renderForm = () => (
      <>
          {subTitle && <p>
              {subTitle}
          </p>}
          <StyledInput
            placeholder={intl.formatMessage(formMessages.name) + intl.formatMessage(formMessages.required)}
            onChange={setNameValue}
            name='name'
            value={formData.name}
          />
          {!emailFormConfig.hidden && <StyledInput
            placeholder={intl.formatMessage(formMessages.email) + `${!emailFormConfig.required ? `` : intl.formatMessage(formMessages.required)}`}
            value={formData.email}
            onChange={setEmailValue}
            name='email'
            type='email'
          />}
          {termsConfig &&
            <div className='boutiq-checkbox-button-group'>
                <label className='checkbox-option-label'>
                    <input
                      type="checkbox"
                      checked={isTermsSubmitted}
                      onChange={() => handleChange()}
                    />
                    <span onClick={(e) => handleChange(e, true)} className='option-value' dangerouslySetInnerHTML={{ __html: termsConfig?.label }}></span>
                </label>
            </div>}
          {recordingConsentRequired && (
            <div className='boutiq-checkbox-button-group'>
                <label className='checkbox-option-label'>
                    <input
                      type="checkbox"
                      checked={recordingConsent}
                      onChange={handleRecordingsChange}
                    />
                    <span
                      onClick={(e) => handleRecordingsChange(e, true)}
                      className='option-value'
                    >
                              <FormattedMessage
                                {...formMessages.consent_recording}
                              />
                          </span>
                </label>
            </div>
          )}
          <div className='actions-container'>
              {primaryButton && <FilledButton
                id='ready-mockup-button'
                onClick={onSubmit}
                className='wtr-connect-btn'
                disabled={isButtonDisabled}>
                  {primaryButton}
              </FilledButton>}
              {secondaryButton && <span className='secondary-button' onClick={onSecondarySubmit}>
                        {secondaryButton}
                    </span>}
          </div>
      </>
    )

    return (
        <div className={`form-container${emailFormConfig.hidden ? ' name-field-only' : ''}${termsConfig?.hidden ? '' : ' terms'}`}>
            <form autoComplete='off'>
                {title && <h3 className='form-header-title'>
                    {title}
                </h3>}
                {sessionData ? renderForm() : <ClientNameFormSkeleton />}
            </form>
            {nameError ? (
                <p className='error-message'>
                    <FormattedMessage {...nameError} />
                </p>
            ) : emailError ? (
                <p className='error-message'>
                    <FormattedMessage {...emailError} />
                </p>
            ) : termsError ? (
                <p className='error-message'>
                    <FormattedMessage {...termsError} />
                </p>
            ) : recordingError ? (
              <p className='error-message'>
                  <FormattedMessage {...recordingError} />
              </p>
            ) : (
                <div className='error-message-warning' />
            )}

            {tertiaryButton &&
                <span className='chat-link' onClick={onClickTertiaryButton}>
                    {tertiaryButton}
                </span>
            }

        </div>

    )
};

export default ClientNameForm;
