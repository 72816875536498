import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OutlinedButton } from '../../../../components/boutiq-buttons/BoutiqButton';
import { logger } from '../../../../logging';
import { checkMobileOnly } from '../../../../utils';
import { scheduleScreenMessages } from '../../messages';
import { ScheduledTag } from '../scheduled-tag/ScheduledTag';
import './EventItemData.scss';

export const EventItemData = ({ data, setSelectedEvent, selectedEvent }) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const [openEventBio, setOpenEventBio] = useState(false);
    const [bioMaxHeight, setBioMaxHeight] = useState(null);
    const [defaultBioHeight, setDefaultBioHeight] = useState(0);
    const [isToggleButtonAvailable, setIsToggleButtonAvailable] = useState(false);
    const eventBioRef = useRef(null);
    const isMobile = useRef(checkMobileOnly());
    const intl = useIntl();

    const animateImageOnLoad = () => {
        setIsImageLoaded(true);
    }

    const toggleEventBio = () => {
        setOpenEventBio(!openEventBio);
    }

    useEffect(() => {
        if (eventBioRef.current) {
            if (eventBioRef.current.clientHeight < eventBioRef.current.scrollHeight) {
                setIsToggleButtonAvailable(true)
            }
            if (!defaultBioHeight) {
                setDefaultBioHeight(eventBioRef.current.clientHeight)
            }
            setBioMaxHeight(openEventBio ? eventBioRef.current.scrollHeight : isMobile.current ? 75 : 90);
        }
    }, [eventBioRef.current, openEventBio])

    const onSelectEvent = (e, eventId) => {
        if (!e.target.classList.contains('event-description-toggle-button-label')) {
            setSelectedEvent(eventId);
		    logger.info('schedule eventId clicked', { eventId });
        }
    }

    return (
        <li onClick={(e) => onSelectEvent(e, data.id)}
            className={
                `event-data-container
                ${selectedEvent === data.id ? ' checked' : ''}`}
            aria-label={data.name}
            aria-selected={selectedEvent === data.id}
                >
            <div className='event-avatar-container'>
                {data.image && <img 
                            onLoad={animateImageOnLoad} 
                            className={`event-avatar${isImageLoaded ? ' loaded' : ''}`} 
                            src={data.image} />}
            </div>
            <div className='event-details-data-container'>
                <div className='event-name-wrapper'>
                    <p className='event-name'>
                        {data.name}
                    </p>
                </div>
                <p className='event-duration'>
                    {intl.formatMessage(scheduleScreenMessages.duration_in_minutes, { minutes: data.duration })}
                </p>
                <OutlinedButton disabled={selectedEvent === data.id} className={`${selectedEvent === data.id ? ' selected' : ''}`}>
                    <div className='event-btn-label'>
                        <FormattedMessage {...scheduleScreenMessages.cta_select} />
                    </div>
                    <div className='event-btn-label-selected'>
                        <FormattedMessage {...scheduleScreenMessages.cta_selected} />
                    </div>
                </OutlinedButton>
                {data.description &&
                    <>
                        <p ref={eventBioRef} style={{ maxHeight: `${bioMaxHeight}px`, '--default-max-height': `${bioMaxHeight}px` }} className={`event-bio${openEventBio ? ' open' : ''}`}>
                            {data.description}
                        </p>
                        <button onClick={() => toggleEventBio()} className={`event-description-toggle-button ${isToggleButtonAvailable ? ' available' : '' }`} >
                            <div className={`event-description-toggle-button-animated-labels-container${openEventBio ? ' open' : ''}`}>
                                <div className='event-description-toggle-button-label'>
                                    <FormattedMessage {...scheduleScreenMessages.cta_show_more} />
                                </div>
                                <div className='event-description-toggle-button-label active'>
                                    <FormattedMessage {...scheduleScreenMessages.cta_show_less} />
                                </div>
                            </div>
                        </button>
                    </>}
            </div>
        </li>
    )
}
