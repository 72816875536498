import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';
import storageFactory from "../utils/storageFactory";

export const CustomerDetailsContext = createContext(null);

export default function CustomerDetailsProvider({ customerName, customerEmail, customerId, children }) {
  const localStore = storageFactory(()=> localStorage);

  const [name, setName] = useState(customerName || localStore.getItem('caazamCredentials.name'));
  const [email, setEmail] = useState(customerEmail || localStore.getItem('caazamCredentials.email'));
  const [phone, setPhone] = useState(localStore.getItem('caazamCredentials.phone'));
  const [recordingConsent, setRecordingConsent] = useState(false)

  useEffect(() => {
    if (customerName) {
      setCustomerName(customerName);
    } else {
      setName(localStore.getItem('caazamCredentials.name'));
    }
  }, [customerName])

  useEffect(() => {
    if (customerEmail) {
      setCustomerEmail(customerEmail);
    } else {
      setEmail(localStore.getItem('caazamCredentials.email'));
    }
  }, [customerEmail])

  const toggleRecordingConsent = () => {
    setRecordingConsent(prev => !prev)
  }

  const setCustomerName = (name) => {
    localStore.setItem('caazamCredentials.name', name);
    setName(name);
  }

  const setCustomerEmail = (email) => {
    localStore.setItem('caazamCredentials.email', email);
    setEmail(email);
  }

  const setCustomerPhone = (phone) => {
    localStore.setItem('caazamCredentials.phone', phone);
    setPhone(phone);
  }

  const initials = useMemo(() => {
    return name?.split(' ')
      .map(item => item?.charAt(0)?.toUpperCase())
      .slice(0, 2)
      .reduce((initials, initial) => initials + initial, '')
  }, [name])

  return <CustomerDetailsContext.Provider value={{
    name,
    initials,
    email,
    shopifyId: customerId,
    phone,
    recordingConsent,
    toggleRecordingConsent,
    setCustomerName,
    setCustomerEmail,
    setCustomerPhone
  }}>{children}</CustomerDetailsContext.Provider>;
}

export function useCustomerDetails() {
  const context = useContext(CustomerDetailsContext);
  if (!context) {
    throw new Error('useCutomerDetails must be used within the CustomerDetailsContext Provider');
  }
  return context;
}
