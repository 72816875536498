import React from 'react';
import './styles.scss';
import EmptyImage from '../../emptyImage';

const ProductPreviewImage = ({image, size}) => {
  return image ? (
    <img className={`story-product-image-wrapper ${size}`} src={image}  alt={'empty'}/>
  )  : (
    <div className={'story-product-empty-image-wrapper'}>
      <EmptyImage size={'adaptive'} />
    </div>
  )
}

export default ProductPreviewImage;
