import { defineMessages } from 'react-intl';

export const callPreviewMessages = defineMessages({
  connect_now: {
    id: 'button_actions_cta_connect_now'
  },
  cta_schedule: {
    id: 'button_actions.cta_schedule'
  },
  powered_by_caazam: {
    id: 'messages.powered_by_caazam'
  },
  primary_welcome_message: {
    id: 'messages.primary_welcome_message'
  },
  secondary_welcome_message: {
    id: 'messages.secondary_welcome_message'
  },
  chat: {
    id: 'button_actions_chat_with_team',
  },
})
