const APP_DIAGNOSTICS_ACTION_TYPES = {  
  PREFLIGHT_COMPLETED: 'preflight-completed',
  PREFLIGHT_FAILED: 'preflight-failed',
  PREFLIGHT_STARTED: 'preflight-started',
  PREFLIGHT_STOPPED: 'preflight-stopped',
  REPORT_SENT: 'diagnostics-sent',
}

const QUALITY_SCORE = {
  POOR: 0,
  SUBOPTIMAL: 1,
  GOOD: 2,
  EXCELLENT: 3,
}

const QUALITY_LABEL = {
  0: 'POOR',
  1: 'SUBOPTIMAL',
  2: 'GOOD',
  3: 'EXCELLENT',
}

export {
  APP_DIAGNOSTICS_ACTION_TYPES,
  QUALITY_SCORE,
  QUALITY_LABEL,
}
