import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { useCallContextProvider } from './CallContextProvider';
import { useClientConfigProvider } from './ClientConfigProvider';
import useFullScreenMode from '../hooks/useFullScreenMode';
import ProductViewProvider from './ProductViewProvider';
import { checkMobileOnly } from '../utils';
import { useIntl } from 'react-intl';
import { useSidebarState } from '../hooks/useSidebarState';
import { useCheckoutAction } from '../hooks/useCheckoutAction';
import { useCartCounter } from '../hooks/useCartCounter';
import { useShowroomCounter } from '../hooks/useShowroomCounter';
import { useChatUnreadMessages } from '../hooks/useChatUnreadMessages';
import useDraftOrder from "../hooks/useDraftOrder";

export const CallScreenContext = createContext(null);

export default function CallScreenProvider({ onDisconnect, children }) {
    const isMobile = useRef(checkMobileOnly());
    const { shopId, callId, callData } = useCallContextProvider();
    const [searchResults, setSearchResults] = useState(null);
    const intl = useIntl();
    const { dynamicConfig, realtimeConfig } = useClientConfigProvider();
    const { isFullScreenEnabled } = useFullScreenMode(callId);

    const customLogo = dynamicConfig && dynamicConfig.app_custom_logo;
    const hostName = callData?.host?.displayName ?? '';

    const isSearchDisabled = realtimeConfig && realtimeConfig.disableClientProductSearch;
    const isChatEnabled = true; // in-video chat is always on

    const {draftOrder, cleanDraftOrder} = useDraftOrder()

    const videoI18nFormatter = ({ id, params }) => intl.formatMessage({ id: 'video.' + id }, params);

    const {
        closeDrawer,
        closeSidebar,
        openShowroom,
        openCart,
        hideModal,
        toggleDrawer,
        openDrawer,
        openChat,
        toggleSidebar,
        openSidebar,
        isVideoHeightMin,
        drawerSize,
        activeTab,
        fullSizeChatImage, setFullSizeChatImage,
        isSidebarOpen, setSidebarOpen,
        videoChatOpen, setVideoChatOpen,
        onFocusTab,
        focusedTab,
    } = useSidebarState();

    const { cartSnapshots, cartCounter } = useCartCounter(openCart, drawerSize, isMobile.current);
    const { sessionSnapshots, showroomCounter } = useShowroomCounter(openShowroom, drawerSize, closeDrawer, activeTab)
    const { openCheckout, isCheckoutComplete, setCheckoutComplete } = useCheckoutAction();
    const { numberOfUnreadMessages } = useChatUnreadMessages(isChatEnabled);

    return <CallScreenContext.Provider value={{
        customLogo, //???
        closeDrawer,
        openCheckout,
        isFullScreenEnabled,
        hostName, //????
        isCheckoutComplete,
        setCheckoutComplete,
        fullSizeChatImage,
        isSidebarOpen,
        videoChatOpen,
        hideModal,
        drawerSize,
        activeTab,
        toggleDrawer,
        openDrawer,
        shopId, // RM
        searchResults, /// ?????
        setSearchResults, /// ?????
        closeSidebar,
        cartSnapshots,
        sessionSnapshots,
        openChat,
        openShowroom,
        openCart,
        videoI18nFormatter,
        setVideoChatOpen,
        isVideoHeightMin,
        isMobile, //?????
        setSidebarOpen,
        toggleSidebar,
        setFullSizeChatImage,
        showroomCounter,
        cartCounter,
        callData,
        isChatEnabled,
        isSearchDisabled,
        numberOfUnreadMessages,
        draftOrder,
        cleanDraftOrder,
        onFocusTab,
        focusedTab,
    }}>
        <ProductViewProvider
            shopId={shopId}
            callId={callId}
            callData={callData}
            openSidebar={openSidebar}>
            {children}
        </ProductViewProvider>
    </CallScreenContext.Provider>
}

export function useCallScreenProvider() {
    const context = useContext(CallScreenContext);
    if (!context) {
        throw new Error('useCallScreenProvider must be used within the scope of CallScreenContext');
    }
    return context;
}
