import React from 'react';
import { CloseIcon } from '../../../../assets/icons/CloseIcon';
import './CloseBtn.scss';
import {isLastStackScreens} from "../../../../utils/ui";
import { useEffect } from 'react';

export const CloseBtn = ({onClose, location}) => {
  
    const handleCloseButton = () => {
      let params = null;
      if (isLastStackScreens(location)) {
        params = {resetStack: true};
      }
      onClose(params);
    }

    useEffect(()=>{
      const handleKeyDown = (event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
          handleCloseButton();
        }
      };
  
      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    },[])

    return (
        <button onClick={handleCloseButton} className='boutiq-close-button' aria-label='close'>
            <CloseIcon />
        </button>
    )
}
