import { REQ_HEADER_TYPES, useInterWindowMessageProvider } from "../components/InterWindowMessageProvider";
import { useClientAuthProvider } from '../components/ClientAuthProvider';

export const useGA4Analytics = () => {
  const { postMessage } = useInterWindowMessageProvider();
  const {contextId} = useClientAuthProvider()

  const defaultParams = ({
    'context_id': contextId,
    'app_version': process.env.REACT_APP_VERSION,
    'app_name': 'Boutiq Shopify Client'
  })

  const sendAnalyticsScreeViewEvent = (screen_name) => {
    postMessage(REQ_HEADER_TYPES.ANALYTICS_EVENT, {
      action: 'screen_view',
      customParams: {
        ...defaultParams,
        firebase_screen: screen_name,
        firebase_screen_class: 'Client app',
      }
    })
  }

  const sendAnalyticsCallEvent = (action, params = {}) => {
    postMessage(REQ_HEADER_TYPES.ANALYTICS_EVENT, {
      action,
      customParams: {
        ...defaultParams,
        'event_category': 'call',
        ...params,
      }
    })
  }

  const sendAnalyticsEcommEvent = (action, customParams) => {
    postMessage(REQ_HEADER_TYPES.ANALYTICS_EVENT, {
      action,
      customParams: {
        ...defaultParams,
        ...customParams
      }
    })
  }

  const sendAnalyticsChatEvent = (action, chat_message_type) => {
    postMessage(REQ_HEADER_TYPES.ANALYTICS_EVENT, {
      action,
      customParams: {
        ...defaultParams,
        'event_category': 'chat',
        chat_message_type,
      }
    })
  }

  const sendAnalyticsStoryEvent = (action, story_id) => {
    postMessage(REQ_HEADER_TYPES.ANALYTICS_EVENT, {
      action,
      customParams: {
        ...defaultParams,
        'event_category': 'story',
        story_id,
      }
    })
  }

  return ({
    sendAnalyticsScreeViewEvent,
    sendAnalyticsCallEvent,
    sendAnalyticsEcommEvent,
    sendAnalyticsChatEvent,
    sendAnalyticsStoryEvent
  })
}
