import { useEffect, useMemo, useRef, useState } from "react";

import { useClientAuthProvider } from "../components/ClientAuthProvider";
import { useClientConfigProvider } from "../components/ClientConfigProvider";
import useRoomsListener from "../modules/chat/api/rooms/listeners";
import { ROLES } from "../modules/chat/constants/participantRoles";
import { useCustomerChatData } from "../modules/chat/hooks/useCustomerChatData";
import { buildUserEntity } from "../modules/chat/utils/auth";
import { useSelector } from "react-redux";
import { useCustomerDetails } from "../components/CustomerDetailsProvider";
import useMessagesRequests from "../modules/chat/api/messages/requests";
import useMetadataRequests from "../modules/chat/api/metadata/requests";
import useFeatureRequests from "../modules/chat/api/features/requests";
import { useActiveRoomData } from "../modules/chat/hooks/useActiveRoomData";
import { logger } from "../logging";
import { ROOM_STATUS } from "../modules/chat/constants/roomStatuses";
import roomsRequests from '../modules/chat/api/rooms/requests';
import { useCallContextAPI } from ".";
import { MSG_TYPES, TYPING_TIME } from "../modules/chat/constants/messaging";
import { IMAGE_MAX_LENGTH, IMAGE_MAX_SIZE } from "../modules/chat/firebaseInitialization";
import {getImageParams, resizeFile} from "../modules/chat/utils/images";
import { useGA4Analytics } from './useGA4Analytics';

export const useChatData = () => {
    const { shopId, user, contextId } = useClientAuthProvider();
    const roomMessagesDisplayed = true;
    const room = useSelector(state => state.activeRoom);
    const { contextChatRequestHost } = useCallContextAPI(contextId);
    const {
        name,
        email,
        shopifyId,
      } = useCustomerDetails();
    const userEntity = useMemo(() => buildUserEntity({ ...user, displayName: name, email, role: ROLES.PARTICIPANT }), [user])

    let typingRef = useRef(null);
    const fileInputRef = useRef(null);
    const { sendMessage, sendFile } = useMessagesRequests({ user: userEntity, recipient: { id: room?.activeHost?.uuid } });
    const { seenMessages } = useMetadataRequests({ user: userEntity});
    const { updateTypingStatus } = useFeatureRequests({ userUuid: user.uid });
    const {sendAnalyticsChatEvent} = useGA4Analytics()

    const { initiateRoom } = roomsRequests({
        user: {
          ...user,
          uuid: buildUserEntity(user).uid,
          email: email,
          displayName: name,
          role: ROLES.PARTICIPANT,
        }
      });

    const [message, setMessage] = useState('');
    const [isTyping, setTyping] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);

    const lastRoomMessage = room && room.metadata && room.metadata.lastMessage && room.metadata.lastMessage;
    const roomStatus = room && room.status;
    const roomStatusUpdateAt = room && room.statusUpdatedAt;
    const roomId = room && room.id;

    useEffect(() => {
      updateTypingStatus({ typing: isTyping });
    }, [isTyping]);

    useEffect(() => {
        logger.info('Starting chat UI', { id: roomId });
    }, [roomId])

    const initChatRoom = () => {
        sendAnalyticsChatEvent('chat_request');
        return initiateRoom({
            createNewRoom: contextChatRequestHost,
            customerName: name,
            customerEmail: email,
            customerId: shopifyId,
            welcomeMessage: null,
        });
    }

    // if last message in chat is from this user while chat is archived while messenger UX is open, ask server to
    // restart chat in background
    useEffect(() => {
      if (lastRoomMessage && roomStatusUpdateAt) {
        if (roomStatus === ROOM_STATUS.ARCHIVED && lastRoomMessage.owner.uuid === user.uid &&
          new Date(lastRoomMessage.timestamp) > new Date(roomStatusUpdateAt) && !!initChatRoom) {
          initChatRoom(false /* withWelcome */).catch(error=>{
            console.error('Error reiniting archived chat',error);
          });
        }
      }

    }, [lastRoomMessage, roomStatus, roomStatusUpdateAt])

    const updateTyping = () => {
      if (!isTyping) {
        setTyping(true)
      }

      clearTimeout(typingRef.current);
      typingRef.current = setTimeout(() => setTyping(false), TYPING_TIME)
    }

    const updateMessage = (e) => {
      updateTyping();
      setMessage(e.target.value);
    };

    const sendNewMessage = () => {
      if (message) {
        setMessage('');
        sendMessage({ msg: message });
        sendAnalyticsChatEvent('chat_message_sent','text');
      }
    };

    const sendNewImage = async (event) => {
      const file = event.target.files[0];
      const {width, height} = await getImageParams(file);

      if (file.size > IMAGE_MAX_SIZE || width > IMAGE_MAX_LENGTH || height > IMAGE_MAX_LENGTH) {
        file.data = await resizeFile(file);
        file.isBase64 = true;
      }

      await sendFile({
        file,
        chatRoomId: room.id,
        type: MSG_TYPES.IMAGE
      });
      sendAnalyticsChatEvent('chat_message_sent','image');
    };

    const openGalleryDialog = () => {
      fileInputRef.current.click();
    };

    return {
        seenMessages,
        updateMessage,
        message,
        openGalleryDialog,
        sendNewMessage,
        sendNewImage,
        fileInputRef,
        user,
        roomMessagesDisplayed,
        scrollPosition,
        setScrollPosition,
    }
}
