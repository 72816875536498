import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl';
import { useIntroPage } from '../IntroPageProvider';
import { introWelcomeMessages } from './messages';
import './index.scss';

export default function IntroWelcomeMessage() {
    const intl = useIntl();
    const { name, config } = useIntroPage();
    const customLogo = config && config.app_custom_logo;

    const titleRenderer = () => {
      // TODO: Shai 2023-07-20 for some reason the name param is always undefined. no tome to debug now
      return (
            name
                ? <FormattedMessage {...introWelcomeMessages.salutation}
                    values={{
                        name: ` ${name.split(' ')[0]}`,
                        content: intl.formatMessage({ ...introWelcomeMessages.messages_welcome_primary })
                    }} />
                : <FormattedMessage {...introWelcomeMessages.messages_welcome_primary} />
        );
    }

    return (
        <div className='welcome-message-container-wrapper no-outline'>
            <div className='welcome-message-container' tabIndex={0}>
                {customLogo
                    ? <p className='primary-text'>
                        {titleRenderer()}
                      </p>
                    : <h3>
                        {titleRenderer()}
                    </h3>}
                <p className='secondary-text'>
                    <FormattedMessage {...introWelcomeMessages.messages_welcome_secondary}
                        values={{ service_name: intl.formatMessage({ ...introWelcomeMessages.service_noun }) }} />
                </p>
                <p className='secondary-text'>
                    <FormattedMessage {...introWelcomeMessages.messages_welcome_secondary_cta}
                        values={{ button_name: intl.formatMessage({ ...introWelcomeMessages.cta_connect_now }) }} />
                </p>
            </div>
        </div>
    )
}
