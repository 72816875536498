import React, { useState, useRef, useEffect } from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import { useCustomerDetails } from '../../../components/CustomerDetailsProvider';
import { useClientConfigProvider } from '../../../components/ClientConfigProvider';
import { useClientAuthProvider } from '../../../components/ClientAuthProvider';
import { useIntl, FormattedMessage } from 'react-intl';
import { welcomFormMessages } from './messages';
import ClientNameForm from '../../../components/client-name-form/client-name-form';
import { Loader } from '../../../components';
import { PAGES } from '../../../constants/routes';
import './index.scss';
import {checkPreviousPage} from "../../../utils/history";
import { useMemo } from 'react';

const WelcomeForm = ({ isLoading, onStartCall, onStartSchedule, isButtonDisabled }) => {
	const intl = useIntl();
	const history = useHistory();
	const { path } = useRouteMatch();
	const {
		name,
	} = useCustomerDetails();
	const [ formContainerHeght, setFormContainerHeight ] = useState(0);
    const formContainerRef = useRef(null);

	// TODO - need to wait for sessionData to have actual data so we can cehck for consent requirements.
	// cannot click on "Im ready" before this is resolved.
	const { sessionData } = useClientAuthProvider();
	const { isChatEnabled, dynamicConfig } = useClientConfigProvider();

	const recordingConsentRequired = useMemo(()=> sessionData?.recordingConsentRequired,[sessionData]);
	const forceRecConsent = useMemo(()=> dynamicConfig.forceRecConsent,[dynamicConfig]);
	const defaultRecConsentOff = useMemo(()=> dynamicConfig.defaultRecConsentOff,[dynamicConfig]);

	const openChat = () => {
		history.push(PAGES.CHAT);
	};

	useEffect(() => {
        if (formContainerRef.current) {
            setFormContainerHeight(getComputedStyle(formContainerRef.current).height);
			formContainerRef.current.setAttribute('tabindex', '-1');
        	formContainerRef.current.focus();
        }
    }, [formContainerRef]);

	const isChatPreviosPage = checkPreviousPage(history, path, PAGES.CHAT);
	const renderForm = () =>
	(
		<>
			{isLoading &&
				<div className='loader-container-wrapper' style={{ height: formContainerHeght }}>
					<h3>
						{/* //TOOO need to make sure the welcome message doesn't take 2 lines */}
						<FormattedMessage
							{...welcomFormMessages.welcome_message_content}
						/>
					</h3>
					<Loader />
				</div>}
			{!isLoading &&
				<div className='form-container-wrapper no-outline' ref={formContainerRef}>
					<ClientNameForm
						//TODO need to make sure the welcome message doesn't take 2 lines
						title={
							<FormattedMessage
								{...welcomFormMessages.welcome_message_content}
							/>}
						primaryButton={<FormattedMessage {...welcomFormMessages.ready} />}
						termsConfig={dynamicConfig.appForms?.videoCall?.terms?.hidden ? null : dynamicConfig.appForms?.videoCall?.terms}
						onClickPrimaryButton={onStartCall}
						isButtonDisabled={isButtonDisabled}
						isPrimaryButtonValidation={dynamicConfig.appForms?.videoCall?.email?.required ?? false} // false if email is optional
						isSecondaryButtonValidation={true}
						secondaryButton={onStartSchedule && !dynamicConfig.appForms?.videoCall?.email?.hidden && !isChatPreviosPage ? <FormattedMessage {...welcomFormMessages.schedule_later} /> : null}
						onClickSecondaryButton={onStartSchedule}
						tertiaryButton={(isChatEnabled && !isChatPreviosPage) ?  <FormattedMessage {...welcomFormMessages.start_chat} /> : null}
						onClickTertiaryButton={openChat}
						emailFormConfig={dynamicConfig.appForms?.videoCall?.email}
						recordingConsentRequired={recordingConsentRequired}
						forceRecConsent={forceRecConsent}
						defaultRecConsentOff={defaultRecConsentOff}
					/>
				</div>

			}
		</>
	);

	return (
		renderForm()
	)
};

export default WelcomeForm;
