import React, { useRef, useEffect } from 'react';
import './OptionListModal.scss';

export function OptionListModal({ children, onClose = () => { }, positionY = 'bottom', positionX = 'right', backdropStyle = '' }) {
    const selectedLocaleRef = useRef(null);

    useEffect(() => {
        if (selectedLocaleRef.current) {
            selectedLocaleRef.current.focus();
        }
        const handleKeyDown = (event) => {
            console.log('OptionListModal handleKeyDown', event);
            if (event.key === 'Escape' || event.keyCode === 27) {
                event.stopPropagation();
                event.preventDefault();
                onClose(event);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    return (
        <div onClick={onClose} className='option-settings-wrapper'>
            <div className={`option-list-modal ${positionY} ${positionX} ${backdropStyle}`}>
                {React.Children.map(children, child =>
                    React.cloneElement(child, {
                        ref: child.props.className.includes('selected') ? selectedLocaleRef : null
                    })
                )}
            </div>
        </div>
    );
}