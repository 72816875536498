import { useState, useEffect } from 'react';
import { useProductSearch } from '.';
import { parseIdString } from '../utils';
import { useGA4Analytics } from './useGA4Analytics';

const DEFAULT_RESULTS_LIMIT = 20;

export const useSearchSidebar = (sessionSnapshots, shopId, callData) => {
    const [cursor, setCursor] = useState(null);
    const [searchResults, setSearchResults] = useState(null)
    const [isSearching, setIsSearching] = useState(false);
    const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [noResults, setNoResults] = useState(false);
	  const [productIdsInShowroom, setProductIdsInShowroom] = useState(null);
    const {sendAnalyticsEcommEvent} = useGA4Analytics()

    const { searchProducts } = useProductSearch(
        shopId,
		callData.customerLocale,
		callData.countryCode,
    );

    const searchForProducts = (searchTerm, productIdsInShowroom) => {
        if (!isSearching) {
            setNoResults(false);
            setCursor(null);
            if (!searchTerm) {
                setSearchResults(null);
                return;
            }
            sendAnalyticsEcommEvent('search', { search_term: searchTerm });
            setIsSearching(true);
            searchProducts(searchTerm, DEFAULT_RESULTS_LIMIT).then((res) =>
                res.json()
                    .then((data) => {
                        if (!data.products.length) {
                            setNoResults(true);
                            return;
                        }
                        if (!productIdsInShowroom) {
                            setSearchResults(data.products);
                        } else {
                            findProductsInSR(data.products, productIdsInShowroom);
                        }
                        setCursor(data.cursor);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        setIsSearching(false);
                    })
            );
        }
    };

    const fetchNextPage = (searchTerm) => {
        if (!isFetchingMore) {
            setIsFetchingMore(true);
            setTimeout(() => {
                // let currentScrollPosition = 0;
                // if (listRef && listRef.current) {
                //     currentScrollPosition = listRef.current.scrollTop;
                // }
                searchProducts(searchTerm, DEFAULT_RESULTS_LIMIT, cursor)
                    .then((res) => res.json())
                    .then((data) => {
                        if (!productIdsInShowroom) {
                            setSearchResults([...searchResults, ...data.products]);
                        } else {
                            findProductsInSR(
                                [...searchResults, ...data.products],
                                productIdsInShowroom
                            );
                        }
                        setCursor(data.cursor);
                    })
                    .catch((err) => {
                        console.error(err);
                    })
                    .finally(() => {
                        setIsFetchingMore(false);
                    });
            }, 1000);
        }
    };

    const findProductsInSR = (productArray, existingProductIds) => {
        const newProductArray = productArray.map((product) => {
            return {
                ...product,
                isInSR: existingProductIds.includes(parseIdString(product.productId)),
            };
        });
        setSearchResults(newProductArray);
    };

    useEffect(() => {
        if (!sessionSnapshots && sessionSnapshots.length > 0) return;
        const existingProductIds = sessionSnapshots.map(
            (snapshot) => snapshot.val().productId
        );
        setProductIdsInShowroom(existingProductIds);
        if (existingProductIds && searchResults) {
            findProductsInSR(searchResults, existingProductIds);
        }
    }, [sessionSnapshots]);

    return {
        searchForProducts,
        fetchNextPage,
        noResults,
        cursor,
        productIdsInShowroom,
        isSearching,
        isFetchingMore,
        searchResults
    }
}
