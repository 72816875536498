import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  name: {
    id: 'nouns_name'
  },
  email: {
    id: 'nouns.email'
  },
  optional: {
    id: 'adverb_optional'
  },
  powered_by_caazam: {
    id: 'messages.powered_by_caazam',
  },
});
