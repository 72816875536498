import { useEffect, useState } from 'react';
import firebase from '../Firebase'
import {getDatabase, ref, onValue, off, push, child, update, remove, set} from 'firebase/database';
import { useList } from 'react-firebase-hooks/database';
import { logger } from '../logging'
import { useGA4Analytics } from './useGA4Analytics';

const db = getDatabase(firebase);

export const CartSourceType = {
  session : 'session',
  preloaded: 'preloaded',
}

export const useSessionCart = (callId, activeCartId) => {
  const cartProductsRef = callId && activeCartId  ? ref(db, `session/${callId}/cart/${activeCartId}/products`) : null;
  const [cartSnapshots, cartSnapshotLoading, cartSnapshotError] = useList(cartProductsRef);

  return {
    cartSnapshots: cartSnapshots || [],
    cartSnapshotsLength: cartSnapshots ? cartSnapshots.length : 0,
    cartSnapshotLoading,
    cartSnapshotError,
  };
};

export function useSessionCartActions(callId, activeCartId) {
  const cartRef = ref(db,`session/${callId}/cart/${activeCartId}`);
  const cartProductsRef = child(cartRef, 'products');
  const {sendAnalyticsEcommEvent} = useGA4Analytics()

  const addToCart = async (product) => {

    const { productId, productTitle, variantId, currentPrice, currencyCode, inventoryPolicy, inventoryQuantity } = product;
    try {
      const newRef = push(cartProductsRef);
      await set(newRef, { ...product, source: CartSourceType.session })
      logger.info('addToCart', { key: newRef.key, productId, variantId, currentPrice, inventoryPolicy, inventoryQuantity });

      const analyticsPayload = {
        value: currentPrice,
        currency: currencyCode,
        items: [{
          item_id: productId,
          item_name: productTitle,
          price: currentPrice,
          quantity: 1,
        }]
      }

      sendAnalyticsEcommEvent('add_to_cart', analyticsPayload);
      return newRef.key;
    } catch (error) {
      logger.error(`addToCart failed ${productId} ${variantId}`, error);
      throw error;
    }
  };

  const loadToCart = async (products) => {
    if (!products || !products.length) return;

    try {
      let updates = {};
      for (const product of products) {
        const newRefId = push(cartProductsRef).key;
        updates[newRefId] = product;
      }
      await update(cartProductsRef, updates);

    } catch (error) {
      logger.error(`Failed to load product ${products.length} to cart`, error);
    }
  };


  const updateCartProduct = (key, newData) => {

    update(child(cartProductsRef, key),{ ...newData, source: CartSourceType.session })
      .then(() => logger.info('updateCartProduct', { key, ...newData }))
      .catch(error => logger.error(`updateCartProduct failed ${key}`, error));
  };

  const removeCartProduct = async (key, product) => {

    const { productId, productTitle, variantId, currentPrice, currencyCode, quantity } = product;

    try {
      await remove(child(cartProductsRef, key));
      logger.info('removeCartProduct', { key, productId, variantId });
      sendAnalyticsEcommEvent('remove_from_cart', {
        value: currentPrice,
        currency: currencyCode,
        items: [{
          item_id: productId,
          item_name: productTitle,
          price: currentPrice,
          quantity,
        }]
      })
    } catch (error) {
      logger.error(`removeCartProduct failed ${key} ${productId} ${variantId}`, error);
    }
  };

  return {
    addToCart,
    loadToCart,
    updateCartProduct,
    removeCartProduct,
  };

}


export const useCheckoutComplete = (callId, cartId, callback) => {

  useEffect(() => {
    if (callId) {
      const cartRef = ref(db,`session/${callId}/cart/${cartId}/order`);
      onValue(cartRef, (snap) => {
        let order = snap.val();
        if (order && order.orderId) {
          callback();
        }
      });
      return () => off(cartRef);
    }
  }, [callId, cartId]);
};
