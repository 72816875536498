
/* 
These functions override the video room main participant elements when the product 
full screen view is enabled so that the remote video is displayed as a thumbnail in the 
top left of the screen.

 */

const restoreVideoViews = () => {
  const localVideo = document.getElementById('LocalParticipantInfo.container');
  if (localVideo) {
    localVideo.style.visibility = 'visible';
  }
  const remoteVideo = document.getElementById('MainParticipantInfo.container');
  if (remoteVideo) {
    const remoteVideoTag = remoteVideo.getElementsByTagName('video');
    if (remoteVideoTag?.[0]) {
      remoteVideoTag[0].classList.remove('presentation-mode-video-desktop');
      remoteVideoTag[0].classList.remove('presentation-mode-video-mobile');
    }
  }
  return null;
}

const updateMobileVideoViews = () => {
  const localVideo = document.getElementById('LocalParticipantInfo.container');
  if (localVideo) {
    localVideo.style.visibility = 'hidden';
  }
  const remoteVideo = document.getElementById('MainParticipantInfo.container');
  if (remoteVideo) {
    const remoteVideoTag = remoteVideo.getElementsByTagName('video');
    if (remoteVideoTag?.[0]) {
      remoteVideoTag[0].classList.add('presentation-mode-video-mobile');
    }
  }
}

const updateDesktopVideoViews = () => {
  const localVideo = document.getElementById('LocalParticipantInfo.container');
  if (localVideo) {
    localVideo.style.visibility = 'hidden';
  }
  const remoteVideo = document.getElementById('MainParticipantInfo.container');
  if (remoteVideo) {
    const remoteVideoTag = remoteVideo.getElementsByTagName('video');
    if (remoteVideoTag?.[0]) {
      remoteVideoTag[0].classList.add('presentation-mode-video-desktop');
    }
  }
}

const isLastStackScreens = (location = null) => {
  if (location !== null && location.pathname) {
    const lastScreens = ['/error', '/scheduling/succsess', '/video-call/thank-you', '/contact']
    return lastScreens.includes(location.pathname);
  }
}

export {
  updateMobileVideoViews,
  updateDesktopVideoViews,
  restoreVideoViews,
  isLastStackScreens,
}
