import React, { useRef } from 'react';
import { checkMobileOnly } from '../../utils';
import CallScreenProvider from '../../components/CallScreenProvider';
import CallScreenMobile from './mobile/CallScreenMobile'; 
import CallScreenDesktop from './desktop/CallScreenDesktop';
import './call-screen.scss';
import '@caazam/boutiq-video-room/dist/style.css';

const CallScreen = ({ onDisconnect }) => {
	const isMobile = useRef(checkMobileOnly());
	return (
		<CallScreenProvider onDisconnect={onDisconnect}>
			{isMobile.current
				? <CallScreenMobile />
				: <CallScreenDesktop />
			}
		</CallScreenProvider>
	);
};
export default CallScreen;
