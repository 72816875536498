import React, { useMemo, useRef, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { chatScreenMessages } from './messages';

import WelcomeChatScreen from './containers/welcomeChatScreen';
import MessengerScreen from './containers/messenger';
import ChatErrorScreen from './containers/chatError';
import { CHAT_ROUTES } from '../../constants/routes';
import { useSchdeuleProvider } from '../../components/SchdeuleProvider';
import { ROLES } from "../../modules/chat/constants/participantRoles";
import { useClientAuthProvider } from '../../components/ClientAuthProvider';
import { useCustomerDetails } from '../../components/CustomerDetailsProvider';
import { useCallContextAPI } from "../../hooks";
import roomsRequests from '../../modules/chat/api/rooms/requests';
import { buildUserEntity } from '../../modules/chat/utils/auth';
import './styles.scss';
import FullSizeImageModal from "../../components/full-size-image-modal/fullSizeImageModal";
import { isMobile } from 'react-device-detect';
import { CloseBtn } from '../../components/appHeader/components/close-btn/CloseBtn';
import { AppFooter } from '../../components/appFooter/AppFooter';
import { useGA4Analytics } from '../../hooks/useGA4Analytics';

export default function ChatScreen({ close }) {
  const { shopId, user, contextId } = useClientAuthProvider();
  const { path } = useRouteMatch();
  const intl = useIntl();
  const userEntity = useMemo(() => buildUserEntity({ ...user, role: ROLES.PARTICIPANT }), [user]);
  const {sendAnalyticsChatEvent} = useGA4Analytics()
  const [fullSizeChatImage, setFullSizeChatImage] = useState(null);

  const {
    name,
    email,
    shopifyId,
  } = useCustomerDetails();

  const { isScheduleLoading, scheduleStatus } = useSchdeuleProvider();
  const noAvailability = useRef(!isScheduleLoading && !scheduleStatus); // using useRef becasue we only care about schedule loading status when component loads

  const { contextChatRequestHost } = useCallContextAPI(contextId);

  const { initiateRoom } = roomsRequests({
    user: {
      ...userEntity,
      uuid: userEntity.uid,
      email: email,
      displayName: name,
      role: ROLES.PARTICIPANT,
    }
  });

  const initChatRoom = async (withWelcome = true) => {
    sendAnalyticsChatEvent('chat_request');
    return initiateRoom({
      createNewRoom: contextChatRequestHost,
      customerName: name,
      customerEmail: email,
      customerId: shopifyId,
      welcomeMessage: withWelcome ? intl.formatMessage(chatScreenMessages.welcomeMessage, { name }) : null,
    });
  }

  const hideModal = () => {
    setFullSizeChatImage(null);
  };

  return (

    <div className='caazam-main-container-wrapper chat-page'>
      <CloseBtn onClose={close} />
      <AppFooter />
      <Switch>
        <Route exact path={path}>
          {noAvailability.current
            ? <ChatErrorScreen close={close} />
            : <WelcomeChatScreen initChatRoom={initChatRoom} />
          }
        </Route>
        <Route path={`${path}${CHAT_ROUTES.MESSENGER}`}>
          <MessengerScreen
            shopId={shopId}
            user={userEntity}
            setFullSizeChatImage={isMobile ? setFullSizeChatImage : null}
            initChatRoom={initChatRoom} />
        </Route>
        <Route path={`${path}${CHAT_ROUTES.ERROR}`}>
          <ChatErrorScreen close={close} />
        </Route>
      </Switch>
      {!!fullSizeChatImage &&
        <FullSizeImageModal
          fullSizeChatImage={fullSizeChatImage}
          closeAction={hideModal}
        />
      }
    </div>
  )
}

