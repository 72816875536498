import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as MenuOpen } from '../../../assets/icons/menu_open.svg';
import ArrowForwardIosIcon from '../../../assets/icons/ArrowForwardIos';
import ExpandLessIcon from '../../../assets/icons/ExpandLess';
import BoutiqVideoRoom from '@caazam/boutiq-video-room';
import Sidebar from '../desktop/components/sidebar/index';
import ShopLogoContainer from '../../../components/shop-logo-container/shop-logo-container';
import FullSizeImageModal from "../../../components/full-size-image-modal/fullSizeImageModal";
import { callScreenMessages } from '../intl/call-screen/messages';
import { useCallScreenProvider } from '../../../components/CallScreenProvider';
import {state, tabLabels} from "../../../constants/menuDrawer";
import { VideoRoomPanel } from '../desktop/components/panel/panel';
import './styles.scss';
import {DRAFT_ORDER_COMPLETED} from "../../../constants/draftOrder";
import ChatIcon from "../../../assets/icons/Chat";

const CallScreenDesktop = () => {

	const {
		drawerSize,
		activeTab,
		toggleDrawer,
		shopId,
		searchResults,
		setSearchResults,
		closeSidebar,
		isCheckoutComplete,
		setCheckoutComplete,
		cartSnapshots,
		sessionSnapshots,
		isFullScreenEnabled,
		openShowroom,
		openCart,
		videoI18nFormatter,
		customLogo,
		isSidebarOpen,
		setVideoChatOpen,
		isVideoHeightMin,
		isMobile,
		hostName,
		toggleSidebar,
		fullSizeChatImage,
		setFullSizeChatImage,
		hideModal,
		showroomCounter,
		cartCounter,
		videoChatOpen,
		draftOrder,
		cleanDraftOrder,
		isChatEnabled,
		numberOfUnreadMessages
	} = useCallScreenProvider();

	const cartTitle = draftOrder && draftOrder.status !== DRAFT_ORDER_COMPLETED
		? {...callScreenMessages.custom_cart}
		: {...callScreenMessages.cart}

	const cartProductNumber = () => {
		if (draftOrder) {
			if (draftOrder.status === DRAFT_ORDER_COMPLETED) {
				return 0
			}
			return draftOrder.lineItems.length
		} else {
			return cartSnapshots ? cartSnapshots.length : 0
		}
	}
	
	const onToggleChat = () => {
		if (isSidebarOpen) {
			setVideoChatOpen(prev => !prev)
		} else {
			toggleSidebar()
			setVideoChatOpen(true)
		}
	}

	return (
		<div
			className={`video-room ${isVideoHeightMin ? 'ftr-open' : ''} ${isSidebarOpen ? 'sidebar-open' : ''
				}`}>
			<div className={`video-container ${isFullScreenEnabled ? 'fullscreen-height' : 'height'}`}>
				<BoutiqVideoRoom i18nFormatter={videoI18nFormatter} />
			</div>
			{!isFullScreenEnabled &&
				<span
					id='sidebar-btn'
					className={`sidebar-btn ${isSidebarOpen ? 'open' : 'close'}`}
					onClick={toggleSidebar}>
					{isSidebarOpen ?
						<ArrowForwardIosIcon color="gray" size={36} /> :
						<MenuOpen style={{ fill: '#CFC8BB', height: 36, width: 36 }} />
					}
				</span>
			}
			{isFullScreenEnabled &&
				<div className='presenting-text'>
					<FormattedMessage {...callScreenMessages.host_is_presenting} values={{ name: hostName }} />
				</div>
			}
			{!isFullScreenEnabled &&
				<div
					className={`drawer-container ${drawerSize > state.COLLAPSED && activeTab === tabLabels.SHOWROOM ? 'product-view' : ''}${drawerSize > state.COLLAPSED && activeTab === tabLabels.CART ? ' cart-view' : ''
						}`}>
					<div className={`drawer-header ${isFullScreenEnabled ? 'fullscreen-height' : 'height'}`}>
						<div className='start'>
							{customLogo && <ShopLogoContainer src={customLogo} isAnimateOnload={true} />}
							<span
								id='cart-tab'
								onClick={openShowroom}
								className={`showroom${activeTab === tabLabels.SHOWROOM ? ' active' : ''}`}>
								<p>
									<FormattedMessage {...callScreenMessages.showroom} />
								</p>
								<span
									id='counter-added-items-in-showroom'
									className={`counter ${showroomCounter.isIncreased ? 'emphasize' : ''
										}`}>
									{sessionSnapshots ? sessionSnapshots.length : 0}
								</span>
							</span>
							<span
								onClick={openCart}
								className={activeTab === tabLabels.CART ? 'active' : ''}>
								<p>
									<FormattedMessage {...cartTitle} />
								</p>
								<span
									id='counter-added-cart-items'
									className={`counter ${cartCounter.isIncreased && !draftOrder ? 'emphasize' : ''}`}>
									{cartProductNumber()}
								</span>
							</span>
						</div>
						<div className='end'>
							{isChatEnabled && (
								<ChatIcon className='icon-chat' onClick={onToggleChat} color='gray' size={30}/>
							)}
							{isChatEnabled && numberOfUnreadMessages > 0 &&
								<div className='icon-badges'>
									<span>
										{numberOfUnreadMessages}
									</span>
								</div>
							}
							<button className='ftr-open arrow' onClick={toggleDrawer}>
								<ExpandLessIcon size={48} color='gray'/>
							</button>
						</div>
					</div>
					<div className='drawer'>
						<VideoRoomPanel
							view={drawerSize > state.COLLAPSED ? activeTab : tabLabels.NONE}
							isCheckoutComplete={draftOrder ? draftOrder?.status === DRAFT_ORDER_COMPLETED : isCheckoutComplete}
							setCheckoutComplete={draftOrder ? cleanDraftOrder : setCheckoutComplete}
						/>
					</div>
				</div>
			}
			<div className={`product-sidebar ${isSidebarOpen ? 'open' : ''} ${!isMobile.current ? 'desktop' : ''}`}>
				{isMobile.current && (
					<span className='close-btn' onClick={closeSidebar}>
						&#215;
					</span>
				)}
				<Sidebar
					shopId={shopId}
					isMobile={isMobile.current}
					closeSidebar={closeSidebar}
					searchResults={searchResults}
					setSearchResults={setSearchResults}
					isFullScreenEnabled={isFullScreenEnabled}
					videoChatOpen={videoChatOpen}
					setVideoChatOpen={setVideoChatOpen}
					setFullSizeChatImage={setFullSizeChatImage}
				/>
			</div>
			{!!fullSizeChatImage &&
				<FullSizeImageModal
					fullSizeChatImage={fullSizeChatImage}
					closeAction={hideModal}
				/>
			}
		</div>
	)
}

export default CallScreenDesktop
