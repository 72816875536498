import { useEffect, useState, useRef } from 'react';
import { useSessionDataClientView } from '.';
import { useCallContextProvider } from '../components/CallContextProvider';
import { state, tabLabels } from '../constants/menuDrawer';
import { checkMobileOnly } from '../utils';
import useFullScreenMode from './useFullScreenMode';

export const useSidebarState = () => {
    const { callId } = useCallContextProvider();
    const { updateClientTabView, clearClientView } = useSessionDataClientView();
    const [drawerSize, setDrawerSize] = useState(0);
    const [fullSizeChatImage, setFullSizeChatImage] = useState(null);
    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const [videoChatOpen, setVideoChatOpen] = useState(false);
    const isMobile = useRef(checkMobileOnly());
    const [isVideoHeightMin, setVideoHeightMin] = useState(false);
    const [activeTab, setActiveTab] = useState(tabLabels.NONE);
    const [focusedTab, setFocusedTab] = useState(tabLabels.NONE)
    const { isFullScreenEnabled } = useFullScreenMode(callId);

    const closeDrawer = () => {
        setDrawerSize(state.COLLAPSED);
        if (!isMobile.current) {
            setVideoHeightMin(false);
        }
    };

    const openCart = () => {
        if (isFullScreenEnabled) {
            return;
        }
        if (!isMobile.current) {
            openDrawer(tabLabels.CART, state.EXTRA_EXPANDED);
            setVideoHeightMin(true);
        } else {
            openDrawer(tabLabels.CART, state.FULL_EXPANDED);
        }
    }

    const openShowroom = () => {
        if (isFullScreenEnabled) {
            return;
        }
        if (!isMobile.current) {
            openDrawer(tabLabels.SHOWROOM, state.EXPANDED);
            setVideoHeightMin(true);
        } else {
            openDrawer(tabLabels.SHOWROOM, state.FULL_EXPANDED);
        }
    };

    const openDrawer = (activeTab, size) => {
        if (isFullScreenEnabled) {
            return;
        }
        setActiveTab(activeTab);
        setDrawerSize(size);
    }

    const onFocusTab = (activeTab) => {
        if (isFullScreenEnabled) {
            return;
        }
        setFocusedTab(activeTab)
    }

    const openChat = () => {
        if (isFullScreenEnabled) {
            return;
        }
        if (!isMobile.current) {
            return;
        } else {
            openDrawer(tabLabels.CHAT, state.FULL_EXPANDED);
        }
    };

    const toggleDrawer = () => {
        if (drawerSize > state.COLLAPSED) {
            closeDrawer();
            return;
        }
        if (drawerSize === state.COLLAPSED) {
            openShowroom();
        }
    };

    const hideModal = () => {
        setFullSizeChatImage(null);
    };

    const openSidebar = () => {
        if (isFullScreenEnabled) {
            return;
        }
        setSidebarOpen(true);
    };
    const closeSidebar = () => {
        setSidebarOpen(false);
        setVideoChatOpen(false);
    };

    const toggleSidebar = () => {
        if (isFullScreenEnabled) {
            return;
        }
        if (isSidebarOpen) {
            setVideoChatOpen(false);
        }
        return setSidebarOpen((isSidebarOpen) => !isSidebarOpen)
    };

    useEffect(() => {
        if (isFullScreenEnabled) {
            closeDrawer();
            closeSidebar();
        }
    }, [isFullScreenEnabled])

    useEffect(() => {
        updateClientTabView(drawerSize !== state.COLLAPSED ? activeTab : null);
    }, [activeTab, drawerSize])

    useEffect(() => {
        return () => clearClientView();
    }, [])

    return {
        drawerSize,
        closeDrawer,
        closeSidebar,
        openShowroom,
        openCart,
        openChat,
        hideModal,
        toggleDrawer,
        openDrawer,
        onFocusTab,
        focusedTab,
        toggleSidebar,
        openSidebar,
        isVideoHeightMin,
        activeTab,
        fullSizeChatImage, setFullSizeChatImage,
        isSidebarOpen, setSidebarOpen,
        videoChatOpen, setVideoChatOpen,
    }
}
