import React, { useState, useEffect } from 'react';
import BoutiqVideoRoom from '@caazam/boutiq-video-room';
import { restoreVideoViews } from '../../../utils/ui';
import { useCallScreenProvider } from '../../../components/CallScreenProvider';
import { MobileMenu } from './components/mobile-menu/MobileMenu';
import { callScreenMessages } from '../intl/call-screen/messages';
import { FormattedMessage } from 'react-intl';
import './CallScreenMobile.scss';

const CallScreenMobile = () => {
  const {
    isFullScreenEnabled,
    videoI18nFormatter,
    hostName
  } = useCallScreenProvider();

  const [showControls, setShowControls] = useState(true);

  useEffect(() => {
    if (isFullScreenEnabled) {
      restoreVideoViews();
    }
  }, [isFullScreenEnabled])

  const handleEvent = event => {
    if (event.target.id === 'MainParticipantInfo.infoContainer') {
      setShowControls(!showControls)
    }
  };

  return (
    <div className='mobile-video-room'>
      {isFullScreenEnabled &&
        <div className='mobile-full-screen-presenting-text'>
          <FormattedMessage {...callScreenMessages.host_is_presenting} values={{ name: hostName }} />
        </div>
      }
      <div className='mobile-video-room-wrapper' onClick={handleEvent}>
        <BoutiqVideoRoom toggleControls={showControls} i18nFormatter={videoI18nFormatter} />
      </div>
      <MobileMenu />
    </div>
  );
}

export default CallScreenMobile
