import React, { createContext, useContext, useState } from 'react';
import { useCustomerDetails } from './CustomerDetailsProvider';
import { useSchdeuleProvider } from './SchdeuleProvider';

export const SchdeulerConfigContext = createContext(null);

export default function SchdeuleConfigProvider({ children }) {
    const {
        name,
        email,
        phone,
        setCustomerName,
        setCustomerEmail,
        setCustomerPhone: persistCustomerPhone
    } = useCustomerDetails();
    const {
        setHostForScheduling, 
        hostIdForScheduling,
        eventType: selectedEventType,
        setEventTypeForScheduling: setSelectedEventType,
        scheduleItemData,
    } = useSchdeuleProvider()

    const [clientFormData, setClientFormData] = useState({});
    const [selectedAllHosts, setSelectedAllHosts] = useState(false);
    const [scheduleData, setScheduleData] = useState(null);
    const isRescheduling = !!scheduleItemData;
    const rescheduledHost = (scheduleItemData && scheduleItemData.hostId) || null;
    const [currentDay, setCurrentDay] = useState(0);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [schedulingError, setSchedulingError] = useState(null);

    // button should be enabled:
    // if new scheduling client must select either a host or any hosts
    // if rescheduling then always enabled
    const isButtonEnabled = (hostIdForScheduling || selectedAllHosts) || isRescheduling;

    const selectSlot = (session) => {
        if (session && session.isAvailable) {
            setSelectedSlot(session);
        } else if (session === null) {
            setSelectedSlot(session);
        }
    };

    return <SchdeulerConfigContext.Provider value={{
        name, setCustomerName,
        email, setCustomerEmail,
        phone, persistCustomerPhone,
        currentDay, setCurrentDay,
        selectedSlot, selectSlot,
        selectedEventType, setSelectedEventType,
        hostIdForScheduling, setHostForScheduling,
        selectedAllHosts, setSelectedAllHosts,
        rescheduledHost,
        isButtonEnabled,
        isRescheduling,
        scheduleData, setScheduleData,
        clientFormData, setClientFormData,
        schedulingError, setSchedulingError,
    }}>
        {children}
    </SchdeulerConfigContext.Provider>
}

export function useSchdeuleConfigProvider() {
    const context = useContext(SchdeulerConfigContext);
    if (!context) {
        throw new Error('useSchdeuleConfigProvider must be used within the scope of SchdeulerConfigContext');
    }
    return context;
}
