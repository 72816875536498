import React from 'react';
import LoadingCard from '../loading-card/LoadingCard';
import './ClientNameFormSkeleton.scss'

const ClientNameFormSkeleton = () => {
  return (
    <div>
      <LoadingCard
        height={52}
      />
      <LoadingCard
        height={52}
      />
      <div className={'client-name-form-skeleton-checkbox-row'}>
        <LoadingCard
          height={52}
          width={'45%'}
        />
        <LoadingCard
          height={52}
          width={'45%'}
        />
      </div>
    </div>
  )
}

export default ClientNameFormSkeleton;
